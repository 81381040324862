import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AnimateScreen from 'componentes/AnimateScreen'
import DB from 'utiles/db'
const cavas = new DB('cavas')
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: []
		}
	}

	componentDidMount() {
		cavas
			.getAll()
			.then(data => {
				this.setState({ data: data.data.Items, loading: false })
			})
			.catch(error => {
				console.error(error)
			})
	}

	render() {
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Cavas</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/admin/cavas/create" className="btn">
							Registrar cava
						</NavLink>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('../../img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Nombre cava</th>
								<th>Dirección</th>
								<th>Teléfono</th>
								<th>Catalogo</th>
								<th>Monto cobradores</th>
								<th>SMS App</th>
								<th>Acción</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(cava => (
								<tr key={cava.uuid}>
									<td>{cava.name}</td>
									<td>{cava.address}</td>
									<td>{cava.phone}</td>
									<td>
										<span className={`status ${cava.catalogo ? 'Activo' : 'Inactivo'}`}>{cava.catalogo ? 'Activo' : 'Inactivo'}</span>
									</td>
									<td>
										<span className={`status ${cava.hide_amount ? 'Activo' : 'Inactivo'}`}>{cava.hide_amount ? 'No ocultar' : 'Ocualtar'}</span>
									</td>
									<td>
										<span className={`status ${cava.sms_cobra ? 'Activo' : 'Inactivo'}`}>{cava.sms_cobra ? 'Activo' : 'Inactivo'}</span>
									</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('/admin/cavas/edit/' + cava.uuid)
											}}
											className="btn-edit">
											Editar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

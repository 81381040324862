import { Component } from 'react'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { v4 as uuidv4 } from 'uuid'
import { Alert } from 'utiles/functions'
import Back from 'componentes/back'
import DB from 'utiles/db'
const providers = new DB('providers')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('doc') !== '' && data.get('phone') !== '') {
			const provider = {
				uuid: uuidv4(),
				cava: this.props.cava.uuid,
				name: data.get('name'),
				doc: data.get('doc'),
				phone: data.get('phone'),
				bank: data.get('bank'),
				description: data.get('description'),
				status: this.state.status
			}
			this.setState({ loading: true }, async () => {
				const r = await providers.create(provider)
				if (r.status) {
					this.setState({ loading: false })
					this.props.navigate('/dashboard/providers')
					Alert('Proveedor creado correctamente.')
				} else {
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				}
			})
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Proveedores</h2>
						<span>Nuevo proveedor</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre del proveedor *" placeholder="Ingresa el nombre del proveedor" name="name" />
							<Input type="number" title="NIT o Cédula de ciudadania *" placeholder="Ingresa el número de documento" name="doc" />
							<Input type="number" title="Número de teléfono *" placeholder="Ingresa el número de teléfono" name="phone" />
						</div>
						<div className="column">
							<Input
								type="textarea"
								title="¿Que productos provee?"
								height={110}
								placeholder="Ejemplo: 
	                        	- Chorizo 
	                        	- Salchichón"
								name="description"
							/>
							<Input
								type="textarea"
								title="Datos de cuenta bancaria para transferencias"
								placeholder="Ingresa los datos de la cuenta bancaria"
								name="bank"
							/>
							<Input
								type="checkbox"
								id="status"
								title="Estado (Inactivo / Activo)"
								active={this.state.status}
								change={val => {
									this.setState({ status: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}

import { Component } from 'react'
import { Price, Alert } from 'utiles/functions'
import { NavLink } from 'react-router-dom'
import withParams from 'componentes/WithParams'
import Back from 'componentes/back'
import DB from 'utiles/db'
import e from 'utiles/settings'
import { setLoading } from 'store'
const dispatchthecollectorsTable = new DB('dispatchthecollectors')
const productsTable = new DB('products')
const usersTable = new DB('users')
class ShowDispatch extends Component {
	constructor(props) {
		super(props)
		this.state = {
			products: [],
			way: '',
			cantidad: 0,
			avaluoCredito: 0,
			avaluoCostoOperacion: 0,
			uuidTweak: ''
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			setLoading(true)
			// Consultar el despacho
			const result = await dispatchthecollectorsTable.get({ uuid: this.props.params.uuid })
			if (result.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const dispatch = result.data.Item
			// Consultar los productos del despacho
			const productsObject = {}
			for (const product of dispatch.products) {
				productsObject[product.uuid] = product
			}
			const productsResult = await productsTable.query('contains(:uuids, #uuid)', { ':uuids': Object.keys(productsObject) }, { '#uuid': 'uuid' })
			if (productsResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			// Armar el array de productos del despacho
			const products = []
			for (const product of productsResult.data.Items) {
				const productDispatch = productsObject[product.uuid]
				products.push({
					uuid: product.uuid,
					name: product.name,
					image: product.image,
					quantity: productDispatch.quantity,
					disponibles: productDispatch.disponibles,
					price_credito: productDispatch.price_credito
				})
			}
			// Consultar el cobrador
			const userResult = await usersTable.get({ email: dispatch.way.user })
			if (userResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			// Setear información en el estado
			this.setState({
				cantidad: dispatch.quantity_products,
				avaluoCredito: dispatch.price_credito,
				avaluoCostoOperacion: dispatch.price_cost_operation,
				way: dispatch.way.name + ' - ' + dispatch.way.day + ' - ' + userResult.data.Item.name,
				uuidTweak: dispatch.uuid_tweaks,
				products
			})
			setLoading(false)
		} catch (error) {
			console.log(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		return (
			<>
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Despacho de cobrador</h2>
						<span>Ver</span>
					</div>
					<div className="btns">
						<NavLink to={`/dashboard/inventory_tweaks/show/${this.state.uuidTweak}`} className="btn">
							Ver ajuste de inventario de este despacho
						</NavLink>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total productos</span>
							<strong>{this.state.cantidad}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avalúo precio crédito</span>
							<strong>{Price(this.state.avaluoCredito)}</strong>
						</div>
					</div>
					<div className="card hiden-responsive">
						<div className="item-widget">
							<span>Avalúo costo de operación</span>
							<strong>{Price(this.state.avaluoCostoOperacion)}</strong>
						</div>
					</div>
				</div>

				<div className="divider"></div>
				<div className="card">
					<div className="la_descriopcion">
						<div className="input-group">
							<label>Ruta</label>
							<input type="text" readOnly={true} value={this.state.way} />
						</div>
					</div>

					<table className="table">
						<thead>
							<tr>
								<th>Producto</th>
								<th>Cantidad despacho</th>
								<th className="hiden-responsive">Cantidad disponible al momento del despacho</th>
								<th className="hiden-responsive">Precio a crédito</th>
							</tr>
						</thead>
						<tbody>
							{this.state.products.map(pro => (
								<tr key={pro.uuid}>
									<td>
										<NavLink className="item-pro">
											{pro.image !== undefined ? (
												<img src={e.urlBucket + pro.image} alt={pro.name} />
											) : (
												<img src={require('img/defecto.jpg')} alt={pro.name} />
											)}
											<span>{pro.name}</span>
										</NavLink>
									</td>
									<td>
										<strong>{pro.quantity}</strong>
									</td>
									<td className="hiden-responsive">
										<p>{pro.disponibles}</p>
									</td>
									<td className="hiden-responsive">{Price(pro.price_credito)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(ShowDispatch)

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import withParams from 'componentes/WithParams'
import Modal from 'componentes/modal'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import { v4 as uuidv4 } from 'uuid'
import Back from 'componentes/back'
import DB from 'utiles/db'
const categorias = new DB('categories')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalCreate: false,
			categoryEdit: {},
			data: []
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ loading: false, data: data.data.Items })
				console.log(data)
			})
			.catch(error => {
				console.error(error)
			})
	}

	registerCategory = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '') {
			this.setState({ modalCreate: false, loading: true }, async () => {
				const result = await categorias.create({
					uuid: uuidv4(),
					cava: this.props.cava.uuid,
					name: data.get('name')
				})
				if (result.status) {
					this.setState({ loading: false, name_store: '' }, () => {
						this.init()
						Alert('Categoria creada correctamente.')
					})
				} else {
					this.setState({ loading: false, name_store: '' })
				}
			})
		} else {
			Alert('Ingresa el nombre de la categoria.', 'danger', 7000)
		}
	}

	updateCategory = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '') {
			const categoria = this.state.categoryEdit
			this.setState({ loading: true, categoryEdit: {} }, async () => {
				const updated = await categorias.update({
					Key: { uuid: categoria.uuid },
					UpdateExpression: 'SET #n = :name',
					ExpressionAttributeValues: { ':name': data.get('name') },
					ExpressionAttributeNames: { '#n': 'name' },
					ReturnValues: 'UPDATED_NEW'
				})

				if (updated.status) {
					this.setState({ loading: false, name_store: '' }, () => {
						this.init()
						Alert('Categoria actualizada correctamente.')
					})
				} else {
					this.setState({ loading: false, name_store: '' })
				}
			})
		} else {
			Alert('Ingresa el nombre de la categoria.', 'danger', 7000)
		}
	}

	rederCreate() {
		return (
			<Modal active={this.state.modalCreate}>
				<form action="#" onSubmit={this.registerCategory}>
					<div className="title-page">
						<div className="title">
							<h2>Nueva categoria</h2>
						</div>
					</div>
					<Input type="text" title="Nombre de la categoria *" placeholder="Ingresa el nombre" name="name" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalCreate: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	rederEdit() {
		return (
			<Modal active={'uuid' in this.state.categoryEdit}>
				<form action="#" onSubmit={this.updateCategory}>
					<div className="title-page">
						<div className="title">
							<h2>Nueva categoria</h2>
						</div>
					</div>
					<Input type="text" title="Nombre de la categoria *" placeholder="Ingresa el nombre" name="name" value={this.state.categoryEdit.name} />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ categoryEdit: {} })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.rederCreate()}
				{this.rederEdit()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Productos | Categorias</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalCreate: true })
							}}
							className="btn">
							Nueva categoria
						</a>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('../../img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Categoria</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(categoria => (
								<tr key={categoria.uuid}>
									<td>{categoria.name}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.setState({ categoryEdit: categoria })
											}}
											className="btn-edit">
											Editar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(AnimateScreen(Page))

import { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Menu from 'componentes/menu'
import Header from 'componentes/header'
import Footer from 'componentes/footer'
function Dashboard(props) {
	const [mini, setMini] = useState(false)
	const handleResize = () => {
		if (window.innerWidth <= 1024) {
			setMini(true)
		} else {
			setMini(false)
		}
	}
	window.addEventListener('resize', handleResize)
	useEffect(() => {
		handleResize()
	}, [])

	if (props.user !== null && props.cava !== null) {
		return (
			<>
				<div id="notifications"></div>
				<main className={`${mini ? 'mini-menu' : ''}`}>
					<Menu
						cava={props.cava}
						setUser={props.setUser}
						setCava={props.setCava}
						navigate={props.navigate}
						rol={props.user.rol}
						super={props.user.rol === 'Super'}
					/>
					<div className="content">
						<Header setMini={setMini} mini={mini} user={props.user} cava={props.cava} />
						<aside>
							<Outlet user={props.user} />
						</aside>
						<Footer />
					</div>
				</main>
			</>
		)
	} else {
		return <Navigate to="/" />
	}
}

export default Dashboard

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import DB from 'utiles/db'
import Table from 'componentes/Table'
import { setLoading } from 'store'
import { hoy, getDate, Price, getDateParse, getHourParse, Alert } from 'utiles/functions'
const waysTable = new DB('ways')
const dispatchTheCollectorsTable = new DB('dispatchthecollectors')
const usersTable = new DB('users')

class Inicio extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cobradores: {},
			syncWays: [],
			precierreWays: [],
			dispatchs: {},
			cantidadProductosDespachos: 0,
			totalProductosDespachos: 0,
			tab: 1
		}
	}

	async componentDidMount() {
		try {
			setLoading(true)
			// Consultamos las rutas sincronizadas y en precierre
			const query1 = [
				'#cava = :c AND (#sync = :sync OR #precierre = :precierre)',
				{
					':c': this.props.cava.uuid,
					':sync': 'Sincronizada',
					':precierre': true
				},
				{
					'#cava': 'cava',
					'#sync': 'sync',
					'#precierre': 'precierre'
				}
			]
			const resutlWays = await waysTable.query(query1[0], query1[1], query1[2])
			if (resutlWays.status) {
				const uuidDispatchs = []
				const emailsCobradores = []
				const syncWays = []
				const precierreWays = []
				for (const way of resutlWays.data.Items) {
					if (way.precierre) {
						precierreWays.push(way)
					} else {
						syncWays.push(way)
					}
					if (way.dispatch !== '') {
						uuidDispatchs.push(way.dispatch)
					}
					if (way.cobrador !== '' && !emailsCobradores.includes(way.cobrador)) {
						emailsCobradores.push(way.cobrador)
					}
				}
				// Consultamos los despachos de las rutas
				const resultDispatchs = await dispatchTheCollectorsTable.query('contains(:uuids, #uuid)', { ':uuids': uuidDispatchs }, { '#uuid': 'uuid' })
				const dispatchs = []
				let cantidadProductosDespachos = 0
				let totalProductosDespachos = 0
				if (resultDispatchs.status) {
					for (const dispatch of resultDispatchs.data.Items) {
						dispatchs[dispatch.uuid] = dispatch
						cantidadProductosDespachos += dispatch.quantity_products
						totalProductosDespachos += dispatch.price_credito
					}
				}
				// Consultamos los cobradores de las rutas
				const resultCobradores = await usersTable.query('contains(:emails, #email)', { ':emails': emailsCobradores }, { '#email': 'email' })
				const cobradores = []
				if (resultCobradores.status) {
					for (const user of resultCobradores.data.Items) {
						cobradores[user.email] = user.name
					}
				}
				this.setState({
					syncWays,
					precierreWays,
					dispatchs,
					cantidadProductosDespachos,
					totalProductosDespachos,
					cobradores
				})
				setLoading(false)
			} else {
				this.setState({ loading: false })
				Alert('No se pudo consultar las rutas sincronizadas y en pre-cierre - error interno', 'danger')
			}
		} catch (error) {
			setLoading(false)
			Alert('Ocurrio un error inexperado', 'danger')
			console.error(error)
		}
	}

	render() {
		const columnsSyncWays = [
			{ header: 'Ruta', accessorKey: 'name' },
			{ header: 'Cobrador', accessorKey: 'cobrador', cell: ({ row }) => this.state.cobradores[row.original.cobrador] || 'Sin cobrador' },
			{
				header: 'Fecha de sincronización',
				accessorKey: 'syncAt',
				cell: ({ row }) => (row.original.dispatch === '' ? 'N/A' : getDateParse(getDate(row.original.syncAt)))
			},
			{
				header: 'Hora de sincronización',
				accessorKey: 'syncAt',
				cell: ({ row }) => (row.original.dispatch === '' ? 'N/A' : getHourParse(getDate(row.original.syncAt)))
			},
			{
				header: 'Avaluo mercancia',
				accessorKey: 'dispatch',
				cell: ({ row }) => (row.original.dispatch === '' ? 'N/A' : Price(this.state.dispatchs[row.original.dispatch].price_credito))
			},
			{
				header: 'Cantidad productos despachados',
				accessorKey: 'dispatch',
				cell: ({ row }) => (row.original.dispatch === '' ? 'N/A' : this.state.dispatchs[row.original.dispatch].quantity_products)
			},
			{
				header: 'Acciones',
				cell: props => {
					const way = props.row.original
					return (
						<>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.props.navigate('/dashboard/waysync/' + way.uuid)
								}}
								className="btn-edit">
								Detalles
							</a>
						</>
					)
				}
			}
		]
		const columnsPrecierreWays = [
			{ header: 'Ruta', accessorKey: 'name' },
			{ header: 'Cobrador', accessorKey: 'cobrador', cell: ({ row }) => this.state.cobradores[row.original.cobrador] || 'Sin cobrador' },
			{ header: 'Fecha pre-cierre', accessorKey: 'datePrecierre', cell: ({ row }) => getDateParse(new Date(row.original.datePrecierre)) },
			{ header: 'Efectivo', accessorKey: 'efectivoPrecierre', cell: ({ row }) => Price(row.original.efectivoPrecierre) },
			{ header: 'Transferencias', accessorKey: 'transferenciasPrecierre', cell: ({ row }) => Price(row.original.transferenciasPrecierre) },
			{ header: 'Total regado', accessorKey: 'regadoPrecierre', cell: ({ row }) => Price(row.original.regadoPrecierre) },
			{
				header: 'Acciones',
				cell: props => {
					const way = props.row.original
					return (
						<>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.props.navigate('/dashboard/waysync/' + way.uuid)
								}}
								className="btn-edit">
								Detalles
							</a>
						</>
					)
				}
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Inicio</h2>
						<span>Estado global</span>
					</div>
				</div>

				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Rutas sincronizadas</span>
							<strong>{this.state.syncWays.length}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Rutas en pre-cierre</span>
							<strong>{this.state.precierreWays.length}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Productos despachados</span>
							<strong>{this.state.cantidadProductosDespachos}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avalúo mercancia despachada</span>
							<strong>{Price(this.state.totalProductosDespachos)}</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="title-page">
					<div className="title">
						<h2>Rutas sincronizadas</h2>
						<span>{hoy()}</span>
					</div>
				</div>
				<div className="card">
					<div className="btns-tabs">
						<a
							className={`${this.state.tab === 1 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 1 })
							}}>
							Rutas sincronizadas
						</a>
						<a
							className={`${this.state.tab === 2 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 2 })
							}}>
							Rutas en pre-cierre
						</a>
					</div>
					<div className="tab" style={{ display: this.state.tab === 1 ? 'block' : 'none' }}>
						<h2 style={{ marginBottom: 10 }}>Rutas sincronizadas</h2>
						<Table columns={columnsSyncWays} data={this.state.syncWays} pageSize={40} />
					</div>
					<div className="tab" style={{ display: this.state.tab === 2 ? 'block' : 'none' }}>
						<h2 style={{ marginBottom: 10 }}>Rutas en pre-cierre</h2>
						<Table columns={columnsPrecierreWays} data={this.state.precierreWays} pageSize={40} />
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Inicio)

import { Component } from 'react'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage } from 'utiles/functions'
import Back from 'componentes/back'
import DB from 'utiles/db'
const users = new DB('users')
const cavas = new DB('cavas')
const bcrypt = require('bcryptjs')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			avatar: null,
			isCobrador: ''
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const user = {
			name: data.get('name'),
			cava: this.props.cava.uuid,
			rol: data.get('rol'),
			status: data.get('status'),
			phone: data.get('phone'),
			date: data.get('date'),
			comision: Number(data.get('comision')),
			email: data.get('email'),
			password: data.get('password')
		}
		if (user.name !== '' && user.cava !== '' && user.rol !== '' && user.phone !== '' && user.email !== '' && user.password !== '') {
			if (this.state.isCobrador === 'Cobrador') {
				if (user.comision < 100) {
					if (user.comision > 0) {
						if (user.password.length > 6) {
							if (user.password === data.get('password_confirm')) {
								this.setState({ loading: true }, async () => {
									const r = await users.get({ email: user.email })
									if (r.status) {
										if (r.data.Count > 0) {
											this.setState({ loading: false })
											Alert('¡Ooops!, el Correo electrónico ya se encuentra registrado', 'danger')
										} else {
											const hash = bcrypt.hashSync(user.password, 8)
											user.password = hash
											if (this.state.avatar !== null) {
												const url = await uploadImage(this.state.avatar)
												user.avatar = url
											}
											const result = await users.create(user)
											if (result.status) {
												this.setState({ loading: false })
												this.props.navigate('dashboard/users')
												Alert('Usuario registrado correctamente.')
											} else {
												this.setState({ loading: false })
												Alert('¡Ooops!, tenemos un error al crear el usuario, intenta de nuevo', 'danger')
											}
										}
										const result = await users.create(user)

										if (user.rol === 'Cobrador') {
											await cavas.newUpdate(
												{
													count_cobradores: Number(this.props.cava.count_cobradores || 0) + 1
												},
												{
													uuid: this.props.cava.uuid
												}
											)
											const cava = this.props.cava
											cava.count_cobradores = Number(this.props.cava.count_cobradores || 0) + 1
											this.props.setCava(cava)
										}

										if (result.status) {
											this.setState({ loading: false })
											this.props.navigate('dashboard/users')
											Alert('Usuario registrado correctamente.')
										} else {
											this.setState({ loading: false })
											Alert('¡Ooops!, tenemos un error al crear el usuario, intenta de nuevo', 'danger')
										}
									}
								})
							} else {
								Alert('Las contraseñas no coinciden.', 'danger', 7000)
							}
						} else {
							Alert('La contraseña es demasiado corta.', 'danger', 7000)
						}
					} else {
						Alert('El porcentaje de comisión no debe menor a 0', 'danger', 7000)
					}
				} else {
					Alert('El porcentaje de comisión no debe ser mayor a 100%.', 'danger', 7000)
				}
			} else {
				if (user.password.length > 6) {
					if (user.password === data.get('password_confirm')) {
						this.setState({ loading: true }, async () => {
							const r = await users.get({ email: user.email })
							if (r.status) {
								if (r.data.Count > 0) {
									this.setState({ loading: false })
									Alert('¡Ooops!, el Correo electrónico ya se encuentra registrado', 'danger')
								} else {
									const hash = bcrypt.hashSync(user.password, 8)
									user.password = hash
									if (this.state.avatar !== null) {
										const url = await uploadImage(this.state.avatar)
										user.avatar = url
									}
									const result = await users.create(user)
									if (result.status) {
										this.setState({ loading: false })
										this.props.navigate('dashboard/users')
										Alert('Usuario registrado correctamente.')
									} else {
										this.setState({ loading: false })
										Alert('¡Ooops!, tenemos un error al crear el usuario, intenta de nuevo', 'danger')
									}
								}
								const result = await users.create(user)

								if (user.rol === 'Cobrador') {
									await cavas.newUpdate(
										{
											count_cobradores: Number(this.props.cava.count_cobradores || 0) + 1
										},
										{
											uuid: this.props.cava.uuid
										}
									)
									const cava = this.props.cava
									cava.count_cobradores = Number(this.props.cava.count_cobradores || 0) + 1
									this.props.setCava(cava)
								}

								if (result.status) {
									this.setState({ loading: false })
									this.props.navigate('dashboard/users')
									Alert('Usuario registrado correctamente.')
								} else {
									this.setState({ loading: false })
									Alert('¡Ooops!, tenemos un error al crear el usuario, intenta de nuevo', 'danger')
								}
							}
						})
					} else {
						Alert('Las contraseñas no coinciden.', 'danger', 7000)
					}
				} else {
					Alert('La contraseña es demasiado corta.', 'danger', 7000)
				}
			}
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Usuarios</h2>
						<span>Nuevo usuario</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="image" name="avatar" title="Avatar" change={data => this.setState({ avatar: data })} />
							<Input type="text" title="Nombre del usuario" placeholder="Ingresa el nombre" name="name" />
							<Input
								type="select"
								name="rol"
								title="Selecionar rol *"
								options={[
									{ val: '', text: 'Seleccionar' },
									{ val: 'Administrador', text: 'Administrador' },
									{ val: 'Supervisor', text: 'Supervisor' },
									{ val: 'Cavero', text: 'Cavero' },
									{ val: 'Cobrador', text: 'Cobrador' },
									{ val: 'Auxiliar', text: 'Auxiliar' }
								]}
								change={val => {
									this.setState({ isCobrador: val })
								}}
							/>
							<Input
								type="select"
								name="status"
								title="Estado *"
								options={[
									{ val: 'Activo', text: 'Activo' },
									{ val: 'Inactivo', text: 'Inactivo' }
								]}
							/>
						</div>
						<div className="column">
							<Input type="number" title="Telefono *" placeholder="Ingresa el telefono" name="phone" />
							<Input type="date" title="Fecha de nacimiento" placeholder="Ingresa la fecha de nacimiento" name="date" />
							<Input type="email" title="Correo electrónico *" placeholder="Ingresa el Correo electrónico" name="email" />
							<Input type="password" title="Contraseña *" placeholder="Ingresa la contraseña" name="password" />
							<Input type="password" title="Confirmar contraseña *" placeholder="Confirma la contraseña" name="password_confirm" />
							{this.state.isCobrador === 'Cobrador' && (
								<Input type="number" title="Porcentaje de comisión" placeholder="Ingresa el % de comisión" name="comision" />
							)}
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn m-auto" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default Page

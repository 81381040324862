import { NavLink } from 'react-router-dom'
import e from 'utiles/settings'

const rolesAdmin = ['Administrador', 'Super']
export default function Menu(props) {
	return (
		<nav>
			{props.cava && (
				<div className="avatar">
					<div className="container-avater">{props.cava?.logo ? <img src={e.urlBucket + props.cava.logo} /> : <p>{props.cava.name[0]}</p>}</div>
					<div className="text">
						<span>{props.cava.name}</span>
					</div>
				</div>
			)}
			<strong>MENU</strong>
			<ul>
				{(rolesAdmin.includes(props.rol) || props.rol === 'Supervisor') && (
					<li>
						<NavLink to="/dashboard/inicio">
							<img src={require('../img/home.svg').default} height={18} width={18} /> Inicio
						</NavLink>
					</li>
				)}
				{(rolesAdmin.includes(props.rol) || props.rol === 'Cavero' || props.rol === 'Supervisor') && (
					<li>
						<NavLink to="/dashboard/dispatchthecollectors">
							<img src={require('../img/routing.svg').default} width={18} height={18} /> Despachos
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/cashMovements">
							<img src={require('../img/money-send.svg').default} width={18} height={18} /> Movimientos de caja
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/loans">
							<img src={require('../img/transaction-minus.svg').default} width={18} height={18} /> Préstamos
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/payroll">
							<img src={require('../img/transaction-minus.svg').default} width={18} height={18} /> Nómina
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/spents">
							<img src={require('../img/spents.svg').default} width={18} height={18} /> Gastos de operación
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/rawmaterials">
							<img src={require('../img/wallet.svg').default} width={18} height={18} /> Compra materia prima
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/providers">
							<img src={require('../img/providers.svg').default} width={18} height={18} /> Proveedores
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/products">
							<img src={require('../img/products.svg').default} width={18} height={18} /> Productos
						</NavLink>
					</li>
				)}
				{(rolesAdmin.includes(props.rol) || props.rol === 'Supervisor') && (
					<li>
						<NavLink to="/dashboard/clients">
							<img src={require('../img/clients.svg').default} width={18} height={18} /> Clientes
						</NavLink>
					</li>
				)}
				{rolesAdmin.includes(props.rol) && (
					<li>
						<NavLink to="/dashboard/users">
							<img src={require('../img/users.svg').default} width={18} height={18} /> Usuarios
						</NavLink>
					</li>
				)}
				{(rolesAdmin.includes(props.rol) || props.rol === 'Supervisor') && (
					<li>
						<NavLink to="/dashboard/ways">
							<img src={require('../img/routing.svg').default} width={18} height={18} /> Rutas
						</NavLink>
					</li>
				)}
				{props.super && (
					<li>
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								props.setCava(null)
								props.navigate('/admin/inicio', { replace: true })
							}}>
							<img src={require('../img/arrow-left.svg').default} width={18} height={18} /> Volver al panel
						</a>
					</li>
				)}
				<li>
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							localStorage.setItem('@user_cava', '')
							props.setUser(null)
							props.navigate('/', { replace: true })
						}}>
						<img src={require('../img/arrow-left.svg').default} width={18} height={18} /> Cerrar sesión
					</a>
				</li>
			</ul>
		</nav>
	)
}

import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { v4 as uuidv4 } from 'uuid'
import { Alert, Price } from 'utiles/functions'
import DB from 'utiles/db'
import Modal from 'componentes/modal'
import e from 'utiles/settings'
import Back from 'componentes/back'
const paymentmethods = new DB('paymentmethods')
const cashs = new DB('cashs')
const movBoxes = new DB('movBoxes')
const loans = new DB('loans')
const users = new DB('users')
const products = new DB('products')
const categorias = new DB('categories')
const inventoryTweaks = new DB('inventory_tweaks')
const cates = []

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			valor: '',
			voucher: '',
			type: '',
			description: '',
			cajas: [],
			metodos: [],
			infoCajas: [],
			infoMetodos: [],
			data: [],
			usuarios: [],
			caja: '',
			metodo: '',
			modalProducts: false,
			productosSeleccionados: {},
			productosAMostrar: [],
			cantidadProductos: 0,
			costo: 0,
			disponibleCaja: 0
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})

		products
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					this.setState({ data: data.data.Items })
				}
			})
			.catch(error => {
				console.error(error)
			})

		users
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const usuarios = data.data.Items.map(val => {
					return { val: val.email, text: val.name }
				})
				usuarios.unshift({ val: '', text: 'Selecciona el empleado' })
				this.setState({ usuarios })
			})
			.catch(error => {
				console.error(error)
			})

		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const cajas = data.data.Items.map(val => {
					return { val: val.uuid, text: val.name }
				})
				cajas.unshift({ val: '', text: 'Selecciona la caja' })
				this.setState({ cajas, infoCajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const metodos = data.data.Items.map(val => {
					return { val: val.uuid, text: val.name }
				})
				metodos.unshift({ val: '', text: 'Selecciona el metodo' })
				this.setState({ metodos, infoMetodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
	}

	// funciones de modal de seleccionar productos
	seleccionProductos(uuid) {
		const productosSeleccionados = this.state.productosSeleccionados

		if (productosSeleccionados[uuid] !== undefined) {
			delete productosSeleccionados[uuid]
		} else {
			productosSeleccionados[uuid] = true
		}
		this.setState({ productosSeleccionados })
		this.LoadSelectedProducts()
	}

	valorProducto(productosSeleccionados, uuid) {
		const productosSeleccionadosar = productosSeleccionados
		if (productosSeleccionadosar[uuid] === true) {
			return 'on'
		}
	}

	// cargar productos en la tabla
	LoadSelectedProducts() {
		const productosSeleccionados = this.state.productosSeleccionados
		const productosAMostrar = []
		for (const uuid in productosSeleccionados) {
			if (productosSeleccionados[uuid] === true) {
				const productoEncontrado = this.state.data.find(producto => producto.uuid === uuid)
				if (productoEncontrado) {
					productosAMostrar.push(productoEncontrado)
				}
			}
		}
		this.setState({ productosAMostrar })
	}

	// obtener la cantidad
	getValueQuantity(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.quantity || producto.quantity === 0) {
				return producto.quantity
			}
		}
	}

	// obtener lea costo
	getValueCost(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.cost) {
				return producto.cost
			}
		}
	}

	// valor unitario
	getValueUnitCost(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.quantity) {
				return Price(producto.price_operation * producto.quantity + (producto.cost > 0 ? producto.cost * producto.quantity : 0))
			}
		}
	}

	// eliminar producto de la orden de compra
	deleteProductBuy(uuid) {
		// eliminar de la orden de compra
		const nuevosProductos = this.state.productosAMostrar.filter(producto => producto.uuid !== uuid)
		this.setState({ productosAMostrar: nuevosProductos })
		// eliminar el estado del check en la seleccion de productos
		this.seleccionProductos(uuid)
	}

	// cambiar cantidad
	changeQuantity(quantity, uuid) {
		let cantidadProductos = 0
		let costo = 0
		const productosAMostrar = this.state.productosAMostrar.map(product => {
			if (product.uuid === uuid) {
				product.quantity = quantity
			}
			if (product.quantity) {
				cantidadProductos += Number(product.quantity)
				if (!product.cost) {
					product.cost = 1000
				}
				// costo
				costo += Number(product.price_operation * product.quantity + product.cost * product.quantity)
			}

			return product
		})
		this.setState({ productosAMostrar, cantidadProductos, costo })
	}

	// cambiar costo total
	changeCost(cost, uuid) {
		const productosAMostrar = this.state.productosAMostrar.map(product => {
			if (product.uuid === uuid) {
				product.cost = cost
			}

			return product
		})
		this.setState({ productosAMostrar })
	}

	guardar() {
		if (this.state.empleado !== '') {
			if (this.state.type !== '') {
				if (this.state.costo !== '') {
					// si es producto
					if (this.state.type === 'productos') {
						this.registrarBd()
					}
					// si es caja
					if (this.state.type === 'dinero') {
						if (this.state.caja !== '') {
							if (this.state.metodo !== '') {
								if (this.state.costo < this.state.disponibleCaja) {
									this.registrarBd()
								} else {
									Alert('No hay dinero disponible suficiente en la caja o método de pago que seleccionaste', 'danger')
								}
							} else {
								Alert('Selecciona el método de pago', 'danger')
							}
						} else {
							Alert('Selecciona la caja', 'danger')
						}
					}
				} else {
					Alert('No puedes guardar un prestamo en $0 COP', 'danger')
				}
			} else {
				Alert('Selecciona el tipo de prestamo', 'danger')
			}
		} else {
			Alert('Selecciona el empleado', 'danger')
		}
	}

	registrarBd() {
		this.setState({ loading: true }, async () => {
			// registrar movimiento
			// construir la info del usuario
			const usuarioAjuste = {
				email: this.props.user.email,
				name: this.props.user.name,
				rol: this.props.user.rol
			}
			const productosCompra = this.state.productosAMostrar.map(product => {
				return {
					uuid: product.uuid,
					image: product.image,
					category: product.category,
					name: product.name,
					price_credito: Number(product.price_credito),
					price_operation: Number(product.price_operation),
					quantity: Number(product.quantity),
					stock: Number(product.stock),
					cost: Number(product.cost)
				}
			})

			// prestamo
			await loans.create({
				uuid: uuidv4(),
				empleado: this.state.empleado,
				cava: this.props.cava.uuid,
				tipo: this.state.type,
				total: Number(this.state.costo),
				productos: productosCompra,
				caja: this.state.caja,
				metodo: this.state.metodo,
				estado: 'pendiente',
				usuario: usuarioAjuste
			})

			if (this.state.type === 'dinero') {
				await movBoxes.create({
					uuid: uuidv4(),
					cava: this.props.cava.uuid,
					caja_uuid: this.state.caja,
					metodo_uuid: this.state.metodo,
					type: 'egreso',
					value: Number(this.state.costo),
					description: 'Préstamo a empleado',
					voucher: '',
					user: usuarioAjuste
				})

				// editar caja
				const laCaja = this.state.infoCajas.find(caja => caja.uuid === this.state.caja)
				let totalcaja = 0
				if (laCaja[this.state.metodo]) {
					totalcaja = laCaja[this.state.metodo].amount
				}
				totalcaja -= this.state.costo

				const method = this.state.infoMetodos.find(metodo => metodo.uuid === this.state.metodo)
				const metodo = {
					amount: Number(totalcaja),
					name: method.name
				}
				await cashs.update({
					Key: { uuid: this.state.caja },
					UpdateExpression: `SET #m = :m`,
					ExpressionAttributeValues: {
						':m': metodo
					},
					ExpressionAttributeNames: {
						'#m': this.state.metodo
					},
					ReturnValues: 'UPDATED_NEW'
				})
			}
			if (this.state.type === 'productos') {
				// guardar ajuste de inventario
				const productosAjustados = []
				// construir arreglo de productos editados
				for (const product of this.state.productosAMostrar) {
					productosAjustados.push({
						uuid: product.uuid,
						name: product.name,
						image: product.image,
						quantity_old: Number(product.stock),
						quantity_new: Number(product.stock) - Number(product.quantity),
						price_credito: Number(product.price_credito)
					})
				}
				// guardar ajuste de inventario
				await inventoryTweaks.create({
					uuid: uuidv4(),
					user: usuarioAjuste,
					description: 'Préstamo de productos',
					tweaks: productosAjustados,
					cava: this.props.cava.uuid
				})

				// ajustar cantidades en los productos
				productosAjustados.map(async producto => {
					await products.update({
						Key: { uuid: producto.uuid },
						UpdateExpression: 'SET #s = :stock',
						ExpressionAttributeValues: {
							':stock': Number(producto.quantity_new)
						},
						ExpressionAttributeNames: {
							'#s': 'stock'
						},
						ReturnValues: 'UPDATED_NEW'
					})
				})
			}
			this.setState({ loading: false })
			this.props.navigate('/dashboard/loans')
			Alert('Préstamo realizado con éxito.')
		})
	}

	evaluarDisponibilidad() {
		if (this.state.costo !== '') {
			if (this.state.caja !== '') {
				if (this.state.metodo !== '') {
					this.setState({ loading: true }, () => {
						cashs
							.get({ uuid: this.state.caja })
							.then(data => {
								const caja = data.data.Item
								if (caja[this.state.metodo]) {
									const amount = caja[this.state.metodo].amount
									this.setState({ disponibleCaja: amount })
									if (this.state.costo > amount) {
										Alert('No hay dinero disponible suficiente en la caja o método de pago que seleccionaste', 'danger')
									}
								} else {
									this.setState({ disponibleCaja: 0 })
									Alert('No hay dinero disponible suficiente en la caja o método de pago que seleccionaste', 'danger')
								}
								this.setState({ loading: false })
							})
							.catch(error => {
								console.error(error)
								this.setState({ loading: false })
								Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo')
							})
					})
				} else {
					Alert('Por favor selecciona el método de pago', 'danger')
				}
			} else {
				Alert('Por favor selecciona la caja', 'danger')
			}
		} else {
			Alert('Por favor ingresa el valor del préstamo', 'danger')
		}
	}

	renderProducts() {
		return (
			<Modal active={this.state.modalProducts} className="lamodal">
				<table className="table">
					<thead>
						<tr>
							<th>Producto</th>
							<th>categoría</th>
							<th>costo operacion</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.data.map(pro => (
							<tr key={pro.uuid}>
								<td>
									<NavLink className="item-pro">
										{pro.image !== undefined ? (
											<img src={e.urlBucket + pro.image} alt={pro.name} />
										) : (
											<img src={require('../../img/defecto.jpg')} alt={pro.name} />
										)}
										<span>{pro.name}</span>
									</NavLink>
								</td>
								<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
								<td>{Price(pro.price_operation)}</td>
								<td>
									<Input
										type="checkbox"
										id=""
										title=""
										active={this.valorProducto(this.state.productosSeleccionados, pro.uuid)}
										change={val => {
											this.seleccionProductos(pro.uuid)
										}}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalProducts: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.renderProducts()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Préstamos</h2>
						<span>Nuevo</span>
					</div>
					<div className="btns">
						<button className="btn" onClick={() => this.guardar()}>
							Guardar
						</button>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total prestado</span>
							<strong>{Price(this.state.costo)}</strong>
						</div>
					</div>
					{this.state.type === 'productos' && (
						<div className="card">
							<div className="item-widget">
								<span>Cantidad productos</span>
								<strong>{this.state.cantidadProductos}</strong>
							</div>
						</div>
					)}
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input
								type="select"
								title="Empleado *"
								placeholder=""
								name="name"
								value={this.state.empleado}
								change={val => this.setState({ empleado: val })}
								options={this.state.usuarios}
							/>
						</div>
						<div className="column">
							<Input
								type="select"
								title="Tipo de prestamo *"
								placeholder=""
								name="name"
								value={this.state.type}
								change={val =>
									this.setState({
										type: val,
										costo: 0,
										productosAMostrar: [],
										productosSeleccionados: {},
										cantidadProductos: 0
									})
								}
								options={[
									{
										val: '',
										text: 'Selecciona el tipo del préstamo'
									},
									{
										val: 'productos',
										text: 'Productos'
									},
									{
										val: 'dinero',
										text: 'Dinero'
									}
								]}
							/>
						</div>
					</div>
					<div className="divider"></div>
					{this.state.type === 'dinero' && (
						<>
							<h2>Selecciona la salida del dinero</h2>
							<Input type="number" value={this.state.costo} change={val => this.setState({ costo: val })} />

							<p>
								<strong>Disponible caja seleccionada: </strong>
								{Price(this.state.disponibleCaja)}
							</p>

							<div className="columns">
								<div className="column">
									<Input
										type="select"
										title="Caja *"
										placeholder=""
										name="name"
										value={this.state.caja}
										change={val => this.setState({ caja: val }, () => this.evaluarDisponibilidad())}
										options={this.state.cajas}
									/>
								</div>
								<div className="column">
									<Input
										type="select"
										title="Método *"
										placeholder=""
										name="name"
										value={this.state.metodo}
										change={val => this.setState({ metodo: val }, () => this.evaluarDisponibilidad())}
										options={this.state.metodos}
									/>
								</div>
							</div>
						</>
					)}
					{this.state.type === 'productos' && (
						<div className="productos">
							<div className="columns">
								<div className="column">
									<h3>Productos prestados</h3>
								</div>
								<div className="column" style={{ textAlign: 'right' }}>
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											this.setState({ modalProducts: true })
										}}
										style={{ display: 'inline-block' }}
										className="btn">
										Seleccionar productos
									</a>
								</div>
							</div>
							<div className="divider"></div>
							<table className="table">
								<thead>
									<tr>
										<th>Producto</th>
										<th>Categroría</th>
										<th>Costo operación</th>
										<th>Cantidad prestada</th>
										<th>Incremento por unidad</th>
										<th>Costo total</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{this.state.productosAMostrar.map(pro => (
										<tr key={pro.uuid}>
											<td>
												<NavLink className="item-pro">
													{pro.image !== undefined ? (
														<img src={e.urlBucket + pro.image} alt={pro.name} />
													) : (
														<img src={require('../../img/defecto.jpg')} alt={pro.name} />
													)}
													<span>{pro.name}</span>
												</NavLink>
											</td>
											<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
											<td>{Price(pro.price_operation)}</td>
											<td>
												<Input
													type="number"
													placeholder="0"
													value={this.getValueQuantity(pro.uuid)}
													change={value => {
														this.changeQuantity(value, pro.uuid)
													}}
												/>
											</td>
											<td>
												<Input
													type="number"
													placeholder="0"
													value={this.getValueCost(pro.uuid)}
													change={value => {
														this.changeCost(value, pro.uuid)
													}}
												/>
											</td>
											<td>
												<Input type="text" placeholder="0" value={this.getValueUnitCost(pro.uuid)} readonly={true} />
											</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.deleteProductBuy(pro.uuid)
													}}
													className="btn-edit">
													Eliminar
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					<div className="divider"></div>
					<button className="btn" onClick={() => this.guardar()}>
						Guardar
					</button>
				</div>
			</>
		)
	}
}

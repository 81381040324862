import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import Input from 'componentes/input'
import Modal from 'componentes/modal'
import Back from 'componentes/back'
import { v4 as uuidv4 } from 'uuid'
import { Price, Alert } from 'utiles/functions'
const providers = new DB('providers')
const typespents = new DB('typespents')
const paymentmethods = new DB('paymentmethods')
const categorias = new DB('categories')
const cashs = new DB('cashs')
const spents = new DB('spents')
const movBoxes = new DB('movBoxes')
const cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalProducts: false,
			type: '',
			provider: '',
			types: [],
			providers: [],
			data: [],
			cajas: [],
			productosSeleccionados: {},
			modalCajas: false,
			modalMetodos: false,
			modalPago: false,
			cajasAMostrar: [],
			metodos: [],
			cajasSeleccionados: [],
			cajasSeleccionada: {},
			metodoSeleccionado: {},
			valorAbono: 0,
			costo: 0,
			Abonado: 0,
			Deuda: 0,
			description: '',
			totalDisponible: 0
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})
		providers
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					const provedorsoskis = data.data.Items.map(type => {
						return { val: type.uuid, text: type.name }
					})
					provedorsoskis.unshift({ val: '', text: 'Selecciona un proveedor' })
					this.setState({ providers: provedorsoskis })
				}
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ metodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ cajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})

		typespents
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					const tipos = data.data.Items.map(type => {
						return { val: type.uuid, text: type.name }
					})
					tipos.unshift({ val: '', text: 'Selecciona un tipo de gasto' })
					this.setState({ loading: false, types: tipos })
				} else {
					this.setState({ loading: false })
				}
			})
			.catch(error => {
				console.error(error)
			})
	}

	// consultar el total de cada caja
	totalCaja(caja) {
		let totalCaja = 0
		// recorro los metodos de pago
		for (const metodo of this.state.metodos) {
			if (caja[metodo.uuid]) {
				totalCaja += Number(caja[metodo.uuid].amount)
			}
		}
		return Price(totalCaja)
	}

	totalCajaMoney(caja) {
		let totalCaja = 0
		// recorro los metodos de pago
		for (const metodo of this.state.metodos) {
			if (caja[metodo.uuid]) {
				totalCaja += Number(caja[metodo.uuid].amount)
			}
		}
		return totalCaja
	}

	// seleccionar caja
	seleccionCaja(uuid) {
		const cajasSeleccionados = this.state.cajasSeleccionados
		if (cajasSeleccionados[uuid] !== undefined) {
			delete cajasSeleccionados[uuid]
		} else {
			cajasSeleccionados[uuid] = true
		}
		this.setState({ cajasSeleccionados })
		this.LoadSelectedCajas()
	}

	valorCaja(cajasSeleccionados, uuid) {
		const cajasSeleccionadosar = cajasSeleccionados
		return cajasSeleccionadosar[uuid] === true ? 'on' : ''
	}

	// listado de cajas
	LoadSelectedCajas() {
		const cajasSeleccionados = this.state.cajasSeleccionados
		const cajasAMostrar = []
		for (const uuid in cajasSeleccionados) {
			if (cajasSeleccionados[uuid] === true) {
				const productoEncontrado = this.state.cajas.find(producto => producto.uuid === uuid)
				if (productoEncontrado) {
					cajasAMostrar.push(productoEncontrado)
				}
			}
		}
		this.setState({ cajasAMostrar })
	}

	// seleccionar caja
	seleccionarCaja(cajasSeleccionada) {
		this.setState({ cajasSeleccionada, modalCajas: false, modalMetodos: true })
	}

	// seleccionar metodo
	seleccionarMetodoPago(metodoSeleccionado) {
		this.setState({ metodoSeleccionado, modalMetodos: false, modalPago: true })
	}

	// guardarAbono
	guardarAbono() {
		const cajasAMostrar = [...this.state.cajasAMostrar]
		const cajasSeleccionada = this.state.cajasSeleccionada
		const metodoSeleccionado = this.state.metodoSeleccionado
		const valorAbono = this.state.valorAbono

		const cajaConAbono = {
			...cajasSeleccionada,
			abono: {
				metodo: metodoSeleccionado,
				amount: valorAbono
			}
		}

		cajasAMostrar.push(cajaConAbono)
		this.setState(
			{
				cajasAMostrar,
				modalPago: false,
				cajasSeleccionada: {},
				metodoSeleccionado: {},
				valorAbono: 0
			},
			() => {
				this.calcularDeuda()
			}
		)
	}

	getDisponible() {
		if (this.state.metodoSeleccionado && this.state.cajasSeleccionada) {
			const metodoSeleccionadoUUID = this.state.metodoSeleccionado.uuid
			if (metodoSeleccionadoUUID in this.state.cajasSeleccionada) {
				const totalDispo = this.state.cajasSeleccionada[metodoSeleccionadoUUID].amount || Price(0)
				if (totalDispo !== this.state.totalDisponible) {
					this.setState({ totalDisponible: totalDispo })
				}
				return Price(totalDispo)
			}
		}
		return Price(0)
	}

	deleteAbono(index) {
		// Clona el array cajasAMostrar para no modificar el estado original directamente
		const cajasAMostrar = [...this.state.cajasAMostrar]

		// Utiliza el índice para eliminar el elemento correspondiente
		if (index >= 0 && index < cajasAMostrar.length) {
			cajasAMostrar.splice(index, 1) // Elimina 1 elemento en la posición 'index'

			// Actualiza el estado con la nueva matriz
			this.setState({ cajasAMostrar }, () => {
				this.calcularDeuda()
			})
		}
	}

	calcularDeuda() {
		let Deuda = this.state.costo
		let Abonado = 0
		for (const caja of this.state.cajasAMostrar) {
			Deuda -= caja.abono.amount
			Abonado += Number(caja.abono.amount)
		}
		this.setState({ Deuda, Abonado })
	}

	// validar el limite de los gastos
	validarMaximoAbono() {
		if (this.state.totalDisponible < this.state.valorAbono) {
			this.setState({ valorAbono: this.state.totalDisponible })
		}
	}

	// guardar
	guardarTodo() {
		if (this.state.costo > 0) {
			if (this.state.type !== '') {
				if (this.state.provider !== '') {
					// valido que ya haya pagado todo el gasto
					if (this.state.Deuda === 0) {
						this.registrarenBD()
					} else {
						Alert('Por favor termina de ingresar la salida del dinero', 'danger')
					}
				} else {
					Alert('Por favor selecciona el proveedor', 'danger')
				}
			} else {
				Alert('Por favor selecciona el tipo del gasto', 'danger')
			}
		} else {
			Alert('Por favor ingresa el monto del gasto', 'danger')
		}
	}

	registrarenBD() {
		this.setState({ loading: true }, async () => {
			const uuidGasto = uuidv4()
			// construir la info del usuario
			const usuarioAjuste = {
				email: this.props.user.email,
				name: this.props.user.name,
				rol: this.props.user.rol
			}
			// guardar gasto
			await spents.create({
				uuid: uuidGasto,
				cava: this.props.cava.uuid,
				rawmaterials: false,
				typespent: this.state.type,
				provider: this.state.provider,
				total: Number(this.state.costo),
				description: this.state.description,
				products: [],
				abonado: Number(this.state.Abonado),
				user: usuarioAjuste
			})

			this.state.cajasAMostrar.map(async caja => {
				// registrar movimiento de caja
				await movBoxes.create({
					uuid: uuidv4(),
					cava: this.props.cava.uuid,
					caja_uuid: caja.uuid,
					metodo_uuid: caja.abono.metodo.uuid,
					gasto_uuid: uuidGasto,
					type: 'egreso',
					value: Number(caja.abono.amount),
					description: `Gasto - ${this.state.type}`,
					user: usuarioAjuste
				})
				// actualizar total del metodo de pago en la caja
				const metodo = caja[caja.abono.metodo.uuid]
				const totalMetodo = Number(caja[caja.abono.metodo.uuid].amount) - Number(caja.abono.amount)
				metodo.amount = Number(totalMetodo)
				const expressionAttributeNames = {
					'#m': caja.abono.metodo.uuid
				}
				const expressionAttributeValues = {
					':m': metodo
				}
				await cashs.update({
					Key: { uuid: caja.uuid },
					UpdateExpression: `SET #m = :m`,
					ExpressionAttributeValues: expressionAttributeValues,
					ExpressionAttributeNames: expressionAttributeNames,
					ReturnValues: 'UPDATED_NEW'
				})
			})

			this.setState({ loading: false })
			this.props.navigate('/dashboard/spents')
			Alert('Gasto realizado con éxito.')
		})
	}

	renderCajas() {
		return (
			<Modal active={this.state.modalCajas} className="lamodal">
				<table className="table">
					<thead>
						<tr>
							<th>Caja</th>
							<th>total</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.cajas.map(pro => (
							<tr key={pro.uuid}>
								<td>{pro.name}</td>
								<td>{this.totalCaja(pro)}</td>
								<td>
									{this.totalCajaMoney(pro) === 0 ? (
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
											}}
											className="btn-edit disabled">
											Selecionar
										</a>
									) : (
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.seleccionarCaja(pro)
											}}
											className="btn-edit">
											Selecionar
										</a>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalCajas: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	renderMetodosPago() {
		return (
			<Modal active={this.state.modalMetodos} className="lamodal">
				<strong>Caja Seleccionada: {this.state.cajasSeleccionada.name}</strong>
				<p style={{ textAlign: 'left' }}>Total compra: {Price(this.state.costo)}</p>
				<p style={{ textAlign: 'left' }}>Restante por pagar: {Price(this.state.Deuda)}</p>
				<div className="divider"></div>
				<table className="table">
					<thead>
						<tr>
							<th>Método</th>
							<th>Disponible</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.metodos.map(metodo => (
							<>
								<tr>
									<td>{metodo.name}</td>
									{this.state.cajasSeleccionada[metodo.uuid] ? (
										<>
											<td>{Price(this.state.cajasSeleccionada[metodo.uuid].amount)}</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.seleccionarMetodoPago(metodo)
													}}
													className="btn-edit">
													Seleccionar
												</a>
											</td>
										</>
									) : (
										<>
											<td>0 COP</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
													}}
													className="btn-edit disabled">
													Seleccionar
												</a>
											</td>
										</>
									)}
								</tr>
							</>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalMetodos: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	renderPago() {
		return (
			<Modal active={this.state.modalPago} className="lamodal">
				<strong>Caja Seleccionada: {this.state.cajasSeleccionada.name}</strong>
				<p style={{ textAlign: 'left' }}>Total compra: {Price(this.state.costo)}</p>
				<p style={{ textAlign: 'left' }}>Restante por pagar: {Price(this.state.Deuda)}</p>
				<div className="divider"></div>
				<strong>Método de pago Seleccionado: {this.state.metodoSeleccionado.name}</strong>
				<p style={{ textAlign: 'left' }}>Disponible: {this.getDisponible()}</p>
				<Input
					type="number"
					value={this.state.valorAbono}
					title="Ingresa el valor del abono"
					change={value => {
						this.setState({ valorAbono: value }, () => this.validarMaximoAbono())
					}}
				/>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalPago: false })
						}}
						className="btn">
						Cerrar
					</a>
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.guardarAbono()
						}}
						className="btn">
						Guardar
					</a>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.renderCajas()}
				{this.renderMetodosPago()}
				{this.renderPago()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Gastos</h2>
						<span>nuevo gasto</span>
					</div>
					<div className="btns">
						<button
							className="btn"
							onClick={event => {
								event.preventDefault()
								this.guardarTodo()
							}}>
							Guardar
						</button>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total gasto</span>
							<strong>{Price(this.state.costo)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Restante por pagar</span>
							<strong>{Price(this.state.Deuda)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total pagado</span>
							<strong>{Price(this.state.Abonado)}</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input
								type="select"
								title="Tipo de gasto *"
								placeholder="Selecciona el tipo de gasto"
								name="name"
								value={this.state.type}
								change={val => {
									this.setState({ type: val })
								}}
								options={this.state.types}
							/>
							<Input
								type="select"
								title="Proveedor *"
								placeholder="Selecciona el proveedor"
								name="name"
								value={this.state.provider}
								change={val => this.setState({ provider: val })}
								options={this.state.providers}
							/>
							<Input
								type="number"
								title="Valor del gasto *"
								placeholder="$0"
								name="name"
								value={this.state.costo}
								change={val => {
									this.setState({ costo: val }, () => {
										this.calcularDeuda()
									})
								}}
							/>
						</div>
						<div className="column">
							<Input
								type="textarea"
								title="Descripción *"
								placeholder=""
								name="name"
								value={this.state.description}
								change={val => this.setState({ description: val })}
							/>
						</div>
					</div>
					<hr />
					<br />

					<div className="productos">
						<div className="columns">
							<div className="column">
								<h3>Salida de dinero</h3>
							</div>
							<div className="column" style={{ textAlign: 'right' }}>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.setState({ modalCajas: true })
									}}
									style={{ display: 'inline-block' }}
									className="btn">
									Seleccionar caja
								</a>
							</div>
						</div>
						<div className="divider"></div>
						<table className="table">
							<thead>
								<tr>
									<th>Caja</th>
									<th>Metodo</th>
									<th>Abono</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.cajasAMostrar.map((caja, index) => (
									<tr key={caja.uuid}>
										<td>{caja.name}</td>
										<td>{caja.abono.metodo.name}</td>
										<td>{Price(caja.abono.amount)}</td>
										<td>
											<a
												href="#"
												onClick={event => {
													event.preventDefault()
													this.deleteAbono(index)
												}}
												className="btn-edit">
												Eliminar
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import Table from 'componentes/Table'
import { setLoading } from 'store'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const usersTable = new DB('users')
const cavasTable = new DB('cavas')

class Users extends Component {
	constructor(props) {
		super(props)
		this.state = {
			users: [],
			cavas: []
		}
	}

	componentDidMount() {
		this.getUsers()
	}

	async getUsers() {
		try {
			setLoading(true)
			const resultCavas = await cavasTable.getAll()
			const result = await usersTable.getAll()
			if (result.status === false || resultCavas.status === false) {
				console.error(result)
				Alert('¡Ooops! tenemos problemas de conexión', 'danger')
				setLoading(false)
			}
			const cavas = []
			for (const cava of resultCavas.data?.Items) {
				cavas[cava.uuid] = cava.name
			}
			this.setState({
				cavas,
				users: result.data?.Items
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops! tenemos problemas de conexión', 'danger')
		}
	}

	render() {
		const columns = [
			{ header: 'Nombre', accessorKey: 'name' },
			{ header: 'Cava', accessorKey: 'cava', cell: ({ row }) => this.state.cavas[row.original.cava] },
			{ header: 'Rol', accessorKey: 'rol' },
			{ header: 'Correo', accessorKey: 'email' },
			{ header: 'Estado', accessorKey: 'status', cell: ({ row }) => <span className={`status ${row.original.status}`}>{row.original.status}</span> },
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.props.navigate('/admin/users/edit/' + row.original.email)
						}}
						className="btn-edit">
						Editar
					</a>
				)
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Usuarios</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/admin/users/create" className="btn">
							Nuevo usuario
						</NavLink>
					</div>
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.users} pageSize={20} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Users)

import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import AnimateScreen from 'componentes/AnimateScreen'
import Table from 'componentes/Table'
import DB from 'utiles/db'
import { setLoading } from 'store'
import { Alert } from 'utiles/functions'
const waysTable = new DB('ways')
const usersTable = new DB('users')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: []
		}
	}

	componentDidMount() {
		this.init()
	}

	init = async () => {
		try {
			setLoading(true)
			const uuid = this.props.cava.uuid
			const users = (await usersTable.query('#cava = :c AND #rol = :rol', { ':c': uuid, ':rol': 'Cobrador' }, { '#cava': 'cava', '#rol': 'rol' }))
				.data.Items
			const ways = (await waysTable.query('#cava = :c', { ':c': uuid }, { '#cava': 'cava' })).data.Items
			for (const way of ways) {
				const cobrador = users.find(user => user.email === way.cobrador)
				way.cobrador = cobrador ? cobrador.name : 'Sin cobrador'
			}
			this.setState({ data: ways })
			setLoading(false)
		} catch (error) {
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
			console.error(error)
		}
	}

	render() {
		const columns = [
			{ header: 'Ruta', accessorKey: 'name' },
			{ header: 'Día', accessorKey: 'day' },
			{ header: 'Cobrador', accessorKey: 'cobrador' },
			{
				header: 'Estado',
				accessorKey: 'status',
				cell: props => (
					<span className={`status ${props.row.original.status ? 'Activo' : 'Inactivo'}`}> {props.row.original.status ? 'Activa' : 'Inactiva'} </span>
				)
			},
			{
				header: 'Sincronizada',
				accessorKey: 'sync',
				cell: props => (
					<span className={`status ${props.row.original.sync === 'Sincronizada' ? 'Activo' : 'Inactivo'}`}>
						{' '}
						{props.row.original.sync === 'Sincronizada' ? 'Si' : 'No'}{' '}
					</span>
				)
			},
			{
				header: 'Acciones',
				cell: props => {
					const way = props.row.original
					return (
						<>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.props.navigate('/dashboard/ways/edit/' + way.uuid)
								}}
								style={{ marginRight: 7 }}
								className="btn-edit">
								Editar
							</a>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									console.log('Ir a vista de historico de cierres de ruta')
								}}
								style={{ marginRight: 7 }}
								className="btn-edit">
								Ver historico
							</a>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									console.log('Funcion de eliminar ruta')
								}}
								className="btn-delete">
								Eliminar ruta
							</a>
						</>
					)
				}
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Rutas</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/typespentsinways" className="btn">
							Tipos de gastos en ruta
						</NavLink>
						<NavLink to="/dashboard/ways/create" className="btn">
							Nueva ruta
						</NavLink>
					</div>
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.data} pageSize={40} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import DB from 'utiles/db'
import { Price, hoy, Alert } from 'utiles/functions'
import { setLoading } from 'store'
import Table from 'componentes/Table'
const paymentmethodsTable = new DB('paymentmethods')
const cashsTable = new DB('cashs')
const movBoxesTable = new DB('movBoxes')

class CashMovs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			movs: [],
			boxes: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			setLoading(true)
			const resultPaymentMethods = await paymentmethodsTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const resultCashs = await cashsTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const resultMovBoxes = await movBoxesTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			if (resultPaymentMethods.status === false || resultCashs.status === false || resultMovBoxes.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const boxes = []
			for (const box of resultCashs.data.Items) {
				box.total = 0
				for (const method of resultPaymentMethods.data.Items) {
					if (!box[method.uuid]) {
						continue
					}
					box.total += Number(box[method.uuid].amount)
				}
				boxes.push(box)
			}
			const movs = []
			for (const mov of resultMovBoxes.data.Items) {
				const box = boxes.find(box => box.uuid === mov.caja_uuid)
				mov.boxName = box?.name
				mov.methodName = box[mov.metodo_uuid]?.name
				movs.push(mov)
			}
			this.setState({
				movs,
				boxes
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		const columns = [
			{
				header: 'Fecha',
				accessorKey: 'time',
				cell: ({ row }) => hoy(row.original.time, true)
			},
			{
				header: 'Descripción',
				accessorKey: 'description'
			},
			{
				header: 'Caja',
				accessorKey: 'boxName'
			},
			{
				header: 'Método',
				accessorKey: 'methodName'
			},
			{
				header: 'Tipo',
				accessorKey: 'type',
				cell: ({ row }) =>
					row.original.type === 'egreso' ? (
						<img className="flechaegreso" src={require('img/direct-down.svg').default} width={18} height={18} />
					) : (
						<img className="flechaingreso" src={require('img/direct-up.svg').default} width={18} height={18} />
					)
			},
			{
				header: 'Valor',
				accessorKey: 'value',
				cell: ({ row }) => Price(row.original.value)
			},
			{
				header: 'Actions',
				accessorKey: 'uuid',
				cell: ({ row }) => (
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.props.navigate('/dashboard/cashMovements/edit/' + row.original.uuid)
						}}
						className="btn-edit">
						Ver
					</a>
				)
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Movimientos de caja</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/cashMovements/create" className="btn">
							Nuevo movimiento
						</NavLink>
					</div>
				</div>
				<div className="grid g5" style={{ marginBottom: 20 }}>
					{this.state.boxes.map((caja, i) => (
						<div className="card" key={i}>
							<div className="item-widget">
								<span>{caja.name}</span>
								<strong>{Price(caja.total)}</strong>
							</div>
						</div>
					))}
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.movs} pageSize={40} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(CashMovs)

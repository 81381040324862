import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const Mapa = withScriptjs(
	withGoogleMap(props => (
		<GoogleMap defaultZoom={14} center={props.center}>
			{props.markers && (
				<>
					{props.markers.map((m, i) => (
						<Marker key={i} onClick={() => props.click(m)} position={{ lat: parseFloat(Number(m.lat) || 0), lng: parseFloat(Number(m.log) || 0) }} />
					))}
				</>
			)}

			{props.marker && (
				<>
					{props.marker.map(m => (
						<Marker
							key={m.id}
							position={{ lat: parseFloat(Number(m.lat || 0)), lng: parseFloat(Number(m.log || 0)) }}
							draggable={true}
							onDragEnd={e => props.onMarkerDragEnd(e, m)}
						/>
					))}
				</>
			)}
		</GoogleMap>
	))
)

export default Mapa

import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom'

// screens
import Login from 'screens/login'

// 			ADMIN
// ------------------------
import Admin from 'screens/admin'
import HomeAdmin from 'screens/inicioadmin'
// cavas
import Cavas from 'screens/cavas/index'
import CavasCreate from 'screens/cavas/create'
import CavasEdit from 'screens/cavas/edit'
// users
import Users from 'screens/users/index'
import UsersCreate from 'screens/users/create'
import UsersEdit from 'screens/users/edit'

// 			DASHBOARD
// ------------------------
import Dashboard from 'screens/dashboard'
// inicio
import Home from 'screens/home/index'
import Syncway from 'screens/home/syncway'

import Profile from 'screens/profile'

// productos
import CashMovements from 'screens/cashMovements/index'
import CashMovementsCreate from 'screens/cashMovements/create'
import CashMovementsEdit from 'screens/cashMovements/edit'

// movimientos de caja
import Products from 'screens/products/index'
import ProductsCreate from 'screens/products/create'
import ProductsEdit from 'screens/products/edit'

// inventario
import InventoryTweaks from 'screens/inventory_tweaks/index'
import InventoryTweaksCreate from 'screens/inventory_tweaks/create'
import InventoryTweaksShow from 'screens/inventory_tweaks/show'

// categorias
import Categoria from 'screens/categories/index'

// despacho de cobradores
import Dispatchthecollectors from 'screens/dispatchthecollectors/index'
import DispatchthecollectorsCreate from 'screens/dispatchthecollectors/create'
import DispatchthecollectorsEdit from 'screens/dispatchthecollectors/edit'
import DispatchthecollectorsShow from 'screens/dispatchthecollectors/show'

// levels
import Levels from 'screens/levels/index'

// Proveedores
import Proveedores from 'screens/providers/index'
import ProveedoresCreate from 'screens/providers/create'
import ProveedoresEdit from 'screens/providers/edit'

// usuarios
import UsersCava from 'screens/userscava/index'
import UsersCavaCreate from 'screens/userscava/create'
import UsersCavaEdit from 'screens/userscava/edit'

// clientes
import Clients from 'screens/clients/index'
import ClientsEdit from 'screens/clients/edit'
import ClientsMap from 'screens/clients/map'
import HistoryMovs from 'screens/clients/history'

// rutas
import Rutas from 'screens/ways/index'
import RutasCreate from 'screens/ways/create'
import RutasEdit from 'screens/ways/edit'
import Mapa from 'screens/ways/map'

// Notificaciones
import Notifications from 'screens/notifications'

// settings cava
import SettingsCava from 'screens/settingscava'

// métodos de pago
import PaymentMethods from 'screens/paymentmethods/index'

// cajas
import Cashs from 'screens/cashs/index'

// campañas
import Campains from 'screens/campains/index'
import CampainsCreate from 'screens/campains/create'
import CampainsEdit from 'screens/campains/edit'

// gastos
import Spents from 'screens/spents/index'
import SpentsCreate from 'screens/spents/create'
import SpentsEdit from 'screens/spents/edit'

// prestamos
import Loans from 'screens/loans/index'
import LoansCreate from 'screens/loans/create'
import LoansEdit from 'screens/loans/edit'

// tipos de gastos
import TypeSpents from 'screens/typespents/index'

// compras de materia prima
import RawMaterials from 'screens/rawmaterials/index'
import RawMaterialsCreate from 'screens/rawmaterials/create'
import RawMaterialsEdit from 'screens/rawmaterials/edit'

// Nómina
import Payroll from 'screens/payroll/index'

// tipos de gastos en rutas
import TypeSpentsInWays from 'screens/typespentsinways/index'

// Catalogo
import Catalogo from 'screens/catalogo/index'

// App movil
import AppMobile from 'screens/appmobile'

export default function AnimatedRoutes() {
	const location = useLocation()
	const navigate = useNavigate()
	const [user, setUser] = useState(null)
	const [cava, setCava] = useState(null)
	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path="/" element={<Login setUser={setUser} setCava={setCava} navigate={navigate} />} />
				<Route path="/catalogo/:uuid_cava" element={<Catalogo />} />
				<Route path="/app/:uuid" element={<AppMobile />} />
				{/* Dashboard de superadministrador */}
				<Route path="/admin" element={<Admin user={user} setUser={setUser} navigate={navigate} />}>
					<Route path="inicio" element={<HomeAdmin setCava={setCava} navigate={navigate} />} />
					<Route path="cavas" element={<Cavas user={user} navigate={navigate} />} />
					<Route path="cavas/create" element={<CavasCreate user={user} navigate={navigate} />} />
					<Route path="cavas/edit/:uuid" element={<CavasEdit user={user} navigate={navigate} />} />
					<Route path="users" element={<Users user={user} navigate={navigate} />} />
					<Route path="users/create" element={<UsersCreate user={user} navigate={navigate} />} />
					<Route path="users/edit/:email" element={<UsersEdit user={user} navigate={navigate} />} />
				</Route>

				<Route path="/dashboard" element={<Dashboard cava={cava} user={user} setUser={setUser} setCava={setCava} navigate={navigate} />}>
					<Route path="inicio" element={<Home user={user} cava={cava} navigate={navigate} />} />
					<Route path="waysync/:uuid" element={<Syncway user={user} cava={cava} navigate={navigate} />} />

					<Route path="products" element={<Products cava={cava} navigate={navigate} />} />
					<Route path="products/create" element={<ProductsCreate cava={cava} navigate={navigate} />} />
					<Route path="products/edit/:uuid" element={<ProductsEdit cava={cava} navigate={navigate} />} />
					<Route path="products/categories" element={<Categoria cava={cava} navigate={navigate} />} />

					<Route path="inventory_tweaks" element={<InventoryTweaks cava={cava} user={user} navigate={navigate} />} />
					<Route path="inventory_tweaks/create" element={<InventoryTweaksCreate cava={cava} user={user} navigate={navigate} />} />
					<Route path="inventory_tweaks/show/:uuid" element={<InventoryTweaksShow cava={cava} user={user} navigate={navigate} />} />

					<Route path="dispatchthecollectors" element={<Dispatchthecollectors cava={cava} user={user} navigate={navigate} />} />
					<Route path="dispatchthecollectors/create" element={<DispatchthecollectorsCreate user={user} cava={cava} navigate={navigate} />} />
					<Route path="dispatchthecollectors/edit/:uuid" element={<DispatchthecollectorsEdit cava={cava} navigate={navigate} />} />
					<Route path="dispatchthecollectors/show/:uuid" element={<DispatchthecollectorsShow cava={cava} navigate={navigate} />} />

					<Route path="providers" element={<Proveedores cava={cava} navigate={navigate} />} />
					<Route path="providers/create" element={<ProveedoresCreate cava={cava} navigate={navigate} />} />
					<Route path="providers/edit/:uuid" element={<ProveedoresEdit cava={cava} navigate={navigate} />} />

					<Route path="users" element={<UsersCava cava={cava} user={user} navigate={navigate} />} />
					<Route path="users/create" element={<UsersCavaCreate cava={cava} setCava={setCava} user={user} navigate={navigate} />} />
					<Route path="users/edit/:email" element={<UsersCavaEdit cava={cava} setCava={setCava} user={user} navigate={navigate} />} />

					<Route path="ways" element={<Rutas cava={cava} user={user} navigate={navigate} />} />
					<Route path="ways/create" element={<RutasCreate cava={cava} setCava={setCava} user={user} navigate={navigate} />} />
					<Route path="ways/edit/:uuid" element={<RutasEdit cava={cava} user={user} navigate={navigate} />} />
					<Route path="ways/map/:uuid" element={<Mapa cava={cava} user={user} navigate={navigate} />} />

					<Route path="typespentsinways" element={<TypeSpentsInWays cava={cava} navigate={navigate} />} />

					<Route path="profile" element={<Profile user={user} setUser={setUser} navigate={navigate} />} />

					<Route path="levels" element={<Levels cava={cava} navigate={navigate} />} />

					<Route path="notifications" element={<Notifications cava={cava} navigate={navigate} />} />

					<Route path="settings" element={<SettingsCava cava={cava} setCava={setCava} navigate={navigate} />} />
					<Route path="settings/paymentmethods" element={<PaymentMethods cava={cava} setCava={setCava} navigate={navigate} />} />
					<Route path="settings/cashs" element={<Cashs cava={cava} setCava={setCava} navigate={navigate} />} />

					<Route path="clients" element={<Clients cava={cava} user={user} navigate={navigate} />} />
					<Route path="clients/edit/:phone" element={<ClientsEdit cava={cava} navigate={navigate} />} />
					<Route path="clients/map/:phone" element={<ClientsMap cava={cava} navigate={navigate} />} />
					<Route path="clients/history/:phone/:uuid_way" element={<HistoryMovs cava={cava} user={user} navigate={navigate} />} />

					<Route path="campains" element={<Campains cava={cava} navigate={navigate} />} />

					<Route path="campains/create" element={<CampainsCreate user={user} cava={cava} navigate={navigate} />} />
					<Route path="campains/edit/:uuid" element={<CampainsEdit user={user} cava={cava} navigate={navigate} />} />

					<Route path="loans" element={<Loans cava={cava} user={user} navigate={navigate} />} />
					<Route path="loans/create" element={<LoansCreate user={user} cava={cava} navigate={navigate} />} />
					<Route path="loans/edit/:uuid" element={<LoansEdit cava={cava} navigate={navigate} />} />

					<Route path="spents" element={<Spents cava={cava} navigate={navigate} />} />
					<Route path="spents/create" element={<SpentsCreate user={user} cava={cava} navigate={navigate} />} />
					<Route path="spents/edit/:uuid" element={<SpentsEdit cava={cava} navigate={navigate} />} />

					<Route path="typespents" element={<TypeSpents cava={cava} navigate={navigate} />} />

					<Route path="rawmaterials" element={<RawMaterials cava={cava} navigate={navigate} />} />
					<Route path="rawmaterials/create" element={<RawMaterialsCreate cava={cava} user={user} navigate={navigate} />} />
					<Route path="rawmaterials/edit/:uuid" element={<RawMaterialsEdit user={user} cava={cava} navigate={navigate} />} />

					<Route path="cashMovements" element={<CashMovements cava={cava} navigate={navigate} />} />
					<Route path="cashMovements/create" element={<CashMovementsCreate cava={cava} user={user} navigate={navigate} />} />
					<Route path="cashMovements/edit/:uuid" element={<CashMovementsEdit user={user} cava={cava} navigate={navigate} />} />

					<Route path="payroll" element={<Payroll cava={cava} navigate={navigate} />} />
				</Route>
			</Routes>
		</AnimatePresence>
	)
}

import { Component } from 'react'
import Loader from 'componentes/loader'
import { v4 as uuidv4 } from 'uuid'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import Back from 'componentes/back'
import DB from 'utiles/db'
const ways = new DB('ways')
const cavas = new DB('cavas')
const users = new DB('users')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			cobradores: [],
			status: true
		}
	}

	componentDidMount() {
		users
			.query(
				'#cava = :c AND #rol = :rol AND #status = :status',
				{ ':c': this.props.cava.uuid, ':rol': 'Cobrador', ':status': 'Activo' },
				{ '#cava': 'cava', '#rol': 'rol', '#status': 'status' }
			)
			.then(data => {
				const cobradores = data.data.Items.map(co => {
					return { val: co.email, text: co.name }
				})
				this.setState({ cobradores })
			})
			.catch(error => {
				console.error(error)
			})
	}

	handleSubmit = async event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('day') !== '' && data.get('cobrador') !== '') {
			if (data.get('cobrador') !== null) {
				this.setState({ loading: true })
				try {
					const r = await ways.create({
						uuid: uuidv4(),
						cava: this.props.cava.uuid,
						name: data.get('name'),
						day: data.get('day'),
						cobrador: data.get('cobrador'),
						status: this.state.status
					})

					await cavas.newUpdate(
						{
							count_ways: Number(this.props.cava.count_ways || 0) + 1
						},
						{
							uuid: this.props.cava.uuid
						}
					)

					const cava = this.props.cava
					cava.count_ways = Number(this.props.cava.count_ways || 0) + 1
					this.props.setCava(cava)

					if (r.status) {
						this.setState({ loading: false }, () => {
							Alert('Ruta creada correctamente.')
							this.props.navigate('/dashboard/ways')
						})
					} else {
						this.setState({ loading: false })
						Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo', 'danger')
					}
				} catch (err) {
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo', 'danger')
				}
			} else {
				Alert('Debes crear primero cobradores para poder asignarlos a la ruta', 'danger', 7000)
			}
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Rutas</h2>
						<span>Nueva ruta</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre de la ruta *" placeholder="Ingresa el nombre" name="name" />
							<Input type="select" name="cobrador" title="Cobrador *" options={this.state.cobradores} />
						</div>
						<div className="column">
							<Input
								type="select"
								name="day"
								title="Dia de la ruta *"
								options={[
									{ val: 'Lunes', text: 'Lunes' },
									{ val: 'Martes', text: 'Martes' },
									{ val: 'Miércoles', text: 'Miercoles' },
									{ val: 'Jueves', text: 'Jueves' },
									{ val: 'Viernes', text: 'Viernes' },
									{ val: 'Sabado', text: 'Sabado' },
									{ val: 'Domingo', text: 'Domingo' }
								]}
							/>
							<Input
								type="checkbox"
								id="status"
								title="Estado de la ruta (Inactivo / Activo)"
								active={this.state.status}
								change={val => {
									this.setState({ status: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import DB from 'utiles/db'
import { Price, hoy, Alert } from 'utiles/functions'
import Table from 'componentes/Table'
import { setLoading } from 'store'
const spentsTable = new DB('spents')
const providersTable = new DB('providers')

class SpentsMaterials extends Component {
	constructor(props) {
		super(props)
		this.state = {
			spentMaterials: []
		}
	}

	componentDidMount() {
		this.getMaterials()
	}

	async getMaterials() {
		try {
			setLoading(true)
			const resultProviders = await providersTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const resultSpents = await spentsTable.query(
				'#cava = :c AND #rawmaterials = :raw',
				{ ':c': this.props.cava.uuid, ':raw': true },
				{ '#cava': 'cava', '#rawmaterials': 'rawmaterials' }
			)
			if (resultProviders.status === false || resultSpents.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			let total = 0
			let deuda = 0
			let abonado = 0
			const providers = {}
			for (const provider of resultProviders.data.Items) {
				providers[provider.uuid] = provider
			}
			const spentMaterials = []
			for (const compra of resultSpents.data.Items) {
				total += Number(compra.total)
				if (compra.abonado) {
					abonado += Number(compra.abonado)
					deuda += Number(compra.total) - Number(compra.abonado)
				} else {
					deuda += Number(compra.total)
				}
				compra.provider = providers[compra.provider]
				spentMaterials.push(compra)
			}
			this.setState({
				spentMaterials,
				totalCompra: total,
				totalAbonado: abonado,
				totalDeuda: deuda
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		const columns = [
			{ header: 'Proveedor', accessorKey: 'provider.name' },
			{ header: 'NIT', accessorKey: 'provider.doc' },
			{ header: 'Factura', accessorKey: 'nbill' },
			{ header: 'Cantidad de productos', accessorKey: 'products.length' },
			{ header: 'Valor total', accessorKey: 'total', cell: ({ row }) => Price(row.original.total) },
			{ header: 'Valor abonado', accessorKey: 'abonado', cell: ({ row }) => Price(row.original.abonado ? row.original.abonado : 0) },
			{
				header: 'Valor restante',
				accessorKey: 'abonado',
				cell: ({ row }) => Price(Number(row.original.total - Number(row.original.abonado ? row.original.abonado : 0)))
			},
			{
				header: 'Estado',
				accessorKey: 'abonado',
				cell: ({ row }) => (
					<span
						className={`status ${Number(row.original.total) - Number(row.original.abonado ? row.original.abonado : 0) === 0 ? 'Activo' : 'Desincronizado'}`}>
						{Number(row.original.total) - Number(row.original.abonado ? row.original.abonado : 0) === 0 ? 'Pagado' : 'Pendiente'}
					</span>
				)
			},
			{ header: 'Fecha', accessorKey: 'time', cell: ({ row }) => hoy(row.original.time) },
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<NavLink to={`/dashboard/rawmaterials/edit/${row.original.uuid}`} className="btn-edit">
						Editar
					</NavLink>
				)
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Compras de materia prima</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/rawmaterials/create" className="btn">
							Nueva compra
						</NavLink>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total compras</span>
							<strong>{Price(this.state.totalCompra)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total deudas</span>
							<strong>{Price(this.state.totalDeuda)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total abonado</span>
							<strong>{Price(this.state.totalAbonado)}</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<Table columns={columns} data={this.state.spentMaterials} pageSize={40} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(SpentsMaterials)

import { useSelector } from 'react-redux'
export default function Loader() {
	const loading = useSelector(state => state.globalFlags.loading)
	if (loading) {
		return (
			<div className="loader">
				<span className="loader-circle"></span>
				<p>Cargando</p>
			</div>
		)
	}
}

import { motion } from 'framer-motion'

const AnimateScreen = Componente => {
	const Animate = props => (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
			<Componente {...props} />
		</motion.div>
	)
	return Animate
}
export default AnimateScreen

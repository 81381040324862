import { NavLink } from 'react-router-dom'

export default function Menu(props) {
	return (
		<nav>
			<strong>MENU</strong>
			<ul>
				<li>
					<NavLink to="/admin/inicio">
						<img src={require('../img/home.svg').default} height={18} width={18} /> Inicio
					</NavLink>
				</li>
				<li>
					<NavLink to="/admin/cavas">
						<img src={require('../img/cavas.svg').default} width={18} height={18} /> Cavas
					</NavLink>
				</li>
				<li>
					<NavLink to="/admin/users">
						<img src={require('../img/users.svg').default} width={18} height={18} /> Usuarios
					</NavLink>
				</li>
			</ul>
			<a
				href="#"
				className="logout"
				onClick={event => {
					event.preventDefault()
					localStorage.setItem('@user_cava', '')
					props.setUser(null)
					props.navigate('/', { replace: true })
				}}>
				<img src={require('../img/arrow-left.svg').default} width={18} height={18} /> Cerrar sesión
			</a>
		</nav>
	)
}

'use client'
import { useState, useRef } from 'react'
import Switch from 'react-switch'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { motion } from 'framer-motion'
import e from 'utiles/settings.js'

export default function Input(props) {
	const [image, setImage] = useState(null)
	const [imageFinal, setImageFinal] = useState(null)
	const [scale, setScale] = useState(1)
	const [crop, setCrop] = useState({
		unit: 'px',
		x: 25,
		y: 25,
		width: 300,
		height: 300
	})
	const imgRef = useRef(null)
	const numberRef = useRef(null)
	switch (props.type) {
		case 'image':
			return (
				<div className="input-group-avater">
					<label>
						{imageFinal ? <img src={imageFinal} /> : <>{props.img ? <img src={e.urlBucket + props.img} /> : <p>{props.title}</p>}</>}
						<input
							type="file"
							accept="image/jpeg"
							name={props.name}
							id={props.id}
							onChange={event => {
								const reader = new FileReader()
								reader.onload = e => setImage(e.target.result)
								reader.readAsDataURL(event.target.files[0])
							}}
						/>
					</label>
					{image && (
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="modal-app">
							<motion.div
								initial={{ opacity: 0, scale: 0.8, y: 60 }}
								animate={{ opacity: 1, scale: 1, y: 0 }}
								exit={{ opacity: 0, scale: 0.8, y: 60 }}
								className="body-modal"
								style={{ position: 'relative', padding: 10 }}>
								<ReactCrop maxWidth={300} maxHeight={300} minWidth={300} minHeight={300} crop={crop} onChange={c => setCrop(c)}>
									<img
										src={image}
										ref={imgRef}
										style={{
											maxWidth: '70vw',
											minWidth: '40vw',
											minHeight: '60vh',
											maxHeight: '90vh',
											objectFit: 'contain',
											transform: `scale(${scale})`
										}}
									/>
								</ReactCrop>
								<div className="btns" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<a
										className="btn"
										href="#"
										onClick={event => {
											event.preventDefault()
											setImage(null)
											setCrop({
												unit: 'px',
												x: 25,
												y: 25,
												width: 300,
												height: 300
											})
										}}>
										Cancelar
									</a>
									<input
										type="range"
										className="slider"
										value={scale}
										name="volume"
										min="0.5"
										max="2"
										step="0.1"
										onChange={e => {
											setScale(e.target.value)
										}}
									/>
									<a
										className="btn"
										href="#"
										onClick={event => {
											event.preventDefault()
											const canvas = document.createElement('canvas')
											const ctx = canvas.getContext('2d')
											const image = imgRef.current
											let scale = image.style.transform.replace('scale(', '').replace(')', '')
											scale = Number(scale)
											const scaleX = image.naturalWidth / image.width
											const scaleY = image.naturalHeight / image.height

											const pixelRatio = window.devicePixelRatio

											canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
											canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

											ctx.scale(pixelRatio, pixelRatio)
											ctx.imageSmoothingQuality = 'high'

											const cropX = crop.x * scaleX
											const cropY = crop.y * scaleY

											const centerX = image.naturalWidth / 2
											const centerY = image.naturalHeight / 2

											ctx.save()
											ctx.translate(-cropX, -cropY)
											ctx.translate(centerX, centerY)
											ctx.scale(scale, scale)
											ctx.translate(-centerX, -centerY)
											ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)
											ctx.restore()
											canvas.toBlob(data => {
												const reader = new FileReader()
												reader.onload = e => {
													setImageFinal(e.target.result)
													setImage(null)
													setCrop({
														unit: 'px',
														x: 25,
														y: 25,
														width: 300,
														height: 300
													})
													if (props.change) {
														props.change(e.target.result)
													}
												}
												reader.readAsDataURL(data)
											})
										}}>
										Recortar
									</a>
								</div>
							</motion.div>
						</motion.div>
					)}
				</div>
			)
		case 'checkbox':
			return (
				<a
					href="#"
					onClick={event => {
						event.preventDefault()
						props.change(!props.active)
					}}
					className="input-group checkbox">
					<label>{props.title}</label>
					<Switch
						checked={props.active}
						onChange={props.change}
						onColor="#c922ff"
						onHandleColor="#fff"
						handleDiameter={24}
						uncheckedIcon={false}
						checkedIcon={false}
						boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
						activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
						height={22}
						width={48}
						id={props.id}
					/>
				</a>
			)
		case 'text':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<input
						type="text"
						disabled={props.disabled}
						onChange={event => {
							if (props.change) {
								props.change(event.target.value)
							}
						}}
						name={props.name}
						id={props.id}
						placeholder={props.placeholder}
						defaultValue={props.value}
						readOnly={props.readonly}
					/>
				</div>
			)
		case 'date':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<input
						type="date"
						disabled={props.disabled}
						onChange={event => {
							if (props.change) {
								props.change(event.target.value)
							}
						}}
						name={props.name}
						id={props.id}
						placeholder={props.placeholder}
						defaultValue={props.value}
						readOnly={props.readonly}
					/>
				</div>
			)
		case 'password':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<input
						type="password"
						disabled={props.disabled}
						onChange={event => {
							if (props.change) {
								props.change(event.target.value)
							}
						}}
						name={props.name}
						id={props.id}
						placeholder={props.placeholder}
						defaultValue={props.value}
					/>
				</div>
			)
		case 'email':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<input
						type="email"
						disabled={props.disabled}
						onChange={event => {
							if (props.change) {
								props.change(event.target.value)
							}
						}}
						name={props.name}
						id={props.id}
						placeholder={props.placeholder}
						defaultValue={props.value}
					/>
				</div>
			)
		case 'number':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<input
						type="text"
						disabled={props.disabled}
						onChange={event => {
							const userInput = event.target.value
							const parsedValue = parseFloat(userInput.replace(/[^0-9]/g, '')) || 0
							if (numberRef.current) {
								numberRef.current.value = String(parsedValue)
							}
							if (props.change) {
								props.change(parsedValue)
							}
						}}
						onKeyUp={event => {
							if (props.onEnter) {
								if (event.key === 'Enter') {
									props.onEnter(event.target.value)
								}
							}
						}}
						ref={numberRef}
						name={props.name}
						id={props.id}
						autoFocus={props.focus}
						placeholder={props.placeholder}
						defaultValue={props.value}
						value={props.value}
						readOnly={props.readonly}
					/>
				</div>
			)
		case 'textarea':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<textarea
						name={props.name}
						id={props.id}
						style={{ height: props.height ? props.height : 'auto' }}
						placeholder={props.placeholder}
						defaultValue={props.value}
						onChange={event => {
							if (props.change) {
								props.change(event.target.value)
							}
						}}
						readOnly={props.readonly}></textarea>
				</div>
			)
		case 'select':
			return (
				<div className="input-group">
					<label>{props.title}</label>
					<select
						name={props.name}
						value={props.value}
						id={props.id}
						placeholder={props.placeholder}
						onChange={event => {
							if (props.change) {
								props.change(event.target.value)
							}
						}}>
						{props.options.map((option, i) => (
							<option key={i} value={option.val}>
								{option.text}
							</option>
						))}
					</select>
				</div>
			)
		default:
			return <p>Input type not found </p>
	}
}

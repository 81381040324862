import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage } from 'utiles/functions'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db'
const categorias = new DB('categories')
const productos = new DB('products')
let cates = []

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status_catalogo: true,
			status: true,
			image: null,
			cates: []
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				cates = data.data.Items.map(cat => {
					return { val: cat.uuid, text: cat.name }
				})
				cates.unshift({ val: '', text: 'Selecciona un proveedor' })
				const categorias = data.data.Items.map(type => {
					return { val: type.uuid, text: type.name }
				})
				categorias.unshift({ val: '', text: 'Selecciona un proveedor' })
				this.setState({ cates: categorias })
			})
			.catch(error => {
				console.error(error)
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const producto = {
			uuid: uuidv4(),
			cava: this.props.cava.uuid,
			name: data.get('name'),
			price_cost: Number(data.get('price_cost')),
			price_operation: Number(data.get('price_operation')),
			price_contado: Number(data.get('price_contado')),
			price_credito: Number(data.get('price_credito')),
			stock: Number(data.get('stock')),
			umbral: Number(data.get('umbral')),
			category: data.get('category'),
			description: data.get('description'),
			status: this.state.status,
			status_catalogo: this.state.status_catalogo
		}
		if (
			producto.name !== '' &&
			producto.price_cost !== '' &&
			producto.price_operation !== '' &&
			producto.price_contado !== '' &&
			producto.price_credito !== '' &&
			producto.stock !== '' &&
			producto.category !== ''
		) {
			this.setState({ loading: true }, async () => {
				if (this.state.image !== null) {
					const url = await uploadImage(this.state.image)
					producto.image = url
				}
				const r = await productos.create(producto)
				if (r.status) {
					this.setState({ loading: false })
					this.props.navigate('dashboard/products')
					Alert('Producto registrado correctamente.')
				} else {
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos un problemas para crear el producto, intenta de nuevo', 'danger')
				}
			})
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/dashboard/products" className="back">
							<img src={require('../../img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Productos</h2>
						<span>Nuevo producto</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre del producto *" placeholder="Ingresa el nombre del producto" name="name" />
							<Input type="number" title="Precio costo *" placeholder="Ingresa el precio costo del producto" name="price_cost" />
							<Input type="number" title="Precio de operación *" placeholder="Ingresa el precio de operación" name="price_operation" />
							<Input type="number" title="Precio de contado *" placeholder="Ingresa el precio de contado" name="price_contado" />
							<Input type="number" title="Precio a crédito *" placeholder="Ingresa el precio a crédito" name="price_credito" />
							<Input type="number" title="Cantidad en inventario *" placeholder="Ingresa la cantidad de inventario" name="stock" />
						</div>
						<div className="column">
							<Input type="image" name="image" title="Imagen del producto" change={data => this.setState({ image: data })} />
							<Input type="number" title="Umbral de pocas existencias" placeholder="Ingresa el umbral de pocas existencias" name="umbral" />
							<Input type="select" name="category" title="Categoria del producto *" options={this.state.cates} />
							<Input type="textarea" title="Descripción del producto" name="description" placeholder="Ingresa la ddescripción del producto" />
							<Input
								type="checkbox"
								id="status"
								title="Estado (Inactivo / Activo)"
								active={this.state.status}
								change={val => {
									this.setState({ status: val })
								}}
							/>
							<Input
								type="checkbox"
								id="status_catalogo"
								title="Visible en el catálogo"
								active={this.state.status_catalogo}
								change={val => {
									this.setState({ status_catalogo: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}

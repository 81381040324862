import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen.jsx'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import Loader from 'componentes/loader'
import { Alert, uploadImage, deleteImage } from 'utiles/functions.js'
import DB from 'utiles/db.js'
const cavas = new DB('cavas')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			name: '',
			address: '',
			phone: '',
			phone2: '',
			catalogo: false,
			hide_amount: false,
			sms_cobra: false,
			can_avaluo: false,
			can_amount_customer: false,
			logo: null,
			old_logo: null
		}
	}

	componentDidMount() {
		cavas
			.get({ uuid: this.props.cava.uuid })
			.then(data => {
				this.setState({
					loading: false,
					name: data.data.Item.name,
					address: data.data.Item.address,
					phone: data.data.Item.phone,
					phone2: data.data.Item.phone2,
					catalogo: data.data.Item.catalogo,
					hide_amount: data.data.Item.hide_amount,
					can_avaluo: data.data.Item.can_avaluo,
					sms_cobra: data.data.Item.sms_cobra,
					can_amount_customer: data.data.Item.can_amount_customer,
					old_logo: data.data.Item.logo
				})
			})
			.catch(error => {
				console.error(error)
				this.setState({ loading: false }, () => {
					Alert('¡Ooops!, tenemos problemas de conexión, intentalo de nuevo', 'danger')
				})
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('address') !== '' && data.get('phone') !== '') {
			let expresion =
				'SET #n = :name, #a = :address, #p = :phone, #p2 = :phone2, #c = :catalogo, #h = :hide_amount, #sms_cobra = :sms_cobra, #can_avaluo = :can_avaluo, #can_amount_customer = :can_amount_customer'
			const values = {
				':name': data.get('name'),
				':address': data.get('address'),
				':phone': data.get('phone'),
				':phone2': data.get('phone2'),
				':catalogo': this.state.catalogo || false,
				':hide_amount': this.state.hide_amount || false,
				':sms_cobra': this.state.sms_cobra || false,
				':can_avaluo': this.state.can_avaluo || false,
				':can_amount_customer': this.state.can_amount_customer || false
			}
			const attributes = {
				'#n': 'name',
				'#a': 'address',
				'#p': 'phone',
				'#p2': 'phone2',
				'#c': 'catalogo',
				'#h': 'hide_amount',
				'#sms_cobra': 'sms_cobra',
				'#can_avaluo': 'can_avaluo',
				'#can_amount_customer': 'can_amount_customer'
			}
			this.setState({ loading: true }, async () => {
				const cava = this.props.cava
				if (this.state.logo !== null) {
					const url = await uploadImage(this.state.logo)
					attributes['#l'] = 'logo'
					values[':logo'] = url
					expresion += ', #l = :logo'
					if (this.state.old_logo !== null && this.state.old_logo !== undefined) {
						await deleteImage(this.state.old_logo)
					}
					cava.logo = url
				}
				await cavas.update({
					Key: { uuid: this.props.cava.uuid },
					UpdateExpression: expresion,
					ExpressionAttributeValues: values,
					ExpressionAttributeNames: attributes,
					ReturnValues: 'UPDATED_NEW'
				})
				this.props.setCava(cava)
				this.setState({ loading: false })
				Alert('Configuración actualizada correctamente.')
			})
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<h2>Cava</h2>
						<span>Configuración</span>
					</div>
					<div className="btns">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.props.navigate('/dashboard/settings/paymentmethods')
							}}
							className="btn">
							Métodos de pago
						</a>
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.props.navigate('/dashboard/settings/cashs')
							}}
							className="btn">
							Cajas
						</a>
						<a></a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre de la cava *" name="name" value={this.state.name} placeholder="Ingresa el nombre" />
							<Input type="textarea" title="Dirección *" name="address" value={this.state.address} placeholder="Ingresa la descripción" />
							<Input
								type="number"
								title="Celular de la cava *"
								name="phone"
								value={this.state.phone}
								change={val => this.setState({ phone: val })}
								placeholder="Ingresa el celular"
							/>
							<Input
								type="number"
								title="Celular 2 de la cava"
								name="phone2"
								value={this.state.phone2}
								change={val => this.setState({ phone2: val })}
								placeholder="Ingresa el celular"
							/>
						</div>
						<div className="column">
							<Input type="image" name="avatar" img={this.state.old_logo} title="Logo" change={data => this.setState({ logo: data })} />
							<div className="divider"></div>
							<Input
								type="checkbox"
								id="catalogo"
								title="Activar catálogo:"
								active={this.state.catalogo}
								change={val => {
									this.setState({ catalogo: val })
								}}
							/>
							<Input
								type="checkbox"
								id="hide_amount"
								title="Acumulado visible en app de cobradores:"
								active={this.state.hide_amount}
								change={val => {
									this.setState({ hide_amount: val })
								}}
							/>
							<Input
								type="checkbox"
								id="sms_cobra"
								title="Envio de SMS en app de cobradores:"
								active={this.state.sms_cobra}
								change={val => {
									this.setState({ sms_cobra: val })
								}}
							/>
							<Input
								type="checkbox"
								id="can_avaluo"
								title="Visualizar avaluo de los productos en app de cobradores:"
								active={this.state.can_avaluo}
								change={val => {
									this.setState({ can_avaluo: val })
								}}
							/>
							<Input
								type="checkbox"
								id="can_amount_customer"
								title="Permitir registro de estado de cuenta del cliente:"
								active={this.state.can_amount_customer}
								change={val => {
									this.setState({ can_amount_customer: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					{this.state.catalogo && (
						<a
							href="#"
							onClick={e => {
								e.preventDefault()
								this.props.navigate('/catalogo/' + this.props.cava.uuid)
							}}>
							Ver calatogo
						</a>
					)}
					<button className="btn m-auto" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(AnimateScreen(Page))

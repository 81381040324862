import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import withParams from 'componentes/WithParams'
import Modal from 'componentes/modal'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import Back from 'componentes/back'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db'
const paymentmethods = new DB('paymentmethods')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalCreate: false,
			methodEdit: {},
			default: false,
			data: [],
			defectoEfectivo: false,
			defectoTransferencia: false
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ loading: false, data: data.data.Items })
				console.log(data)
			})
			.catch(error => {
				console.error(error)
			})
	}

	registerMethod = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '') {
			this.setState({ modalCreate: false, loading: true }, async () => {
				const result = await paymentmethods.create({
					uuid: uuidv4(),
					cava: this.props.cava.uuid,
					name: data.get('name'),
					description: data.get('description'),
					defaultTransferencia: this.state.defectoTransferencia,
					defaultEfectivo: this.state.defectoEfectivo
				})
				if (result.status) {
					this.setState({ loading: false, name_store: '', default: false }, () => {
						this.init()
						Alert('Método de pago creado correctamente.')
					})
				} else {
					this.setState({ loading: false, name_store: '' })
				}
			})
		} else {
			Alert('Ingresa el nombre del método de pago.', 'danger', 7000)
		}
	}

	updateMethod = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '') {
			const method = this.state.methodEdit
			this.setState({ loading: true, methodEdit: {} }, async () => {
				console.log(method)
				if (method.defaultTransferencia === true || method.defaultEfectivo === true) {
					console.log('adentro')
					for (const metho of this.state.data) {
						await paymentmethods.update({
							Key: { uuid: metho.uuid },
							UpdateExpression: 'SET #n = :name, #dt = :defaultTransferencia, #de = :defaultEfectivo',
							ExpressionAttributeValues: {
								':name': metho.name,
								':defaultTransferencia':
									method.defaultTransferencia === true ? false : metho.defaultTransferencia ? metho.defaultTransferencia : false,
								':defaultEfectivo': method.defaultEfectivo === true ? false : metho.defaultEfectivo ? metho.defaultEfectivo : false
							},
							ExpressionAttributeNames: {
								'#n': 'name',
								'#dt': 'defaultTransferencia',
								'#de': 'defaultEfectivo'
							},
							ReturnValues: 'UPDATED_NEW'
						})
					}
				}
				const updated = await paymentmethods.update({
					Key: { uuid: method.uuid },
					UpdateExpression: 'SET #n = :name, #d = :description, #dt = :defaultTransferencia, #de = :defaultEfectivo',
					ExpressionAttributeValues: {
						':name': data.get('name'),
						':description': data.get('description'),
						':defaultTransferencia': method.defaultTransferencia,
						':defaultEfectivo': method.defaultEfectivo
					},
					ExpressionAttributeNames: {
						'#n': 'name',
						'#d': 'description',
						'#dt': 'defaultTransferencia',
						'#de': 'defaultEfectivo'
					},
					ReturnValues: 'UPDATED_NEW'
				})

				if (updated.status) {
					this.setState({ loading: false, name_store: '' }, () => {
						this.init()
						Alert('Método de pago actualizado correctamente.')
					})
				} else {
					this.setState({ loading: false, name_store: '' })
				}
			})
		} else {
			Alert('Ingresa el nombre del método de pago.', 'danger', 7000)
		}
	}

	rederCreate() {
		return (
			<Modal active={this.state.modalCreate}>
				<form action="#" onSubmit={this.registerMethod}>
					<div className="title-page">
						<div className="title">
							<h2>Nuevo método de pago</h2>
						</div>
					</div>
					<Input type="text" title="Nombre del método *" placeholder="Ingresa el nombre" name="name" />
					<Input type="textarea" title="Descripció del método *" placeholder="Ingresa la descripción" name="description" />
					<Input
						type="checkbox"
						title="Método por defecto para pagos en efectivo *"
						name="default"
						active={this.state.defectoEfectivo}
						change={val => {
							this.setState({ defectoEfectivo: val, defectoTransferencia: false })
						}}
					/>
					<Input
						type="checkbox"
						title="Método por defecto para pagos en transferencias *"
						name="default"
						active={this.state.defectoTransferencia}
						change={val => {
							this.setState({ defectoTransferencia: val, defectoEfectivo: false })
						}}
					/>

					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalCreate: false, default: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	rederEdit() {
		return (
			<Modal active={'uuid' in this.state.methodEdit}>
				<form action="#" onSubmit={this.updateMethod}>
					<div className="title-page">
						<div className="title">
							<h2>Editar nivel</h2>
						</div>
					</div>

					<Input type="text" title="Nombre del método de pago *" placeholder="Ingresa el método" name="name" value={this.state.methodEdit.name} />
					<Input type="text" title="Descripción" placeholder="" name="description" value={this.state.methodEdit.description} />

					<Input
						type="checkbox"
						title="Método por defecto para pagos en efectivo *"
						name="default"
						active={this.state.methodEdit.defaultEfectivo}
						change={val => {
							const methodEdit = this.state.methodEdit
							methodEdit.defaultEfectivo = true
							methodEdit.defaultTransferencia = false
							this.setState({ methodEdit })
						}}
					/>
					<Input
						type="checkbox"
						title="Método por defecto para pagos en transferencias *"
						name="default"
						active={this.state.methodEdit.defaultTransferencia}
						change={val => {
							const methodEdit = this.state.methodEdit
							methodEdit.defaultEfectivo = false
							methodEdit.defaultTransferencia = true
							this.setState({ methodEdit })
						}}
					/>

					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ methodEdit: {}, default: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.rederCreate()}
				{this.rederEdit()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Configuración</h2>
						<span>Métodos de pago</span>
					</div>
					<div className="btns">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalCreate: true })
							}}
							className="btn">
							Nuevo método de pago
						</a>
					</div>
				</div>
				<div className="card">
					<table className="table">
						<thead>
							<tr>
								<th>Método</th>
								<th>Descripción</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(method => (
								<tr key={method.uuid}>
									<td>
										{method.name}
										{method.defaultTransferencia === true && (
											<span>
												{' '}
												- 🌟 <small>Transferencias</small>
											</span>
										)}
										{method.defaultEfectivo === true && (
											<span>
												{' '}
												- 🌟 <small>Efectivo</small>
											</span>
										)}
									</td>
									<td>{method.description}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.setState({ methodEdit: method })
											}}
											className="btn-edit">
											Editar
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(AnimateScreen(Page))

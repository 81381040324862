import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import withParams from 'componentes/WithParams'
import { NavLink } from 'react-router-dom'
import Table from 'componentes/Table'
import { setLoading } from 'store'
import { Price, Alert } from 'utiles/functions'
import DB from 'utiles/db'
const clientsTable = new DB('clients')
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			clients: []
		}
	}

	componentDidMount() {
		this.getClient()
	}

	async getClient() {
		try {
			setLoading(true)
			const result = await clientsTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			if (result.status === false) {
				console.error(result)
				Alert('¡Ooops! tenemos problemas de conexión', 'danger')
				setLoading(false)
			}
			this.setState({
				clients: result.data.Items
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops! tenemos problemas de conexión', 'danger')
		}
	}

	render() {
		const columns = [
			{ header: 'Cliente', accessorKey: 'name' },
			{ header: 'Teléfono', accessorKey: 'phone' },
			{ header: 'Dirección', accessorKey: 'address' },
			{ header: 'Nivel', accessorKey: 'level.name' },
			{ header: 'Tope', accessorKey: 'level.amount', cell: ({ row }) => Price(row.original.level.amount) },
			{
				header: 'Estado',
				accessorKey: 'status',
				cell: ({ row }) => (
					<span className={`status ${row.original.status ? 'Activo' : 'Inactivo'}`}>{row.original.status ? 'Activo' : 'Inactivo'}</span>
				)
			},
			{ header: 'N° Rutas', accessorKey: 'ways.length' },
			{
				header: 'Último movimiento',
				accessorKey: 'last_mov.amount',
				cell: ({ row }) => (row.original.last_mov.type === 'debe' ? '-' + Price(row.original.last_mov.amount) : Price(row.original.last_mov.amount))
			},
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.props.navigate('/dashboard/clients/edit/' + row.original.phone)
						}}
						className="btn-edit">
						Editar
					</a>
				)
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Clientes</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						{this.props.user.rol !== 'Supervisor' && (
							<>
								<NavLink to="/dashboard/campains" className="btn">
									Campañas SMS
								</NavLink>
								<NavLink to="/dashboard/levels" className="btn">
									Niveles
								</NavLink>
							</>
						)}
					</div>
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.clients} pageSize={20} />
				</div>
			</>
		)
	}
}
export default withParams(AnimateScreen(Page))

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen.jsx'
import DB from 'utiles/db'
import { setLoading } from 'store'
const cavasTable = new DB('cavas')
class InicioAdmin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cavas: []
		}
	}

	async componentDidMount() {
		try {
			setLoading(true)
			const cavas = (await cavasTable.getAll()).data.Items
			this.setState({ cavas })
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.error(error)
		}
	}

	render() {
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Inicio</h2>
						<span>Cavas en operación</span>
					</div>
				</div>
				<div className="grid">
					{this.state.cavas.map(cava => (
						<div className="card" key={cava.uuid}>
							<div className="title-page" style={{ paddingBottom: 0 }}>
								<h2>{cava.name}</h2>
							</div>
							<div className="divider"></div>
							<div className="inline-item">
								<strong>Rutas: </strong>
								<span>{cava.count_ways || 0}</span>
							</div>
							<div className="inline-item">
								<strong>Cobradores: </strong>
								<span>{cava.count_cobradores || 0}</span>
							</div>
							<div className="inline-item">
								<strong>Clientes: </strong>
								<span>{cava.count_clientes || 0}</span>
							</div>
							<div className="inline-item">
								<strong>Monto Cobradores: </strong>
								<span className={`status ${cava.hide_amount ? 'Activo' : 'Inactivo'}`}>{cava.hide_amount ? 'Activo' : 'Inactivo'}</span>
							</div>
							<div className="inline-item">
								<strong>Envio de SMS en App: </strong>
								<span className={`status ${cava.sms_cobra ? 'Activo' : 'Inactivo'}`}>{cava.sms_cobra ? 'Activo' : 'Inactivo'}</span>
							</div>
							<div className="inline-item">
								<strong>Avaluo despacho en app: </strong>
								<span className={`status ${cava.can_avaluo ? 'Activo' : 'Inactivo'}`}>{cava.can_avaluo ? 'Activo' : 'Inactivo'}</span>
							</div>
							<div className="inline-item">
								<strong>Catalogo en linea: </strong>
								<span className={`status ${cava.catalogo ? 'Activo' : 'Inactivo'}`}>{cava.catalogo ? 'Activo' : 'Inactivo'}</span>
							</div>
							<div className="divider"></div>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.props.setCava(cava) // TODO: Cambiar a redux
									this.props.navigate('/dashboard/inicio', { replace: true })
								}}
								className="btn m-left">
								Gestionar
							</a>
						</div>
					))}
				</div>
			</>
		)
	}
}
export default AnimateScreen(InicioAdmin)

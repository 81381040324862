import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import Table from 'componentes/Table'
import { setLoading } from 'store'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const providersTable = new DB('providers')
class Providers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			providers: []
		}
	}

	componentDidMount() {
		this.getProviders()
	}

	async getProviders() {
		try {
			setLoading(true)
			const resultProviders = await providersTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			if (resultProviders.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			this.setState({ providers: resultProviders.data.Items })
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		const columns = [
			{ header: 'Proveedor', accessorKey: 'name' },
			{ header: 'NIT o Cédula', accessorKey: 'doc' },
			{ header: 'Teléfono', accessorKey: 'phone' },
			{
				header: 'Estado',
				accessorKey: 'status',
				cell: ({ row }) => (
					<span className={`status ${row.original.status ? 'Activo' : 'Inactivo'}`}> {row.original.status ? 'Activo' : 'Inactivo'} </span>
				)
			},
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<NavLink to={`/dashboard/providers/edit/${row.original.uuid}`} className="btn-edit">
						Editar
					</NavLink>
				)
			}
		]

		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Proveedores</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/providers/create" className="btn">
							Nuevo proveedor
						</NavLink>
					</div>
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.providers} pageSize={40} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Providers)

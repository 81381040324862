import { BrowserRouter as Router } from 'react-router-dom'
import AnimatedRoutes from './rutas'
import Loader from 'componentes/loader'
function App() {
	return (
		<div className="App">
			<Loader />
			<Router>
				<AnimatedRoutes />
			</Router>
		</div>
	)
}

export default App

import { Component } from 'react'
import Input from 'componentes/input.jsx'
import Loader from 'componentes/loader.jsx'
import Modal from 'componentes/modal.jsx'
import Back from 'componentes/back.jsx'
import { Alert, sendSMS } from 'utiles/functions.js'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db.js'
const camps = {
	phone: 'Telefono de usuario',
	name: 'Nombre de usuario',
	address: 'Dirección de usuario',
	level: 'Nivel de usuario',
	mora: 'Deuda de usuario'
}
const conditions = {
	'=': 'Igual ( = )',
	'>': 'Mayor ( > )',
	'<': 'Menor ( < )',
	'!=': 'Diferente ( <> )',
	contains: 'Contenga'
}
const types = {
	AND: 'Y',
	OR: 'O'
}
const clients = new DB('clients')
const ways = new DB('ways')
const campains = new DB('campains')
export default class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sms: '',
			loading: false,
			modalContidions: false,
			conditions: [],
			modalTypeconditions: false,
			ways: [],
			clientsSelected: [],
			modalCheckUsers: false,
			priceSMS: 0.0015
		}
	}

	componentDidMount() {
		this.getWays()
	}

	getWays() {
		ways
			.query('#cava = :c AND #status = :status', { ':c': this.props.cava.uuid, ':status': true }, { '#cava': 'cava', '#status': 'status' })
			.then(data => {
				if (data.status) {
					const ways = [{ val: '', text: 'Seleccionar...' }]
					for (const way of data.data.Items) {
						ways.push({
							val: way.uuid,
							text: way.name + ' - ' + way.day
						})
					}
					this.setState({ ways })
				}
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const campain = {
			uuid: uuidv4(),
			sms: data.get('sms'),
			way: data.get('way'),
			conditions: this.state.conditions,
			cava: this.props.cava.uuid,
			user: {
				email: this.props.user.email,
				name: this.props.user.name
			}
		}
		if (campain.sms !== '') {
			if (campain.sms.length < 100) {
				let expresion = '#cava = :c'
				const values = { ':c': this.props.cava.uuid }
				const attributes = { '#cava': 'cava' }
				if (campain.conditions.length > 0) {
					expresion += ` AND `
					for (const expre of campain.conditions) {
						if (expre.type === 'seperator') {
							expresion += ` ${expre.separator} `
						}
						if (expre.type === 'condition') {
							if (expre.condition === 'contains') {
								expresion += ` ${expre.condition}(#${expre.camp}, :${expre.value.replaceAll(' ', '_').toLowerCase()})`
							} else {
								expresion += ` #${expre.camp} ${expre.condition} :${expre.value.replaceAll(' ', '_').toLowerCase()}`
							}
							values[`:${expre.value.replaceAll(' ', '_').toLowerCase()}`] = expre.value
							attributes[`#${expre.camp}`] = expre.camp
						}
					}
				}
				expresion = expresion.replaceAll('  ', ' ')
				this.setState({ loading: true }, () => {
					clients
						.query(expresion, values, attributes)
						.then(data => {
							if (data.status) {
								if (data.data.Items.length > 0) {
									let clients = data.data.Items
									if (campain.way !== '') {
										clients = clients.filter(c => c.ways.filter(w => w.uuid === campain.way).length > 0)
									}
									if (clients.length > 0) {
										campain.countClients = clients.length
										this.setState({
											clientsSelected: clients,
											modalCheckUsers: true,
											loading: false,
											campain
										})
									} else {
										Alert('No se encontrarón clientes.', 'warning', 7000)
										this.setState({ loading: false })
									}
								} else {
									Alert('No se encontrarón clientes.', 'warning', 7000)
									this.setState({ loading: false })
								}
							} else {
								this.setState({ loading: false })
							}
						})
						.catch(error => {
							this.setState({ loading: false })
							console.error(error)
						})
				})
			} else {
				Alert('El mesanje supera la longitud permitida.', 'danger', 7000)
			}
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	sendSMS() {
		this.setState({ loading: true, modalCheckUsers: false }, () => {
			const phones = this.state.clientsSelected.map(c => {
				return '+57' + c.phone
			})
			sendSMS(phones, this.state.campain.sms, phones.length === 1 ? 'Transactional' : 'Campain').then(data => {
				if (data.status) {
					const campain = this.state.campain
					campains.create(campain).then(data2 => {
						if (data2.status) {
							this.setState({ loading: false })
							this.props.navigate('/dashboard/campains')
							Alert('Campaña registrada.')
						} else {
							this.setState({ loading: false })
							Alert('¡Ooops! tenemos problemas de conexión.', 'danger', 7000)
						}
					})
				} else {
					this.setState({ loading: false })
					Alert('¡Ooops! tenemos problemas de conexión.', 'danger', 7000)
				}
			})
		})
	}

	addContdition = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const conditions = this.state.conditions
		conditions.push({
			type: 'condition',
			camp: data.get('camp'),
			condition: data.get('condition'),
			value: data.get('value')
		})
		this.setState({ conditions, modalContidions: false })
	}

	modalContidions() {
		return (
			<Modal active={this.state.modalContidions}>
				<form action="#" onSubmit={this.addContdition}>
					<div className="title-page">
						<div className="title">
							<h2>Agregar condición</h2>
						</div>
					</div>
					<Input
						type="select"
						name="camp"
						title="Cuando:"
						options={[
							{ val: 'phone', text: 'Telefono de usuario' },
							{ val: 'name', text: 'Nombre de usuario' },
							{ val: 'address', text: 'Dirección de usuario' },
							{ val: 'level', text: 'Nivel de usuario' },
							{ val: 'mora', text: 'Deuda de usuario' }
						]}
					/>
					<Input
						type="select"
						name="condition"
						title="Sea:"
						options={[
							{ val: '=', text: 'Igual ( = )' },
							{ val: '>', text: 'Mayor ( > )' },
							{ val: '<', text: 'Menor ( < )' },
							{ val: '!=', text: 'Diferente ( <> )' },
							{ val: 'contains', text: 'Contenga' }
						]}
					/>
					<Input type="text" title="A: " placeholder="..." name="value" />
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalContidions: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Agregar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	addContditionType = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const conditions = this.state.conditions
		conditions.push({
			type: 'seperator',
			separator: data.get('separator')
		})
		this.setState({ conditions, modalTypeconditions: false, modalContidions: true })
	}

	modalContidionsType() {
		return (
			<Modal active={this.state.modalTypeconditions}>
				<form action="#" onSubmit={this.addContditionType}>
					<div className="title-page">
						<div className="title">
							<h2>Tipo de separador de condición</h2>
						</div>
					</div>
					<Input
						type="select"
						name="separator"
						title="Selecciones el tipo de separador"
						options={[
							{ val: 'AND', text: 'Y' },
							{ val: 'OR', text: 'O' }
						]}
					/>
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalTypeconditions: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Continuar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	modalTotalUsers() {
		return (
			<Modal active={this.state.modalCheckUsers}>
				<div>
					<div className="title-page">
						<div className="title">
							<h2>Verificar campaña</h2>
						</div>
					</div>
					<div className="item">
						<strong>Cantidad de usuarios: </strong>
						<span>{this.state.clientsSelected.length}</span>
					</div>
					<div className="item">
						<strong>Costo aproximado: </strong>
						<span>{this.state.clientsSelected.length * this.state.priceSMS}</span>
					</div>
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalCheckUsers: false, clientsSelected: [] })
							}}
							className="btn">
							Cancelar
						</a>
						<a
							href="#"
							className="btn"
							onClick={event => {
								event.preventDefault()
								this.sendSMS()
							}}>
							Continuar
						</a>
					</div>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.modalContidions()}
				{this.modalContidionsType()}
				{this.modalTotalUsers()}
				<form action="#" onSubmit={this.handleSubmit}>
					<Loader active={this.state.loading} message="Cargando..." />
					<div className="title-page">
						<div className="title">
							<Back />
							<h2>Campañas SMS</h2>
							<span>Nueva campaña</span>
						</div>
						<div className="btns">
							<button className="btn" type="submit">
								Crear campaña y enviar
							</button>
						</div>
					</div>
					<div className="card">
						<Input type="textarea" height={170} title="Mensaje SMS (Máximo 100 caracteres) *" name="sms" placeholder="Escribe el mensaje." />
						<Input type="select" title="Ruta ( Opcional )" name="way" options={this.state.ways} />
						<div className="divider"></div>
						<div className="title-page">
							<div className="title">
								<h2>Condiciones de envio</h2>
							</div>
						</div>
						<div className="divider"></div>
						{this.state.conditions.length > 0 && (
							<table className="table">
								<thead>
									<tr>
										<th>Cuando</th>
										<th>Sea</th>
										<th>A</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{this.state.conditions.map((condition, i) => (
										<tr key={i}>
											{condition.type === 'condition' ? (
												<>
													<td>{camps[condition.camp]}</td>
													<td>{conditions[condition.condition]}</td>
													<td>{condition.value}</td>
													<td>
														<a
															href="#"
															onClick={event => {
																event.preventDefault()
																const conditions = this.state.conditions.filter(c => c !== condition)
																if (conditions.length > 0) {
																	if (conditions[conditions.length - 1].type === 'seperator') {
																		conditions.pop()
																	}
																}
																this.setState({ conditions })
															}}
															className="btn-delete">
															Eliminar
														</a>
													</td>
												</>
											) : (
												<>
													<td
														colSpan={4}
														style={{
															textAlign: 'center',
															backgroundColor: '#600080',
															color: '#fff',
															fontWeight: 'bold'
														}}>
														{types[condition.separator]}
													</td>
												</>
											)}
										</tr>
									))}
								</tbody>
							</table>
						)}
						<a
							className="btn m-auto"
							href="#"
							onClick={e => {
								e.preventDefault()
								if (this.state.conditions.length > 0) {
									this.setState({ modalTypeconditions: true })
								} else {
									this.setState({ modalContidions: true })
								}
							}}>
							Agregar condición de envío
						</a>
						<div className="divider"></div>
						<button className="btn m-auto" type="submit">
							Calcular usuarios
						</button>
					</div>
				</form>
			</>
		)
	}
}

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import Loader from 'componentes/loader'
import { Price, Levenshtein, Alert } from 'utiles/functions'
import Input from 'componentes/input'
import { NavLink } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import Back from 'componentes/back'
import DB from 'utiles/db'
import e from 'utiles/settings'
const products = new DB('products')
const categorias = new DB('categories')
const inventoryTweaks = new DB('inventory_tweaks')
let productos = []
const cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			cant: 0,
			avaluoOperation: 0,
			avaluoCosto: 0,
			activos: 0,
			inactivos: 0,
			cantidades: [],
			description: ''
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})
		products
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				let cant = 0
				let avaluoOperation = 0
				let avaluoCosto = 0
				let activos = 0
				let inactivos = 0
				for (const p of data.data.Items) {
					cant += Number(p.stock)
					avaluoCosto += Number(p.stock) * Number(p.price_cost)
					avaluoOperation += Number(p.stock) * Number(p.price_operation)
					activos += p.status ? 1 : 0
					inactivos += !p.status ? 1 : 0
				}
				productos = data.data.Items
				const cantidades = data.data.Items.map(pro => {
					return {
						uuid: pro.uuid,
						cantidad: pro.stock,
						cantidad_original: pro.stock,
						cambio: false,
						image: pro.image,
						name: pro.name,
						price_credito: pro.price_credito
					}
				})

				this.setState({
					loading: false,
					data: data.data.Items,
					cant,
					avaluoOperation,
					avaluoCosto,
					activos,
					inactivos,
					cantidades
				})
			})
			.catch(error => {
				console.error(error)
			})
	}

	cantidadProducto(uuid, cantidades) {
		const input = document.getElementById(uuid)
		if (input !== null) {
			input.value = cantidades.filter(product => product.uuid === uuid)[0].cantidad
		}
		return cantidades.filter(product => product.uuid === uuid)[0].cantidad
	}

	restele(uuid) {
		const cantidades = this.state.cantidades
		const index = cantidades.findIndex(product => product.uuid === uuid)
		cantidades[index].cantidad--
		cantidades[index].cambio = true
		this.setState({
			cantidades
		})
	}

	sumele(uuid) {
		const cantidades = this.state.cantidades
		const index = cantidades.findIndex(product => product.uuid === uuid)
		cantidades[index].cantidad++
		cantidades[index].cambio = true
		this.setState({
			cantidades
		})
	}

	changeInput(uuid) {
		const input = document.getElementById(uuid)
		let value
		if (input !== null) {
			value = input.value
			const cantidades = this.state.cantidades
			const index = cantidades.findIndex(product => product.uuid === uuid)
			if (cantidades[index].cantidad !== value) {
				cantidades[index].cantidad = value
				cantidades[index].cambio = true
				this.setState({
					cantidades
				})
			}
		}
	}

	guardarAjuste() {
		const productosAjustados = []
		// construir arreglo de productos editados
		for (const product of this.state.cantidades) {
			if (product.cambio) {
				// si cambió el producto
				productosAjustados.push({
					uuid: product.uuid,
					name: product.name,
					image: product.image,
					quantity_old: Number(product.cantidad_original),
					quantity_new: Number(product.cantidad),
					price_credito: Number(product.price_credito)
				})
			}
		}
		// construir la info del usuario
		const usuarioAjuste = {
			email: this.props.user.email,
			name: this.props.user.name,
			rol: this.props.user.rol
		}

		if (this.state.description !== '') {
			this.setState({ loading: true }, async () => {
				const result = await inventoryTweaks.create({
					uuid: uuidv4(),
					user: usuarioAjuste,
					description: this.state.description,
					tweaks: productosAjustados,
					cava: this.props.cava.uuid
				})
				productosAjustados.map(async producto => {
					await products.update({
						Key: { uuid: producto.uuid },
						UpdateExpression: 'SET #s = :stock',
						ExpressionAttributeValues: {
							':stock': Number(producto.quantity_new)
						},
						ExpressionAttributeNames: {
							'#s': 'stock'
						},
						ReturnValues: 'UPDATED_NEW'
					})
				})

				if (result.status) {
					this.setState({ loading: false }, () => {
						this.props.navigate('dashboard/inventory_tweaks')
						Alert('Ajuste de inventario creado correctamente.')
					})
				} else {
					this.setState({ loading: false }, () => {
						Alert('Algo falló, inténtalo nuevamente.', 'danger')
					})
				}
			})
		} else {
			Alert('Ingresa la descripción del ajuste del inventario.', 'danger', 7000)
		}
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Ajustes de inventario</h2>
						<span>nuevo</span>
					</div>
					<div className="btns">
						<button className="btn" onClick={() => this.guardarAjuste()}>
							Guardar
						</button>
					</div>
				</div>

				<div className="divider"></div>
				<div className="card">
					<div className="la_descriopcion">
						<Input
							type="textarea"
							title="Descripción del ajuste de inventario"
							placeholder="Ingresa aquí tu descripción"
							name=""
							value={this.state.description}
							change={val => this.setState({ description: val })}
						/>
					</div>
					<div className="container-search">
						<div className="search">
							<input
								type="text"
								placeholder="Escribe algo.."
								onChange={event => {
									const val = event.target.value
									if (val.length > 2) {
										const produs = productos.filter(pr => {
											const name = pr.name.toLowerCase().split(' ')
											let coincidencia = false
											for (const palabra of name) {
												if (Levenshtein(palabra, val.toLowerCase()) < 3 || palabra.indexOf(val.toLowerCase()) > -1) {
													coincidencia = true
												}
											}
											return coincidencia
										})
										this.setState({ data: produs })
									} else {
										this.setState({ data: productos })
									}
								}}
							/>
							<button>
								<img src={require('../../img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Producto</th>
								<th>Cantidad</th>
								<th>Precio a crédito</th>
								<th>Costo de operación</th>
								<th>Categoria</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(pro => (
								<tr key={pro.uuid}>
									<td>
										<NavLink className="item-pro">
											{pro.image !== undefined ? (
												<img src={e.urlBucket + pro.image} alt={pro.name} />
											) : (
												<img src={require('../../img/defecto.jpg')} alt={pro.name} />
											)}
											<span>{pro.name}</span>
										</NavLink>
									</td>
									<td className="cantidadCombo">
										<span onClick={() => this.restele(pro.uuid)}>-</span>
										<Input
											type="number"
											title=""
											placeholder=""
											name="cantidad"
											id={pro.uuid}
											value={this.cantidadProducto(pro.uuid, this.state.cantidades)}
											change={() => this.changeInput(pro.uuid)}
										/>
										<span onClick={() => this.sumele(pro.uuid)}>+</span>
									</td>
									<td>{Price(pro.price_credito)}</td>
									<td>{Price(pro.price_operation)}</td>
									<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

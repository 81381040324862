import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import withParams from 'componentes/WithParams'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage, deleteImage } from 'utiles/functions'
import Back from 'componentes/back'
import DB from 'utiles/db'
const bcrypt = require('bcryptjs')
const users = new DB('users')
const cavas = new DB('cavas')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			cava: '',
			comision: '',
			date: '',
			email: '',
			name: '',
			password: '',
			phone: '',
			rol: '',
			status: '',
			avatar: null,
			old_avatar: null,
			cavas: [],
			isCobrador: ''
		}
	}

	componentDidMount() {
		cavas
			.getAll()
			.then(data => {
				const ca = data.data.Items.map(cava => {
					return { val: cava.uuid, text: cava.name }
				})
				this.setState({ cavas: ca })
			})
			.catch(error => {
				console.error(error)
			})

		users
			.get({ email: this.props.params.email })
			.then(data => {
				if (data.status) {
					this.setState({
						loading: false,
						cava: data.data.Item.cava,
						comision: data.data.Item.comision,
						date: data.data.Item.date,
						email: data.data.Item.email,
						name: data.data.Item.name,
						phone: data.data.Item.phone,
						rol: data.data.Item.rol,
						isCobrador: data.data.Item.rol,
						status: data.data.Item.status,
						old_avatar: data.data.Item.avatar
					})
				} else {
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo')
				}
			})
			.catch(error => {
				console.error(error)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo')
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('cava') !== '' && data.get('rol') !== '' && data.get('phone') !== '' && data.get('email') !== '') {
			if (this.state.isCobrador === 'Cobrador') {
				if (data.get('comision') < 100 && data.get('comision') > 0) {
					let expresion = 'SET #n = :name, #c = :cava, #r = :rol, #s = :status, #p = :phone, #d = :date, #co = :comision'
					const values = {
						':name': data.get('name'),
						':cava': data.get('cava'),
						':rol': data.get('rol'),
						':status': data.get('status'),
						':phone': data.get('phone'),
						':date': data.get('date'),
						':comision': Number(data.get('comision'))
					}
					const attributes = {
						'#n': 'name',
						'#c': 'cava',
						'#r': 'rol',
						'#s': 'status',
						'#p': 'phone',
						'#d': 'date',
						'#co': 'comision'
					}

					let valid = false
					if (data.get('password') !== '') {
						if (data.get('password').length > 6) {
							if (data.get('password') === data.get('password_confirm')) {
								const hash = bcrypt.hashSync(data.get('password'), 8)
								values[':password'] = hash
								attributes['#pas'] = 'password'
								expresion += ', #pas = :password'
								valid = true
							} else {
								Alert('Las contraseñas no coinciden.', 'danger', 7000)
							}
						} else {
							Alert('La contraseña es demasiado corta.', 'danger', 7000)
						}
					} else {
						valid = true
					}
					if (valid) {
						this.setState({ loading: true }, async () => {
							if (this.state.avatar !== null) {
								const url = await uploadImage(this.state.avatar)
								attributes['#a'] = 'avatar'
								values[':avatar'] = url
								expresion += ', #a = :avatar'
								if (this.state.old_avatar !== null && this.state.old_avatar !== undefined) {
									await deleteImage(this.state.old_avatar)
								}
							}
							await users.update({
								Key: { email: this.props.params.email },
								UpdateExpression: expresion,
								ExpressionAttributeValues: values,
								ExpressionAttributeNames: attributes,
								ReturnValues: 'UPDATED_NEW'
							})
							this.setState({ loading: false })
							this.props.navigate('admin/users')
							Alert('Usuario actualizado correctamente.')
						})
					}
				} else {
					Alert('El porcentaje de comisión no debe ser mayor a 100%.', 'danger', 7000)
				}
			} else {
				let expresion = 'SET #n = :name, #c = :cava, #r = :rol, #s = :status, #p = :phone, #d = :date, #co = :comision'
				const values = {
					':name': data.get('name'),
					':cava': data.get('cava'),
					':rol': data.get('rol'),
					':status': data.get('status'),
					':phone': data.get('phone'),
					':date': data.get('date'),
					':comision': Number(data.get('comision'))
				}
				const attributes = {
					'#n': 'name',
					'#c': 'cava',
					'#r': 'rol',
					'#s': 'status',
					'#p': 'phone',
					'#d': 'date',
					'#co': 'comision'
				}

				let valid = false
				if (data.get('password') !== '') {
					if (data.get('password').length > 6) {
						if (data.get('password') === data.get('password_confirm')) {
							const hash = bcrypt.hashSync(data.get('password'), 8)
							values[':password'] = hash
							attributes['#pas'] = 'password'
							expresion += ', #pas = :password'
							valid = true
						} else {
							Alert('Las contraseñas no coinciden.', 'danger', 7000)
						}
					} else {
						Alert('La contraseña es demasiado corta.', 'danger', 7000)
					}
				} else {
					valid = true
				}
				if (valid) {
					this.setState({ loading: true }, async () => {
						if (this.state.avatar !== null) {
							const url = await uploadImage(this.state.avatar)
							attributes['#a'] = 'avatar'
							values[':avatar'] = url
							expresion += ', #a = :avatar'
							if (this.state.old_avatar !== null && this.state.old_avatar !== undefined) {
								await deleteImage(this.state.old_avatar)
							}
						}
						await users.update({
							Key: { email: this.props.params.email },
							UpdateExpression: expresion,
							ExpressionAttributeValues: values,
							ExpressionAttributeNames: attributes,
							ReturnValues: 'UPDATED_NEW'
						})
						this.setState({ loading: false })
						this.props.navigate('admin/users')
						Alert('Usuario actualizado correctamente.')
					})
				}
			}
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Usuarios</h2>
						<span>Editar usuario</span>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="image" name="avatar" img={this.state.old_avatar} title="Avatar" change={data => this.setState({ avatar: data })} />
							<Input type="text" title="Nombre del usuario" placeholder="Ingresa el nombre" value={this.state.name} name="name" />
							<Input
								type="select"
								value={this.state.cava}
								change={val => this.setState({ cava: val })}
								name="cava"
								title="Selecionar cava *"
								options={this.state.cavas}
							/>
							<Input
								type="select"
								value={this.state.rol}
								change={val => this.setState({ rol: val, isCobrador: val })}
								name="rol"
								title="Selecionar rol *"
								options={[
									{ val: '', text: 'Seleccionar' },
									{ val: 'Administrador', text: 'Administrador' },
									{ val: 'Supervisor', text: 'Supervisor' },
									{ val: 'Cavero', text: 'Cavero' },
									{ val: 'Cobrador', text: 'Cobrador' },
									{ val: 'Auxiliar', text: 'Auxiliar' }
								]}
							/>
							<Input
								type="select"
								value={this.state.status}
								name="status"
								change={val => this.setState({ status: val })}
								title="Estado *"
								options={[
									{ val: 'Activo', text: 'Activo' },
									{ val: 'Inactivo', text: 'Inactivo' }
								]}
							/>
						</div>
						<div value={this.state.column} className="column">
							<Input
								type="number"
								title="Telefono *"
								placeholder="Ingresa el telefono"
								value={this.state.phone}
								change={val => this.setState({ phone: val })}
								name="phone"
							/>
							<Input type="date" title="Fecha de nacimiento" placeholder="Ingresa la fecha de nacimiento" value={this.state.date} name="date" />

							<Input
								type="email"
								disabled={true}
								title="Correo electrónico *"
								placeholder="Ingresa el Correo electrónico"
								value={this.state.email}
								name="email"
							/>
							<Input type="password" title="Contraseña *" placeholder="Ingresa la contraseña" value={this.state.password} name="password" />
							<Input
								type="password"
								title="Confirmar contraseña *"
								placeholder="Confirma la contraseña"
								value={this.state.password_confirm}
								name="password_confirm"
							/>
							{this.state.isCobrador === 'Cobrador' && (
								<Input
									type="number"
									title="Porcentaje de comisión"
									placeholder="Ingresa el % de comisión"
									value={this.state.comision}
									change={val => this.setState({ comision: val })}
									name="comision"
								/>
							)}
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn m-auto" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(AnimateScreen(Page))

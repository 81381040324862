import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { Price, hoy, Confirm, Alert } from 'utiles/functions'
import { NavLink } from 'react-router-dom'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
import { setLoading } from 'store'
import Table from 'componentes/Table'
const dispatchthecollectorsTable = new DB('dispatchthecollectors')
const usersTable = new DB('users')
const inventoryTweaksTable = new DB('inventory_tweaks')
const productsTable = new DB('products')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dispatchs: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			setLoading(true)
			const dicpatchsResult = await dispatchthecollectorsTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const usersResult = await usersTable.query(
				'#cava = :c AND #rol = :rol',
				{ ':c': this.props.cava.uuid, ':rol': 'Cobrador' },
				{ '#cava': 'cava', '#rol': 'rol' }
			)
			if (dicpatchsResult.status === false || usersResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const cobradores = []
			for (const user of usersResult.data.Items) {
				cobradores[user.email] = user
			}
			const dispatchs = []
			for (const dispatch of dicpatchsResult.data.Items) {
				dispatch.cobrador = cobradores[dispatch.way.user]
				dispatchs.push(dispatch)
			}
			this.setState({ dispatchs })
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	async deleteDispatch(uuid, uudiTweak) {
		try {
			setLoading(true)
			if (uudiTweak && uudiTweak !== '') {
				// Consultar ajuste de inventario del despacho
				const ajusteResult = await inventoryTweaksTable.get({ uuid: uudiTweak })
				if (ajusteResult.status === false) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					return
				}
				const tweakProducts = {}
				for (const tweak of ajusteResult.data.Item.tweaks) {
					tweakProducts[tweak.uuid] = tweak
				}
				// Consultar los productos del ajuste
				const uuidsProducts = Object.keys(tweakProducts)
				const productsResult = await productsTable.query('contains(:uuids, #uuid)', { ':uuids': uuidsProducts }, { '#uuid': 'uuid' })
				if (productsResult.status === false) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					return
				}
				// Ajustar productos
				const ajustmentProducts = productsResult.data.Items.map(product => ({
					uuid: product.uuid,
					name: product.name,
					image: product.image,
					quantity_old: product.stock,
					quantity_new: product.stock + (tweakProducts[product.uuid].quantity_old - tweakProducts[product.uuid].quantity_new),
					price_credito: product.price_credito
				}))
				// cambio las cantidades de los productos
				const promisesProducts = []
				for (const product of ajustmentProducts) {
					promisesProducts.push(
						productsTable.update({
							Key: { uuid: product.uuid },
							UpdateExpression: 'SET #s = :stock',
							ExpressionAttributeValues: {
								':stock': Number(product.quantity_new)
							},
							ExpressionAttributeNames: {
								'#s': 'stock'
							}
						})
					)
				}
				const ajustmentProductsResult = await Promise.all(promisesProducts)
				if (ajustmentProductsResult.some(res => res.status === false)) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					return
				}
				// Registro el ajuste del inventario
				const inventoryTweaksResult = await inventoryTweaksTable.create({
					uuid: uuidv4(),
					description: 'Ajuste de inventario por eliminación de despacho',
					cava: this.props.cava.uuid,
					tweaks: ajustmentProducts,
					user: {
						email: this.props.user.email,
						name: this.props.user.name,
						rol: this.props.user.rol
					}
				})
				if (inventoryTweaksResult.status === false) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					return
				}
			}
			// Eliminamos el despacho
			const deleteDispatchResult = await dispatchthecollectorsTable.delete({ uuid })
			if (deleteDispatchResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			this.init()
			Alert('Despacho eliminado correctamente')
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		const columns = [
			{ header: 'Ruta', accessorKey: 'way.name', cell: ({ row }) => `${row.original.way.name} - ${row.original.way.day}` },
			{ header: 'Cobrador', accessorKey: 'cobrador.name', cell: ({ row }) => row.original.cobrador.name || 'N/A' },
			{ header: 'Cantidad de productos', accessorKey: 'quantity_products' },
			{ header: 'Avalúo', accessorKey: 'price_credito', cell: props => Price(props.row.original.price_credito) },
			{ header: 'Fecha despacho', accessorKey: 'time', cell: props => hoy(props.row.original.time) },
			{
				header: 'Estado',
				accessorKey: 'status',
				cell: ({ row }) => (
					<span
						className={`status ${row.original.status === 'Validado' ? 'Activo' : row.original.status === 'Des-sincronizado' ? 'Desincronizado' : 'Inactivo'}`}>
						{row.original.status === 'Validado' ? 'Validado' : row.original.status === 'Des-sincronizado' ? 'Desincronizado' : 'Sin validar'}
					</span>
				)
			},
			{
				header: 'Acciones',
				cell: ({ row }) => {
					const dispatch = row.original
					return (
						<>
							{dispatch.status === 'sin validar' && (
								<>
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											this.props.navigate('/dashboard/dispatchthecollectors/edit/' + dispatch.uuid)
										}}
										className="btn-edit">
										Editar
									</a>
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											Confirm('¿Realmente desea eliminar este despacho de cobrador?', () => this.deleteDispatch(dispatch.uuid, dispatch.uuid_tweaks))
										}}
										className="btn-delete"
										style={{ marginLeft: 10 }}>
										Eliminar
									</a>
								</>
							)}
							{dispatch.status === 'Des-sincronizado' && dispatch.desync && (
								<>
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											this.props.navigate('/dashboard/dispatchthecollectors/show/' + dispatch.uuid)
										}}
										className="btn-edit">
										Detalles
									</a>
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											Confirm('¿Realmente desea eliminar este despacho de cobrador?', () => this.deleteDispatch(dispatch.uuid, dispatch.uuid_tweaks))
										}}
										className="btn-delete"
										style={{ marginLeft: 10 }}>
										Eliminar
									</a>
								</>
							)}
							{dispatch.status === 'Validado' && (
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/dashboard/dispatchthecollectors/show/' + dispatch.uuid)
									}}
									className="btn-edit">
									Detalles
								</a>
							)}
						</>
					)
				}
			}
		]

		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Despacho de cobradores</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						{this.props.user.rol !== 'Supervisor' && (
							<NavLink to="/dashboard/dispatchthecollectors/create" className="btn">
								Nuevo despacho
							</NavLink>
						)}
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<Table columns={columns} data={this.state.dispatchs} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

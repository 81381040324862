import { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Menu from 'componentes/menuadmin.jsx'
import Header from 'componentes/header.jsx'
import Footer from 'componentes/footer'
function Dashboard(props) {
	const [mini, setMini] = useState(false)
	const handleResize = () => {
		if (window.innerWidth <= 1024) {
			setMini(true)
		} else {
			setMini(false)
		}
	}
	window.addEventListener('resize', handleResize)
	useEffect(() => {
		handleResize()
	}, [])

	if (props.user !== null) {
		if (props.user.rol === 'Super') {
			return (
				<>
					<div id="notifications"></div>
					<main className={`${mini ? 'mini-menu' : ''}`}>
						<Menu setUser={props.setUser} navigate={props.navigate} />
						<div className="content">
							<Header setMini={setMini} mini={mini} user={props.user} />
							<aside>
								<Outlet />
							</aside>
							<Footer />
						</div>
					</main>
				</>
			)
		} else {
			return <Navigate to="/" />
		}
	} else {
		return <Navigate to="/" />
	}
}

export default Dashboard

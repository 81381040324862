const jsonVersion = require('utiles/version.json')
export default function Footer() {
	return (
		<footer>
			<p className="version">
				<strong>
					Versión: {jsonVersion.major}.{jsonVersion.minor}.{jsonVersion.patch} - {jsonVersion.date}
				</strong>
			</p>
			<p className="brand">
				Desarrollado por &copy; <a href="https://hexasoftware.net">Hexa Software</a> - {new Date().getFullYear().toString()}
			</p>
		</footer>
	)
}

import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import AnimateScreen from 'componentes/AnimateScreen'
import DB from 'utiles/db'
import { hoy, Price } from 'utiles/functions'
import Back from 'componentes/back'
const campains = new DB('campains')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			campains: []
		}
	}

	// Falta la vista de ver y replicar campaña

	componentDidMount() {
		campains
			.query('#cava = :cava', { ':cava': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ campains: data.data.Items, loading: false })
			})
			.catch(error => {
				console.error(error)
			})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Campañas SMS</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/campains/create" className="btn">
							Nueva campaña
						</NavLink>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('../../img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Mensaje</th>
								<th>Enviado por</th>
								<th>Fecha</th>
								<th>Cantidad SMS</th>
								<th>Costo camapaña</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.campains.map(c => (
								<tr key={c.uuid}>
									<td>{c.sms}</td>
									<td>{c.user.name}</td>
									<td>{hoy(c.time)}</td>
									<td>{c.countClients}</td>
									<td>{Price(c.countClients * 0.0015, 'USD')}</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('/dashboard/campains/edit/' + c.uuid)
											}}
											className="btn-edit">
											Ver
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

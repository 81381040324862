import { Component } from 'react'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import { Alert, Price, Confirm } from 'utiles/functions'
import Back from 'componentes/back'
import Modal from 'componentes/modal'
import DB from 'utiles/db'
import e from 'utiles/settings'
const clients = new DB('clients')
const clientsbyways = new DB('clientsbyways')
const levels = new DB('levels')
const movsCarterInWay = new DB('movsCarterInWay')
const movsClientsByWay = new DB('movsClientsByWay')
const orders = new DB('orders')

// TODO: este archivo se debe refactorizar

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			name: '',
			address: '',
			last_mov: {},
			lat: 0,
			log: 0,
			level: {},
			new: 'No',
			phone: this.props.params.phone,
			old_phone: this.props.params.phone,
			photo: '',
			status: '',
			time: 0,
			ways: [],
			levels: [],
			originalLevels: [],
			modalPhoto: false,
			waysToAdd: []
		}
	}

	componentDidMount() {
		clients
			.get({ phone: this.props.params.phone })
			.then(data => {
				if (data.status) {
					this.setState({
						loading: false,
						name: data.data.Item.name,
						address: data.data.Item.address,
						last_mov: data.data.Item.last_mov,
						lat: data.data.Item.lat,
						log: data.data.Item.log,
						level: data.data.Item.level.uuid,
						new: data.data.Item.new,
						phone: data.data.Item.phone,
						photo: data.data.Item.photo,
						status: data.data.Item.status,
						time: data.data.Item.time,
						ways: data.data.Item.ways
					})
				} else {
					this.setState({ loading: false })
					Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo')
				}
			})
			.catch(error => {
				console.error(error)
				this.setState({ loading: false })
				Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo')
			})

		levels.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' }).then(data => {
			if (data.status) {
				this.setState({
					levels: data.data.Items.map(l => {
						return { val: l.uuid, text: l.name + ' - ' + Price(l.limit) }
					}),
					originalLevels: data.data.Items
				})
			}
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (this.state.phone !== this.state.old_phone) {
			// cambio de número de teléfono
			Confirm('¿Realmente deseas actualizar el número de teléfono del cliente?', () => this.validateClientPhone(event, data))
		} else {
			this.updateClient(event, false, data)
		}
	}

	async validateClientPhone(event, data) {
		// validar si hay clientes con el mismo telefono
		const validatePhone = await clients.get({ phone: this.state.phone })
		if (validatePhone.data.Item) {
			Alert('El teléfono que ingresaste ya está registrado')
		} else {
			// validar si hay registros que contengan el telefono y actualizarlos
			await this.updateRegistersPhone()
			// actualizar telefono e info del cliente
			await this.updateClient(event, true, data)
		}
	}

	async updateRegistersPhone() {
		// tablas para actualizar registros
		// clientsbyways
		const clientsByWaysClient = await clientsbyways.query('#phone = :p', { ':p': this.state.old_phone }, { '#phone': 'phone' })
		// validar si encuentra para actualizar **
		clientsByWaysClient.data.Items.map(async way => {
			await clientsbyways.newUpdate(
				{
					phone: this.state.phone
				},
				{ uuid: way.uuid }
			)
		})
		// movsCarterInWay
		const movsCarterInWayClient = await movsCarterInWay.query(
			'#phone_client = :p',
			{ ':p': this.state.old_phone },
			{ '#phone_client': 'phone_client' }
		)
		movsCarterInWayClient.data.Items.map(async mov => {
			await movsCarterInWay.newUpdate(
				{
					phone_client: this.state.phone
				},
				{ uuid: mov.uuid }
			)
		})
		// movsClientsByWay
		const movsClientsByWayClient = await movsClientsByWay.query(
			'#phone_client = :p',
			{ ':p': this.state.old_phone },
			{ '#phone_client': 'phone_client' }
		)
		movsClientsByWayClient.data.Items.map(async mov => {
			await movsClientsByWay.newUpdate(
				{
					phone_client: this.state.phone
				},
				{ uuid: mov.uuid }
			)
		})
		// orders
		const ordersClient = await orders.query('#phone_client = :p', { ':p': this.state.old_phone }, { '#phone_client': 'phone_client' })
		ordersClient.data.Items.map(async order => {
			await orders.newUpdate(
				{
					phone_client: this.state.phone
				},
				{ uuid: order.uuid }
			)
		})
	}

	async updateClient(event, updatePhone = false, data) {
		if (data.get('name') !== '' && data.get('address') !== '' && data.get('level') !== '') {
			const level = this.state.originalLevels.filter(l => l.uuid === data.get('level'))[0]
			this.setState({ loading: true }, async () => {
				// crear objeto de actualización de datos
				const dataCliente = {
					name: data.get('name'),
					address: data.get('address'),
					status: this.state.status,
					level: { uuid: level.uuid, name: level.name, amount: level.limit }
				}
				await clients.newUpdate(dataCliente, { phone: this.props.params.phone }).then(async () => {
					const dataways = (await clientsbyways.query('#phone = :p', { ':p': this.props.params.phone }, { '#phone': 'phone' })).data.Items
					for (const i in dataways) {
						await clientsbyways.newUpdate({ name: data.get('name'), status: this.state.status }, { uuid: dataways[i].uuid })
					}
				})
				if (updatePhone) {
					const cliente = await clients.get({ phone: this.props.params.phone })
					const infoCliente = cliente.data.Item
					infoCliente.phone = this.state.phone
					await clients.delete({ phone: this.props.params.phone })
					await clients.create(infoCliente)
				}

				this.setState({ loading: false }, () => {
					Alert('Usuario actualizado')
					this.props.navigate('dashboard/clients')
				})
			})
		} else {
			Alert('Ingresa la información (*)')
		}
	}

	modalPhoto() {
		return (
			<Modal active={this.state.modalPhoto}>
				<div className="mapa-modal">
					<div className="title-page">
						<div className="title">
							<h2>Foto del lugar</h2>
						</div>
					</div>
					<div className="mapa">
						<img src={e.urlBucket + this.state.photo} style={{ maxHeight: '75vh' }} />
						<div className="btns-flex" style={{ paddingTop: 20 }}>
							<a
								href="#"
								className="btn"
								onClick={e => {
									e.preventDefault()
									this.setState({ modalPhoto: false })
								}}>
								Cerrar
							</a>
						</div>
					</div>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				{this.modalPhoto()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Clientes</h2>
						<span>Nuevo cliente</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input
								type="text"
								title="Teléfono del cliente *"
								value={this.state.phone}
								change={val => {
									this.setState({ phone: val })
								}}
								placeholder="Ingresa el teléfono"
								name="phone"
							/>
							<Input type="text" title="Nombre del cliente *" value={this.state.name} placeholder="Ingresa el nombre" name="name" />
							<Input type="text" title="Dirección *" value={this.state.address} placeholder="Ingresa el nombre" name="address" />
						</div>
						<div className="column">
							<Input
								type="select"
								title="Nivel del cliente *"
								value={this.state.level}
								change={val => this.setState({ level: val })}
								name="level"
								options={this.state.levels}
							/>
							<Input
								type="checkbox"
								id="status"
								title="Estado (Inactivo / Activo)"
								active={this.state.status === 'Activo'}
								change={val => {
									this.setState({ status: val ? 'Activo' : 'Inactivo' })
								}}
							/>
							<div className="item-inline">
								<strong>Fecha de creación:</strong>
								<span>{this.state.time}</span>
							</div>
							<div className="item-inline">
								<strong>Usuario nuevo:</strong>
								<span>{this.state.new}</span>
							</div>
							<a href={'http://www.google.com/maps/place/' + this.state.lat + ',' + this.state.log} target="_blank" rel="noreferrer">
								Ver ubicación en google maps
							</a>
							<br />
							<a
								href="#"
								onClick={e => {
									e.preventDefault()
									this.setState({ modalPhoto: true })
								}}>
								Ver foto del lugar
							</a>
							<br />
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.props.navigate(`/dashboard/clients/map/${this.state.phone}`)
								}}>
								Editar ubicación en el mapa
							</a>
						</div>
					</div>
					<div className="divider"></div>
					<div className="title-page">
						<div className="title">
							<h2>Rutas donde se encuentra asociado el cliente</h2>
						</div>
					</div>
					<div className="divider"></div>
					<table className="table">
						<thead>
							<tr>
								<th>Ruta</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.ways.map(way => (
								<tr key={way.uuid}>
									<td>
										{way.name} - {way.day}
									</td>
									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('/dashboard/ways/edit/' + way.uuid)
											}}
											className="btn-edit"
											style={{ marginRight: 10 }}>
											Ver ruta
										</a>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate(`/dashboard/clients/history/${this.state.phone}/${way.uuid}`)
											}}
											className="btn-edit">
											Ver movimientos
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(Page)

import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Back from 'componentes/back'
import AnimateScreen from 'componentes/AnimateScreen'
import Mapa from 'componentes/mapa'
import { Alert, Price } from 'utiles/functions'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
import e from 'utiles/settings'
import { setLoading } from 'store'
const clientsbywaysTable = new DB('clientsbyways')
const clientsTable = new DB('clients')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			client: null,
			clientsByWay: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			setLoading(true)
			const clientsBtWayResult = await clientsbywaysTable.query('#uuid_way = :u', { ':u': this.props.params.uuid }, { '#uuid_way': 'uuid_way' })
			if (clientsBtWayResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const clientsByWay = clientsBtWayResult.data.Items.sort((a, b) => a.index - b.index)
			this.setState({
				clientsByWay,
				lat: clientsByWay[0].lat,
				lng: clientsByWay[0].log
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	async getClient(phone, index) {
		try {
			setLoading(true)
			const clientResult = await clientsTable.get({ phone })
			if (clientResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const client = clientResult.data.Item
			client.index = index
			this.setState({ client })
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		return (
			<>
				<div>
					<div className="container-mapa">
						<Back />
						<Mapa
							center={{ lat: parseFloat(Number(this.state.lat || 0)), lng: parseFloat(Number(this.state.lng || 0)) }}
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDjfUItorM8PVBzBBmE5Stx9wLyuf0_310&v=3.exp&libraries=places"
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `88vh` }} />}
							mapElement={<div style={{ height: `100%` }} />}
							markers={this.state.clientsByWay}
							click={c => this.getClient(c.phone, c.index)}
						/>
					</div>
					{this.state.client && (
						<div className="info-marker">
							<div className="img">
								<img src={e.urlBucket + this.state.client.photo} alt="" />
							</div>
							<h2>Datos del cliente</h2>
							<div className="item">
								<strong>Orden en la ruta:</strong>
								<span>{this.state.client.index}</span>
							</div>
							<div className="item">
								<strong>Nombre:</strong>
								<span>{this.state.client.name}</span>
							</div>
							<div className="item">
								<strong>Teléfono:</strong>
								<span>{this.state.client.phone}</span>
							</div>
							<div className="item">
								<strong>Dirección:</strong>
								<span>{this.state.client.address}</span>
							</div>
							<div className="item">
								<strong>Estado:</strong>
								<span>{this.state.client.status}</span>
							</div>
							<div className="item">
								<strong>Nivel:</strong>
								<span>{this.state.client.level.name}</span>
							</div>
							<div className="item">
								<strong>Tope:</strong>
								<span>{this.state.client.level.amount}</span>
							</div>
							<div className="item">
								<strong>Último movimiento:</strong>
								<span>
									{this.state.client.last_mov.type === 'debe' ? '-' : ''} {Price(this.state.client.last_mov.amount)}
								</span>
							</div>
							<div className="item">
								<strong>Latitude:</strong>
								<span>{this.state.client.lat}</span>
							</div>
							<div className="item">
								<strong>Longitud:</strong>
								<span>{this.state.client.log}</span>
							</div>
							<div className="btns">
								<NavLink className="btn" to={'/dashboard/clients/edit/' + this.state.client.phone}>
									Ver cliente
								</NavLink>
								<a href="#" className="btn">
									Ver movimientos
								</a>
								<a
									href="#"
									className="btn"
									onClick={event => {
										event.preventDefault()
										this.setState({ client: null })
									}}>
									Cerrar
								</a>
							</div>
						</div>
					)}
				</div>
			</>
		)
	}
}
export default withParams(AnimateScreen(Page))

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import DB from 'utiles/db'
import { Price, hoy, Alert } from 'utiles/functions'
import Table from 'componentes/Table'
import { setLoading } from 'store'
const providersTable = new DB('providers')
const spentsTable = new DB('spents')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			spents: []
		}
	}

	componentDidMount() {
		this.getSpents()
	}

	async getSpents() {
		try {
			setLoading(true)
			const resultProviders = await providersTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const resultSpents = await spentsTable.query(
				'#cava = :c AND #rawmaterials = :raw',
				{ ':c': this.props.cava.uuid, ':raw': false },
				{ '#cava': 'cava', '#rawmaterials': 'rawmaterials' }
			)
			if (resultProviders.status === false || resultSpents.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const providers = {}
			for (const provider of resultProviders.data.Items) {
				providers[provider.uuid] = provider
			}
			let total = 0
			let deuda = 0
			let abonado = 0
			const spents = []
			for (const compra of resultSpents.data.Items) {
				total += Number(compra.total)
				if (compra.abonado) {
					abonado += Number(compra.abonado)
					deuda += Number(compra.total) - Number(compra.abonado)
				} else {
					deuda += Number(compra.total)
				}
				compra.provider = providers[compra.provider]
				spents.push(compra)
			}
			this.setState({
				spents,
				totalCompra: total,
				totalAbonado: abonado,
				totalDeuda: deuda
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		const columns = [
			{ header: 'Proveedor', accessorKey: 'provider.name' },
			{ header: 'Teléfono', accessorKey: 'provider.phone' },
			{ header: 'Descripción', accessorKey: 'description' },
			{ header: 'Valor total', accessorKey: 'total', cell: ({ row }) => Price(row.original.total) },
			{ header: 'Fecha', accessorKey: 'time', cell: ({ row }) => hoy(row.original.time, true) },
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<NavLink to={`/dashboard/spents/edit/${row.original.uuid}`} className="btn-edit">
						Editar
					</NavLink>
				)
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Gastos de operación</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/spents/create" className="btn">
							Nuevo gasto
						</NavLink>
						<NavLink to="/dashboard/typespents" className="btn">
							Tipos de gastos
						</NavLink>
					</div>
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.spents} pageSize={40} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import withParams from 'componentes/WithParams'
import { NavLink } from 'react-router-dom'
import Input from 'componentes/input'
import Modal from 'componentes/modal'
import Table from 'componentes/Table'
import Back from 'componentes/back'
import e from 'utiles/settings'
import { setLoading } from 'store'
import { getDate, Price, getDateParse, getHourParse, hoy, Alert, sendNotification, Confirm } from 'utiles/functions'
import DB from 'utiles/db'
import { v4 as uuidv4 } from 'uuid'
// const clientsTable = new DB('clients')
const typeSpentsInWaysTable = new DB('typespentsinways')
const movsCarterInWayTable = new DB('movsCarterInWay')
const productsTable = new DB('products')
const inventoryTweaksTable = new DB('inventory_tweaks')
const waysTable = new DB('ways')
const usersTable = new DB('users')
const dispatchTable = new DB('dispatchthecollectors')
const clientsWaysTable = new DB('clientsbyways')
const ordersTable = new DB('orders')
const productsOrdersTable = new DB('productsOrders')
const donationsTable = new DB('donations')
const smsTable = new DB('sms')
const movsClientsByWayTable = new DB('movsClientsByWay')

class SyncWay extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: 0,
			way: {},
			cobrador: {},
			clients: [],
			orders: [],
			productsOrders: [],
			avaluoMercancia: 0,
			avaluoMercanciaSobrante: 0,
			avaluoMercanciaDonada: 0,
			regado: 0,
			deberia: 0,
			recodigo: 0,
			cantidadProductosRegados: 0,
			cantidadProductosSobrantes: 0,
			donations: [],
			cantidadProductosDonados: 0,
			avaluoProductosDonados: 0,
			movimientos: [],
			efectivo: 0,
			transferencias: 0,
			totalRecogido: 0,
			totalGastos: 0,
			optionsSpents: [],
			productosRegados: {},
			productosSobrantes: [],
			smsEnviados: [],
			// movimientos de un cliente
			modalMovsClientByWay: false,
			movsClientByWay: [],
			clientMovs: {},
			// ordenes de un cliente
			modalOrderClient: false,
			orderClient: {},
			// Modal registrar abono
			modalRegisterSpent: false,
			typeSpentMov: false,
			asumedCava: false,
			// Modal de precierre
			modalPrecierre: false,
			observacionesPrecierre: ''

			// modalCierre: false,
			// observacionesCierre: ''
		}
	}

	componentDidMount() {
		this.init()
	}

	init = async () => {
		try {
			setLoading(true)
			// Obtener ruta
			const way = (await waysTable.get({ uuid: this.props.params.uuid })).data.Item
			// Obtener cobrador
			const cobrador = (await usersTable.get({ email: way.cobrador })).data.Item
			// Obtener despacho de la ruta
			const dispatch = way.dispatch !== '' ? (await dispatchTable.get({ uuid: way.dispatch })).data.Item : {}
			// obtener clientes por ruta y Organizarlos menor a mayor por su index (orden de registro)
			const clients = (await clientsWaysTable.query('#uuid_way = :u', { ':u': this.props.params.uuid }, { '#uuid_way': 'uuid_way' })).data.Items.sort(
				(a, b) => a.index - b.index
			)
			const phones = []
			const namesClientsByPhone = {}
			for (const c of clients) {
				namesClientsByPhone[c.phone] = c.name
				phones.push(c.phone)
			}
			// Obtener ordenes de clientes
			const orders = (
				await ordersTable.query(
					'#uuid_way = :uuid_way AND #uuid_closed = :empty AND contains(:phone_client, #phone_client)',
					{ ':uuid_way': this.props.params.uuid, ':empty': 'EMPTY', ':phone_client': phones },
					{ '#uuid_way': 'uuid_way', '#uuid_closed': 'uuid_closed', '#phone_client': 'phone_client' }
				)
			).data.Items
			const uuidsOrders = []
			orders.map(o => {
				uuidsOrders.push(o.uuid)
				o.fecha = hoy(o.time, true)
				o.name = namesClientsByPhone[o.phone_client]
				return o
			})
			// Obtener productos de las ordenes para calcular el total regado y la cantidad de productos regados
			const productsOrders = (
				await productsOrdersTable.query('contains(:uuids, #uuid_order)', { ':uuids': uuidsOrders }, { '#uuid_order': 'uuid_order' })
			).data.Items
			const productosRegados = {}
			let regado = 0
			let cantidadProductosRegados = 0
			for (const p of productsOrders) {
				regado += Number(p.amount) * Number(p.price)
				cantidadProductosRegados += Number(p.amount)
				if (productosRegados[p.uuid]) {
					productosRegados[p.uuid].amount += Number(p.amount)
				} else {
					productosRegados[p.uuid] = p
				}
			}
			// Eliminar productos que ya se regaron o se donaron
			let productosSobrantes = []
			let avaluoMercanciaSobrante = 0
			let cantidadProductosSobrantes = 0
			let cantidadProductosDonados = 0
			let avaluoProductosDonados = 0
			if (way.dispatch !== '') {
				// puede darse el caso de que una ruta no tenga despacho
				productosSobrantes = dispatch.products
				for (const uuid of Object.keys(productosRegados)) {
					productosSobrantes = productosSobrantes.map(ps => {
						if (ps.uuid === productosRegados[uuid].uuid_product) {
							ps.quantity -= productosRegados[uuid].amount
						}
						return ps
					})
				}
			}
			// obtener donaciones
			const donations = (
				await donationsTable.query(
					'#uuid_way = :uuid_way AND #uuid_closed = :empty',
					{ ':uuid_way': this.props.params.uuid, ':empty': 'EMPTY' },
					{ '#uuid_way': 'uuid_way', '#uuid_closed': 'uuid_closed' }
				)
			).data.Items
			donations.map(d => {
				cantidadProductosDonados += Number(d.amount)
				avaluoProductosDonados += Number(d.amount) * Number(d.price_credito)
				d.fecha = getDateParse(new Date(Number(d.fecha))) + ' a las ' + getHourParse(new Date(Number(d.fecha)))
				productosSobrantes = productosSobrantes.map(ps => {
					if (ps.uuid === d.uuid_product) {
						ps.quantity -= Number(d.amount)
					}
					return ps
				})
				return d
			})
			productosSobrantes = productosSobrantes.filter(ps => ps.quantity > 0)
			for (const ps of productosSobrantes) {
				cantidadProductosSobrantes += Number(ps.quantity)
				avaluoMercanciaSobrante += Number(ps.quantity) * Number(ps.price_credito)
			}
			// Movimientos de cartera en la ruta
			const movimientos = (
				await movsCarterInWayTable.query(
					'#uuid_way = :uuid_way AND #uuid_closed = :empty',
					{ ':uuid_way': this.props.params.uuid, ':empty': 'EMPTY' },
					{ '#uuid_way': 'uuid_way', '#uuid_closed': 'uuid_closed' }
				)
			).data.Items
			const gastos = []
			let efectivo = 0
			let gastosEfectivo = 0
			let transferencias = 0
			let gastosTransferencias = 0
			let totalRecogido = 0
			let totalGastos = 0
			let deberia = 0
			for (const m of movimientos) {
				m.fecha = getDateParse(new Date(Number(m.date))) + ' a las ' + getHourParse(new Date(Number(m.date)))
				if (m.motivo === 'Registro de abono') {
					m.client = namesClientsByPhone[m.phone_client]
					if (m.type === 'Efectivo') {
						efectivo += Number(m.amount)
					}
					if (m.type === 'Transferencia') {
						transferencias += Number(m.amount)
					}
					totalRecogido += Number(m.amount)
				} else {
					if (m.type === 'Efectivo') {
						gastosEfectivo += Number(m.amount)
					}
					if (m.type === 'Transferencia') {
						gastosTransferencias += Number(m.amount)
					}
					totalGastos += Number(m.amount)
					gastos.push(m)
				}
			}
			efectivo = Number(efectivo) - Number(gastosEfectivo)
			transferencias = Number(transferencias) - Number(gastosTransferencias)
			deberia = totalRecogido - totalGastos
			// obtener tipos de gastos
			const optionsSpentsResult = (await typeSpentsInWaysTable.query('#cava = :cava', { ':cava': this.props.cava.uuid }, { '#cava': 'cava' })).data
				.Items
			const optionsSpents = optionsSpentsResult.map(t => {
				return { val: t.uuid, text: t.name }
			})
			const smsEnviados = (await smsTable.query('#uuid_way = :uuid_way', { ':uuid_way': this.props.params.uuid }, { '#uuid_way': 'uuid_way' })).data
				.Items
			this.setState({
				way,
				cobrador,
				dispatch,
				clients,
				orders,
				productsOrders,
				avaluoMercancia: dispatch.price_credito || 0,
				avaluoMercanciaSobrante,
				regado,
				deberia,
				cantidadProductosRegados,
				donations,
				cantidadProductosDonados,
				avaluoProductosDonados,
				cantidadProductosSobrantes,
				gastos,
				movimientos,
				efectivo,
				transferencias,
				totalRecogido,
				totalGastos,
				optionsSpents,
				productosRegados,
				productosSobrantes,
				smsEnviados
			})
			setLoading(false)
		} catch (error) {
			setLoading(false)
			Alert('Ocurrio un error inesperado', 'danger')
			console.error(error)
		}
	}

	async makePrecierre() {
		setLoading(true)
		// Ajuste de inventario de los productos sobrantes
		// -----------------------------------------------
		if (this.state.productosSobrantes.length > 0) {
			const uuids = []
			const productsBandera = {}
			for (const product of this.state.productosSobrantes) {
				uuids.push(product.uuid)
				productsBandera[product.uuid] = product.quantity
			}
			const productsToUpdate = (await productsTable.query('contains(:uuids, #uuid)', { ':uuids': uuids }, { '#uuid': 'uuid' })).data.Items
			const productsAjuste = []
			const promises = []
			for (const p of productsToUpdate) {
				const amount = Number(p.stock) + Number(productsBandera[p.uuid])
				productsAjuste.push({
					uuid: p.uuid,
					name: p.name,
					image: p.image,
					quantity_old: Number(p.stock),
					quantity_new: Number(amount),
					price_credito: Number(p.price_credito) || 0
				})
				promises.push(productsTable.newUpdate({ stock: amount }, { uuid: p.uuid }))
			}
			const ajuste = {
				uuid: uuidv4(),
				user: {
					email: this.props.user.email,
					name: this.props.user.name,
					rol: this.props.user.rol
				},
				description: 'Ajuste de inventario por pre-cierre de ruta: ' + this.state.way.name + ' - ' + this.state.way.day,
				tweaks: productsAjuste,
				cava: this.props.cava.uuid
			}
			promises.push(inventoryTweaksTable.create(ajuste))
			await Promise.all(promises)
		}
		// Actualizacion de la ruta
		// ------------------------
		await waysTable.newUpdate(
			{
				sync: 'No',
				syncAt: null,
				precierre: true,
				datePrecierre: new Date().getTime(),
				observacionesPrecierre: this.state.observacionesPrecierre,
				efectivoPrecierre: this.state.efectivo,
				transferenciasPrecierre: this.state.transferencias,
				regadoPrecierre: this.state.regado,
				userPrecierre: {
					name: this.props.user.name,
					email: this.props.user.email
				}
			},
			{ uuid: this.props.params.uuid }
		)
		this.sendDesSync()
		this.props.navigate('/dashboard/inicio')
		setLoading(false)
	}

	// Metodos de los modales
	// ----------------------
	async getMovsByClient(client) {
		try {
			setLoading(true)
			const movsClientByWay = (
				await movsClientsByWayTable.query(
					'#phone_client = :p AND #uuid_ruta = :uuid_ruta AND #uuid_closed = :empty',
					{
						':p': client.phone,
						':uuid_ruta': client.uuid_way,
						':empty': 'EMPTY'
					},
					{
						'#phone_client': 'phone_client',
						'#uuid_ruta': 'uuid_ruta',
						'#uuid_closed': 'uuid_closed'
					}
				)
			).data.Items
			this.setState({
				movsClientByWay: movsClientByWay || [],
				clientMovs: client,
				modalMovsClientByWay: true
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('Ocurrio un error inesperado', 'danger')
		}
	}

	async getProductsOrder(uuid) {
		setLoading(true)
		const order = this.state.orders.find(orden => orden.uuid === uuid)
		const productsOrder = this.state.productsOrders.filter(pro => pro.uuid_order === uuid)
		const orderClient = {
			...order,
			products: productsOrder
		}
		this.setState({ modalOrderClient: true, orderClient })
		setLoading(false)
	}

	registerSpent = async e => {
		e.preventDefault()
		const data = new FormData(e.currentTarget)
		if (data.get('typespentinway') !== '' && data.get('amount') !== '') {
			setLoading(true)
			this.setState({ modalRegisterSpent: false })
			await movsCarterInWayTable.create({
				uuid: uuidv4(),
				amount: Number(data.get('amount')),
				date: new Date().getTime(),
				manual: 'Si',
				motivo: this.state.optionsSpents.find(t => t.val === data.get('typespentinway'))?.text,
				type: this.state.typeSpentMov ? 'Transferencia' : 'Efectivo',
				uuid_closed: 'EMPTY',
				uuid_way: this.props.params.uuid,
				asumed_cava: this.state.asumedCava
			})
			this.setState({ typeSpentMov: false, asumedCava: false })
			await this.init()
			Alert('Gasto registrado')
			setLoading(false)
		} else {
			Alert('Ingresa la información requerida (*).', 'danger', 4000)
		}
	}

	async deleteSpent(uuid) {
		setLoading(false)
		await movsCarterInWayTable.delete({ uuid })
		await this.init()
		setLoading(false)
		Alert('Movimiento eliminado')
	}

	async sendForceSync() {
		if ('devices' in this.state.cobrador && this.state.cobrador.devices.length > 0) {
			setLoading(true)
			for (const device of this.state.cobrador.devices) {
				if (!device.token) {
					continue
				}
				await sendNotification(device.token, 'Sincronización de ruta', 'El administrador ha enviado la orden de sincronizar la ruta', 'force-sync')
			}
			setLoading(false)
			this.props.navigate(-1)
			setTimeout(() => {
				Alert('Señal de forzar sincronización enviada con exito, debes esperar un momento para ver los cambio reflejados en el sistema')
			}, 500)
		}
	}

	async sendDesSync() {
		if ('devices' in this.state.cobrador && this.state.cobrador.devices.length > 0) {
			setLoading(true)
			const promises = []
			for (const device of this.state.cobrador.devices) {
				if (!device.token) {
					continue
				}
				promises.push(
					sendNotification(device.token, 'Des-sincronización de ruta', 'El administrador ha enviado la orden de des-sincronizar la ruta', 'close-way')
				)
			}
			promises.push(waysTable.newUpdate({ sync: 'Empty', precierre: false }, { uuid: this.props.params.uuid }))
			await Promise.all(promises)
			if (promises.some(p => p.status === false)) {
				setLoading(false)
				Alert('Ocurrio un error al enviar la señal de des-sincronización', 'danger')
				return
			}
			setLoading(false)
			Alert('Señal de des-sincronización enviada con exito, debes esperar un momento para ver los cambio reflejados en el sistema')
			this.props.navigate('/dashboard/inicio')
		}
	}

	// Modales
	// --------------------
	modalMovsClientByWay() {
		if (this.state.modalMovsClientByWay) {
			return (
				<Modal active={this.state.modalMovsClientByWay}>
					<div>
						<h2>Movimientos del cliente</h2>
						<div className="divider"></div>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>{this.state.clientMovs.name}</strong>
							</div>
							<div className="col-card-user">
								<strong>Teléfono: {this.state.clientMovs.phone}</strong>
							</div>
							<div className="col-card-user">
								{Number(this.state.clientMovs.debe) < 0 ? (
									<strong style={{ color: 'red' }}>Estado de cuenta: {Price(this.state.clientMovs.debe)}</strong>
								) : (
									<strong style={{ color: 'green' }}>Estado de cuenta: {Price(this.state.clientMovs.debe)}</strong>
								)}
							</div>
						</div>
						<table className="table">
							<thead>
								<tr>
									<th>Monto</th>
									<th>Tipo</th>
									<th>Metodo</th>
									<th>Registrado por</th>
									<th>Fecha</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.movsClientByWay.map(mov => (
									<tr key={mov.uuid}>
										<td>
											<strong style={{ color: mov.type !== 'debe' ? 'green' : 'red' }}>
												{mov.type !== 'debe' ? '+' : '-'}
												{Price(mov.amount)}
											</strong>
										</td>
										<td>
											<strong>{mov.type !== 'debe' ? 'Registro de abono' : 'Compra de productos'}</strong>
										</td>
										<td>
											<strong style={{ color: mov.type !== 'debe' ? 'green' : 'red' }}>{mov.type !== 'debe' ? mov.type : 'N/A'}</strong>
										</td>
										<td>{mov.place_cava ? 'Sistema' : 'Cobrador'}</td>
										<td>{getDateParse(new Date(Number(mov.date))) + ' a las ' + getHourParse(new Date(Number(mov.date)))}</td>
										<td>
											{mov.uuid_order !== null && mov.uuid_order !== undefined && mov.uuid_order !== 'null' && (
												<a
													href="#"
													className="btn-edit"
													onClick={event => {
														event.preventDefault()
														this.getProductsOrder(mov.uuid_order)
													}}>
													Ver detalles
												</a>
											)}
											{mov.photo !== null && mov.photo !== undefined && mov.photo !== 'null' && (
												<a
													href="#"
													className="btn-edit"
													onClick={event => {
														event.preventDefault()
														this.setState({ modalPhoto: true, photo: mov.photo })
													}}>
													Ver detalles
												</a>
											)}
											{mov.type !== 'debe' && (mov.photo === null || mov.photo === undefined || mov.photo === 'null') && 'N/A'}
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="divider"></div>
						<center>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalMovsClientByWay: false, movsClientByWay: [], clientMovs: {} })
								}}
								className="btn">
								Cerrar
							</a>
						</center>
					</div>
				</Modal>
			)
		}
	}

	modalOrderClient() {
		if (this.state.modalOrderClient) {
			return (
				<Modal active={this.state.modalOrderClient}>
					<div>
						<h2>Detalles de la orden</h2>
						<div className="divider"></div>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>{this.state.orderClient.name}</strong>
							</div>
							<div className="col-card-user">
								<strong>Teléfono: {this.state.orderClient.phone_client}</strong>
							</div>
							<div className="col-card-user">
								<strong>Total orden: {Price(this.state.orderClient.total)}</strong>
							</div>
						</div>
						<table className="table">
							<thead>
								<tr>
									<th>Productos</th>
									<th>Cantidad</th>
									<th>Precio</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{this.state.orderClient.products.map(pro => (
									<tr key={pro.uuid}>
										<td>
											<div className="item-pro">
												{pro.imagen !== undefined && pro.imagen !== 'undefined' ? (
													<img src={e.urlBucket + pro.imagen} alt={pro.name} />
												) : (
													<img src={require('../../img/defecto.jpg')} alt={pro.name} />
												)}
												<span>{pro.name}</span>
											</div>
										</td>
										<td>
											<strong>{pro.amount}</strong>
										</td>
										<td>
											<strong>{Price(pro.price)}</strong>
										</td>
										<td>
											<strong>{Price(Number(pro.price) * pro.amount)}</strong>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="divider"></div>
						<center>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalOrderClient: false, orderClient: {} })
								}}
								className="btn">
								Cerrar
							</a>
						</center>
					</div>
				</Modal>
			)
		}
	}

	modalComprobante() {
		return (
			<Modal active={this.state.modalPhoto}>
				<div className="mapa-modal">
					<div className="title-page">
						<div className="title">
							<h2>Comprobante de transferencia</h2>
						</div>
					</div>
					<div className="mapa">
						<img src={e.urlBucket + this.state.photo} style={{ maxHeight: '75vh' }} />
						<div className="btns-flex" style={{ paddingTop: 20 }}>
							<a
								href="#"
								className="btn"
								onClick={e => {
									e.preventDefault()
									this.setState({ modalPhoto: false })
								}}>
								Cerrar
							</a>
						</div>
					</div>
				</div>
			</Modal>
		)
	}

	modalRegisterSpent() {
		return (
			<Modal active={this.state.modalRegisterSpent}>
				<form action="#" onSubmit={this.registerSpent}>
					<div className="title-page">
						<div className="title">
							<h2>Registrar gasto de la ruta</h2>
						</div>
					</div>
					<Input type="select" title="Tipo de gasto *" name="typespentinway" options={this.state.optionsSpents} />
					<Input type="number" title="Valor del gasto *" name="amount" />
					<Input
						type="checkbox"
						title="Efectivo / Transferencia *"
						name="type"
						active={this.state.typeSpentMov}
						change={v => this.setState({ typeSpentMov: v })}
					/>
					<Input
						type="checkbox"
						title="Asumido por el cobrador / Asumido por la cava *"
						name="type"
						active={this.state.asumedCava}
						change={v => this.setState({ asumedCava: v })}
					/>
					<div className="divider"></div>
					<div className="btns-flex">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.setState({ modalRegisterSpent: false })
							}}
							className="btn">
							Cancelar
						</a>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</Modal>
		)
	}

	modalPrecierre() {
		return (
			<Modal active={this.state.modalPrecierre}>
				<div className="mapa-modal">
					<div className="title-page">
						<div className="title">
							<h2>Observaciones del pre-cierre</h2>
						</div>
					</div>
					<div className="mapa">
						<Input
							type="textarea"
							placeholder="..."
							name="observaciones_precierre"
							value={this.state.observacionesPrecierre}
							change={val => this.setState({ observacionesPrecierre: val })}
							title="Observaciones"
						/>
						<div className="btns-flex" style={{ paddingTop: 20 }}>
							<a
								href="#"
								className="btn"
								onClick={e => {
									e.preventDefault()
									this.setState({ modalPrecierre: false })
								}}>
								Cancelar
							</a>
							<a
								href="#"
								className="btn"
								onClick={e => {
									e.preventDefault()
									this.makePrecierre()
								}}>
								Hacer pre-cierre
							</a>
						</div>
					</div>
				</div>
			</Modal>
		)
	}

	render() {
		const columnsClientsByWay = [
			{
				header: 'Cliente',
				accessorKey: 'name',
				cell: ({ row }) => (
					<NavLink to={`/dashboard/clients/edit/${row.original.phone}`} className="widget-client" style={{ position: 'relative' }}>
						{row.original.status !== 'Activo' && <span className="status-bloqueado">Bloqueado</span>}
						{row.original.deleted === true && <span className="status-bloqueado">Cliente mudado</span>}
						<div className="container-avater">
							<strong>{Number(row.original.index) + 1}</strong>
						</div>
						{row.original.name}
					</NavLink>
				)
			},
			{ header: 'Teléfono', accessorKey: 'phone' },
			{
				header: 'Estado de cuenta',
				accessorKey: 'debe',
				cell: ({ row }) => (
					<strong style={{ textAlign: 'center', display: 'block', color: Number(row.original.debe) < 0 ? 'red' : 'green' }}>
						{Price(row.original.debe)}
					</strong>
				)
			},
			{
				header: 'Acciones',
				key: 'actions',
				cell: ({ row }) => (
					<a
						href="#"
						className="btn-edit"
						onClick={event => {
							event.preventDefault()
							this.getMovsByClient(row.original)
						}}>
						Ver movimientos de cliente
					</a>
				)
			}
		]
		const movsCarterInWay = [
			{
				header: 'Monto',
				accessorKey: 'amount',
				cell: ({ row }) => (
					<strong style={{ color: row.original.motivo === 'Registro de abono' ? 'green' : 'blue' }}>
						{row.original.motivo === 'Registro de abono' ? '+' : '-'}
						{Price(row.original.amount)}
					</strong>
				)
			},
			{ header: 'Tipo movimiento', accessorKey: 'motivo' },
			{ header: 'Metodo', accessorKey: 'type' },
			{ header: 'Cliente', accessorKey: 'client', cell: ({ row }) => row.original.client || 'N/A' },
			{ header: 'Asumido por la cava', accessorKey: 'place_cava', cell: ({ row }) => (row.original.asumed_cava ? 'Si' : 'No') },
			{ header: 'Fecha y hora', accessorKey: 'fecha' },
			{
				header: 'Acciones',
				key: 'actions',
				cell: ({ row }) => (
					<>
						{row.original.asumed_cava && (
							<a
								href="#"
								className="btn-delete"
								onClick={event => {
									event.preventDefault()
									Confirm('¿Realmente desea eliminar este gasto?', () => {
										this.deleteSpent(row.original.uuid)
									})
								}}>
								Eliminar
							</a>
						)}
						{row.original.photo && row.original.photo !== 'undefined' && row.original.type === 'Transferencia' && (
							<a
								href="#"
								className="btn-edit"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalPhoto: true, photo: row.original.photo })
								}}>
								Ver comprobante
							</a>
						)}
						{(!row.original.photo || row.original.photo === 'undefined') && !row.original.asumed_cava && <p>N/A</p>}
					</>
				)
			}
		]
		return (
			<>
				{this.modalMovsClientByWay()}
				{this.modalOrderClient()}
				{this.modalComprobante()}
				{this.modalRegisterSpent()}
				{this.modalPrecierre()}
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Detalles de ruta sincronizada</h2>
						<span>
							{this.state.way.name} - {this.state.way.day}
						</span>
					</div>
					<div className="btns">
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								Confirm('¿Realmente desea sincronizar esta ruta?', () => {
									this.sendForceSync()
								})
							}}
							className="btn">
							Forzar sincronización
						</a>
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								Confirm('¿Realmente desea des-sincronizar esta ruta?', () => {
									this.sendDesSync()
								})
							}}
							className="btn">
							Des-sincronizar ruta
						</a>
						{(this.props.user.rol === 'Administrador' || this.props.user.rol === 'Super') && this.state.way.precierre !== true && (
							<>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.setState({ modalPrecierre: true })
									}}
									className="btn">
									Hacer pre-cierre
								</a>
							</>
						)}
						{(this.props.user.rol === 'Administrador' || this.props.user.rol === 'Super') && this.state.way.precierre === true && (
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalCierre: true })
								}}
								className="btn">
								Hacer cierre de ruta
							</a>
						)}
					</div>
				</div>

				<div className="card-user-way">
					<div className="container-avater">
						<strong>{'name' in this.state.cobrador ? this.state.cobrador.name[0].toUpperCase() : '-'}</strong>
					</div>
					<div className="col-card-user">
						<strong>{this.state.cobrador.name}</strong>
					</div>
					<div className="col-card-user">
						<strong>
							Ultima sincronización: {'syncAt' in this.state.way ? getDateParse(getDate(this.state.way.syncAt)) : ''}{' '}
							{'syncAt' in this.state.way ? getHourParse(getDate(this.state.way.syncAt)) : ''}
						</strong>
					</div>
					<div className="col-card-user">
						<NavLink to={`/dashboard/users/edit/${this.state.cobrador.email}`} className="btn-edit">
							Ver cobrador
						</NavLink>
					</div>
				</div>
				<div className="card">
					<div className="btns-tabs">
						<a
							className={`${this.state.tab === 0 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 0 })
							}}>
							Resumen
						</a>
						<a
							className={`${this.state.tab === 1 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 1 })
							}}>
							Clientes ruta
						</a>
						<a
							className={`${this.state.tab === 2 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 2 })
							}}>
							Movimientos de dinero
						</a>
						<a
							className={`${this.state.tab === 3 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 3 })
							}}>
							Detalles del despacho
						</a>
						<a
							className={`${this.state.tab === 4 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 4 })
							}}>
							SMS enviados
						</a>
					</div>
					{/* Resumen */}
					<div className="tab" style={{ display: this.state.tab === 0 ? 'block' : 'none' }}>
						<h2 style={{ marginBottom: 10 }}>Resumen</h2>
						<table className="table" style={{ width: 550, marginLeft: 'auto', marginRight: 'auto' }}>
							<tbody>
								<tr>
									<th>AVALUO MERCANCIA DESPACHADA</th>
									<td>{Price(this.state.avaluoMercancia)}</td>
								</tr>
								<tr>
									<th>AVALUO MERCANCIA SOBRANTE</th>
									<td>{Price(this.state.avaluoMercanciaSobrante)}</td>
								</tr>
								<tr>
									<th>AVALUO MERCANCIA REGADA</th>
									<td>{Price(this.state.regado)}</td>
								</tr>
								<tr>
									<th>TOTAL RECOGIDO</th>
									<td>{Price(this.state.totalRecogido)}</td>
								</tr>
								<tr>
									<th>TOTAL GASTOS</th>
									<td>{Price(this.state.totalGastos)}</td>
								</tr>
								<tr>
									<th>DEBERIA TENER</th>
									<td>{Price(this.state.deberia)}</td>
								</tr>
								<tr>
									<th>RECOGIDO REAL</th>
									<td>
										<Input type="number" placeholder="Ingresa el dinero recogido" name="amount" change={val => this.setState({ recodigo: val })} />
									</td>
								</tr>
								<tr>
									<th>DESCUADRE</th>
									<td>{Price(this.state.recodigo - this.state.deberia)}</td>
								</tr>
								<tr>
									<th>PORCENTAJE DE DESCUADRE</th>
									<td>{Math.ceil(this.state.recodigo > 0 ? 100 - (this.state.recodigo * 100) / this.state.deberia : 100)}%</td>
								</tr>
								<tr>
									<th>PORCENTAJE COMISIÓN</th>
									<td>{this.state.cobrador.comision || 0}%</td>
								</tr>
								<tr>
									<th>TOTAL COMISIÓN</th>
									<td>{Price((Number(this.state.recodigo) * Number(this.state.cobrador.comision || 0)) / 100)}</td>
								</tr>
							</tbody>
						</table>
					</div>
					{/* Clientes de la ruta */}
					<div className="tab" style={{ display: this.state.tab === 1 ? 'block' : 'none' }}>
						<h2 style={{ marginBottom: 10 }}>Clientes ruta</h2>
						<Table columns={columnsClientsByWay} data={this.state.clients} pageSize={40} />
					</div>
					{/* Movimientos de dinero */}
					<div className="tab" style={{ display: this.state.tab === 2 ? 'block' : 'none' }}>
						<div className="title-page">
							<h2 style={{ marginBottom: 10 }}>Movimientos de dinero</h2>
							{this.props.user.rol !== 'Supervisor' && (
								<div className="btns">
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											this.setState({ modalRegisterSpent: true })
										}}
										className="btn">
										Registrar gasto
									</a>
								</div>
							)}
						</div>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>Efectivo: {Price(this.state.efectivo)}</strong>
							</div>
							<div className="col-card-user">
								<strong>Transferencias: {Price(this.state.transferencias)}</strong>
							</div>
							<div className="col-card-user">
								<strong style={{ color: 'green' }}>Total recogido: {Price(this.state.totalRecogido)}</strong>
							</div>
							<div className="col-card-user">
								<strong style={{ color: 'red' }}>Total gastos: {Price(this.state.totalGastos)}</strong>
							</div>
							<div className="col-card-user">
								<strong>Diferencia: {Price(Number(this.state.totalRecogido) - Number(this.state.totalGastos))}</strong>
							</div>
						</div>
						<Table columns={movsCarterInWay} data={this.state.movimientos} pageSize={40} />
					</div>
					{/* Despacho */}
					<div className="tab" style={{ display: this.state.tab === 3 ? 'block' : 'none' }}>
						<h2 style={{ marginBottom: 10 }}>Detalles del despacho</h2>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>Avaluo mercancia despachada: {Price(this.state.avaluoMercancia)}</strong>
							</div>
							<div className="col-card-user">
								<strong>Cantidad de productos despachados: {this.state.dispatch?.quantity_products || 0}</strong>
							</div>
						</div>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>Avaluo mercancia regada: {Price(this.state.regado)}</strong>
							</div>
							<div className="col-card-user">
								<strong>Canditad de productos regados: {this.state.cantidadProductosRegados}</strong>
							</div>
						</div>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>Avaluo mercancia donada: {Price(this.state.avaluoProductosDonados)}</strong>
							</div>
							<div className="col-card-user">
								<strong>Canditad de productos donados: {this.state.cantidadProductosDonados}</strong>
							</div>
						</div>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>Avaluo mercancia sobrante: {Price(this.state.avaluoMercanciaSobrante)}</strong>
							</div>
							<div className="col-card-user">
								<strong>Canditad de productos sobrantes: {this.state.cantidadProductosSobrantes}</strong>
							</div>
						</div>
						<h2 style={{ marginBottom: 10 }}>Productos regados</h2>
						<table className="table">
							<thead>
								<tr>
									<th>Producto</th>
									<th>Cantidad</th>
									<th>Avaluo</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(this.state.productosRegados).map((uuid, i) => (
									<tr key={i}>
										<td>
											<div className="item-pro">
												{this.state.productosRegados[uuid].imagen !== undefined && this.state.productosRegados[uuid].imagen !== 'undefined' ? (
													<img src={e.urlBucket + this.state.productosRegados[uuid].imagen} alt={this.state.productosRegados[uuid].name} />
												) : (
													<img src={require('../../img/defecto.jpg')} alt={this.state.productosRegados[uuid].name} />
												)}
												<span>{this.state.productosRegados[uuid].name}</span>
											</div>
										</td>
										<td>
											<strong>{this.state.productosRegados[uuid].amount}</strong>
										</td>
										<td>
											<strong>{Price(Number(this.state.productosRegados[uuid].amount) * Number(this.state.productosRegados[uuid].price))}</strong>
										</td>
										<td>
											<a
												href="#"
												className="btn-delete"
												onClick={event => {
													event.preventDefault()
													this.getProductsOrder(this.state.productosRegados[uuid].uuid_order)
												}}>
												Ver orden
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{this.state.donations.length > 0 && (
							<>
								<h2 style={{ marginBottom: 10 }}>Productos donados</h2>
								<table className="table">
									<thead>
										<tr>
											<th>Producto</th>
											<th>Cantidad</th>
											<th>Avaluo</th>
											<th>Fecha y hora</th>
										</tr>
									</thead>
									<tbody>
										{this.state.donations.map(donation => (
											<tr key={donation.uuid}>
												<td>
													<NavLink className="item-pro">
														{donation.imagen !== undefined ? (
															<img src={e.urlBucket + donation.imagen} alt={donation.name} />
														) : (
															<img src={require('../../img/defecto.jpg')} alt={donation.name} />
														)}
														<span>{donation.name}</span>
													</NavLink>
												</td>
												<td>
													<strong>{donation.amount}</strong>
												</td>
												<td>
													<strong>{Price(Number(donation.amount) * Number(donation.price_credito))}</strong>
												</td>
												<td>{donation.fecha}</td>
											</tr>
										))}
									</tbody>
								</table>
							</>
						)}
						{this.state.productosSobrantes.length > 0 && (
							<>
								<h2 style={{ marginBottom: 20, marginTop: 30 }}>Productos sobrantes</h2>
								<table className="table">
									<thead>
										<tr>
											<th>Producto</th>
											<th>Cantidad</th>
											<th>Avaluo</th>
										</tr>
									</thead>
									<tbody>
										{this.state.productosSobrantes.map((producto, i) => (
											<tr key={i}>
												<td>
													<div className="item-pro">
														{producto.image !== undefined ? (
															<img src={e.urlBucket + producto.image} alt={producto.name} />
														) : (
															<img src={require('../../img/defecto.jpg')} alt={producto.name} />
														)}
														<span>{producto.name}</span>
													</div>
												</td>
												<td>
													<strong>{producto.quantity}</strong>
												</td>
												<td>
													<strong>{Price(Number(producto.quantity) * Number(producto.price_credito))}</strong>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</>
						)}
						{this.state.way.dispatch !== '' && (
							<NavLink to={`/dashboard/dispatchthecollectors/show/${this.state.way.dispatch}`} className="btn">
								Ver despacho de ruta
							</NavLink>
						)}
					</div>
					{/* SMS enviados */}
					<div className="tab" style={{ display: this.state.tab === 4 ? 'block' : 'none' }}>
						<h2 style={{ marginBottom: 10 }}>SMS enviados</h2>
						<div className="card-user-way">
							<div className="col-card-user">
								<strong>Cantidad de SMS enviados: {this.state.smsEnviados.length}</strong>
							</div>
						</div>
						<table className="table">
							<thead>
								<tr>
									<th>Telefono</th>
									<th>SMS</th>
									<th>Fecha</th>
								</tr>
							</thead>
							<tbody>
								{this.state.smsEnviados.map(smsEn => (
									<tr key={smsEn.uuid}>
										<td>{smsEn.phone}</td>
										<td>{smsEn.sms}</td>
										<td>{hoy(smsEn.time, true)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(withParams(SyncWay))

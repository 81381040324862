import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen.jsx'
import withParams from 'componentes/WithParams'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage, deleteImage } from 'utiles/functions.js'
import DB from 'utiles/db.js'
const bcrypt = require('bcryptjs')
const users = new DB('users')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			date: props.user.date,
			email: props.user.email,
			name: props.user.name,
			phone: props.user.phone,
			avatar: null
		}
	}

	handleSubmit = async event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('phone') !== '' && data.get('date') !== '') {
			let expresion = 'SET #n = :name, #p = :phone, #d = :date'
			const values = {
				':name': data.get('name'),
				':phone': data.get('phone'),
				':date': data.get('date')
			}
			const attributes = {
				'#n': 'name',
				'#p': 'phone',
				'#d': 'date'
			}

			let valid = false
			if (data.get('password') !== '') {
				if (data.get('password').length > 6) {
					if (data.get('password') === data.get('password_confirm')) {
						const hash = bcrypt.hashSync(data.get('password'), 8)
						values[':password'] = hash
						attributes['#pas'] = 'password'
						expresion += ', #pas = :password'
						valid = true
					} else {
						Alert('Las contraseñas no coinciden.', 'danger', 7000)
					}
				} else {
					Alert('La contraseña es demasiado corta.', 'danger', 7000)
				}
			} else {
				valid = true
			}
			if (valid) {
				this.setState({ loading: true }, async () => {
					const user = this.props.user
					if (this.state.avatar !== null) {
						const url = await uploadImage(this.state.avatar)
						attributes['#a'] = 'avatar'
						values[':avatar'] = url
						expresion += ', #a = :avatar'
						if (user.avatar !== null && user.avatar !== undefined) {
							await deleteImage(user.avatar)
						}
						user.avatar = url
					}
					await users.update({
						Key: { email: this.props.user.email },
						UpdateExpression: expresion,
						ExpressionAttributeValues: values,
						ExpressionAttributeNames: attributes,
						ReturnValues: 'UPDATED_NEW'
					})
					user.date = data.get('date')
					user.name = data.get('name')
					user.phone = data.get('phone')
					this.props.setUser(user)
					localStorage.setItem('@user_cava', JSON.stringify(user))
					this.setState({ loading: false })
					Alert('Usuario actualizado correctamente.')
				})
			}
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<h2>Perfil</h2>
						<span>Editar mi perfil</span>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="image" name="avatar" img={this.props.user.avatar} title="Avatar" change={data => this.setState({ avatar: data })} />
							<Input type="text" title="Nombre del usuarios" placeholder="Ingresa el nombre" value={this.state.name} name="name" />
							<Input
								type="number"
								title="Telefono *"
								placeholder="Ingresa el telefono"
								value={this.state.phone}
								change={val => this.setState({ phone: val })}
								name="phone"
							/>
						</div>
						<div value={this.state.column} className="column">
							<Input type="date" title="Fecha de nacimiento" placeholder="Ingresa la fecha de nacimiento" value={this.state.date} name="date" />
							<Input
								type="email"
								disabled={true}
								title="Correo electrónico *"
								placeholder="Ingresa el Correo electrónico"
								value={this.state.email}
								name="email"
							/>
							<Input type="password" title="Contraseña *" placeholder="Ingresa la contraseña" value={this.state.password} name="password" />
							<Input
								type="password"
								title="Confirmar contraseña *"
								placeholder="Confirma la contraseña"
								value={this.state.password_confirm}
								name="password_confirm"
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn m-auto" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(AnimateScreen(Page))

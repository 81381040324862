import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import withParams from 'componentes/WithParams'
import Modal from 'componentes/modal'
import Mapa from 'componentes/mapa'
import Back from 'componentes/back'
import Input from 'componentes/input'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Alert, Confirm, Price } from 'utiles/functions'
import { setLoading } from 'store'
import DB from 'utiles/db'
const waysTable = new DB('ways')
const usersTable = new DB('users')
const clientsByWaysTable = new DB('clientsbyways')
const clientsTable = new DB('clients')

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	padding: 8 * 2,
	margin: `0 0 ${8}px 0`,
	borderRadius: 4,
	borderWidth: 1,
	borderColor: '#eaeaea',
	borderStyle: 'solid',
	position: 'relative',
	background: isDragging ? '#e69dfd' : '#fff',
	...draggableStyle
})
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cobradores: [],
			name: '',
			day: '',
			cobrador: '',
			status: false,
			clientes: [],
			changeOrder: false,
			modalMapa: false,
			clienteMapa: {},
			deletes: [],
			// widgets
			regado: 0,
			recogido: 0,
			mudado: 0,
			new_clients: 0,
			block_clients: 0,
			clients: 0
		}
	}

	componentDidMount() {
		this.init()
	}

	init = async () => {
		setLoading(true)
		try {
			const way = (await waysTable.get({ uuid: this.props.params.uuid })).data.Item
			const cobradores = (
				await usersTable.query(
					'#cava = :c AND #rol = :rol AND #status = :status',
					{ ':c': this.props.cava.uuid, ':rol': 'Cobrador', ':status': 'Activo' },
					{ '#cava': 'cava', '#rol': 'rol', '#status': 'status' }
				)
			).data.Items
			const clientes = (
				await clientsByWaysTable.query(
					'#uuid_way = :u AND #deleted <> :d',
					{ ':u': this.props.params.uuid, ':d': true },
					{ '#uuid_way': 'uuid_way', '#deleted': 'deleted' }
				)
			).data.Items
			// Verificar el orden de los clientes y actualizarlo en caso de que esten mal
			let clientesOrder = clientes.filter(c => c.deleted !== true) // deep copy
			clientesOrder = clientesOrder.sort((a, b) => Number(a.index) - Number(b.index))
			const toUpdateOrder = clientesOrder.some((c, i) => Number(c.index) !== i)
			if (toUpdateOrder) {
				// Si los index de los clientes no coinciden con el orden
				// Actualizar el orden de los clientes
				console.log('Orden de los clientes incorrecto')
				const newClientesOrder = JSON.parse(JSON.stringify(clientesOrder)) // deep copy
				clientesOrder = newClientesOrder
					.map((c, i) => {
						c.index = i
						return c
					})
					.sort((a, b) => Number(a.index) - Number(b.index))
			}
			if (toUpdateOrder) {
				// Si se debe actualizar el orden de los clientes
				await this.updateOrder(clientesOrder)
			}
			this.setState({
				name: way.name,
				day: way.day,
				deletes: way.deletes || [],
				regado: 0, // Pendiente por calcular
				recogido: 0, // Pendiente por calcular
				mudado: way.mudado || 0,
				new_clients: 0, // Pendiente por calcular clientes nuevos
				clientes: clientesOrder,
				block_clients: clientesOrder.filter(c => c.status !== 'Activo').length,
				cobrador: way.cobrador,
				status: way.status,
				cobradores: cobradores.map(co => {
					return { val: co.email, text: co.name }
				})
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	handleSubmit = async event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('day') !== '' && data.get('cobrador') !== '' && data.get('cobrador') !== null) {
			setLoading(true)
			try {
				const result = await waysTable.newUpdate(
					{
						name: data.get('name'),
						day: data.get('day'),
						cobrador: data.get('cobrador'),
						status: this.state.status
					},
					{
						uuid: this.props.params.uuid
					}
				)
				if (result.status === false) {
					console.error(result)
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					return
				}
				if (this.state.changeOrder) {
					const resultUpdateOrder = await this.updateOrder(this.state.clientes)
					if (resultUpdateOrder === false) {
						console.log('Ocurrió un error en la actualización del orden de los clientes: ', resultUpdateOrder)
					}
				}
				this.props.navigate('/dashboard/ways')
				Alert('Ruta actualizada correctamente.')
			} catch (error) {
				console.error(error)
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
			}
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	async updateOrder(clientesArr) {
		const pr = []
		for (const cliente of clientesArr) {
			pr.push(
				clientsByWaysTable.update({
					Key: { uuid: cliente.uuid },
					UpdateExpression: 'SET #index = :i',
					ExpressionAttributeValues: { ':i': cliente.index },
					ExpressionAttributeNames: { '#index': 'index' }
				})
			)
		}
		const results = await Promise.all(pr)
		return results.every(r => r.status === true)
	}

	async deleteClientWay(item) {
		try {
			setLoading(true)
			const deletes = this.state.deletes
			let mudado = this.state.mudado || 0
			mudado += Number(item.debe || 0)
			deletes.push({
				uuid: item.uuid,
				phone: item.phone
			})
			const cliente = (await clientsTable.get({ phone: item.phone })).data.Item // Consulto el cliente
			const clientways = cliente.ways.filter(w => w.uuid !== this.props.params.uuid) // Al cliente le quitamos la ruta actual
			await clientsTable.newUpdate({ ways: clientways }, { phone: item.phone }) // Actualizamos el cliente para que no quede asociado a la ruta actual
			await waysTable.newUpdate({ deletes, mudado }, { uuid: this.props.params.uuid }) // Actualizamos la ruta con los clientes eliminados y el monto mudado
			await clientsByWaysTable.newUpdate({ deleted: true }, { uuid: item.uuid }) // ponemos la bandera de deleted en true
			this.init()
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	// Orden de los clientes
	onDragEnd(result, clientes) {
		if (!result.destination) {
			return
		}
		let items = reorder(clientes, result.source.index, result.destination.index)
		items = items.map((item, i) => {
			item.index = i
			return item
		})
		this.setState({ clientes: items, changeOrder: true })
	}

	modalMapa() {
		if (this.state.clienteMapa.name) {
			return (
				<Modal active={this.state.modalMapa}>
					<div className="mapa-modal">
						<div className="title-page">
							<div className="title">
								<h2>Ubicación del cliente en el mapa</h2>
							</div>
						</div>
						<div className="item-inline">
							<strong>Cliente:</strong>
							<span>{this.state.clienteMapa.name}</span>
						</div>
						<div className="mapa">
							<Mapa
								center={{ lat: Number(this.state.clienteMapa.lat || 0), lng: Number(this.state.clienteMapa.log || 0) }}
								googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDjfUItorM8PVBzBBmE5Stx9wLyuf0_310&v=3.exp&libraries=places"
								loadingElement={<div style={{ height: `100%` }} />}
								containerElement={<div style={{ height: `70vh` }} />}
								mapElement={<div style={{ height: `100%` }} />}
								markers={[{ lat: Number(this.state.clienteMapa.lat || 0), log: Number(this.state.clienteMapa.log || 0) }]}
								click={c => console.log(c)}
							/>
							<div className="btns-flex" style={{ paddingTop: 20 }}>
								<a
									href={'http://www.google.com/maps/place/' + this.state.clienteMapa.lat + ',' + this.state.clienteMapa.log}
									target="_blank"
									className="btn"
									rel="noreferrer">
									Ver en Google Maps
								</a>
								<a
									href="#"
									className="btn"
									onClick={e => {
										e.preventDefault()
										this.setState({ modalMapa: false, clienteMapa: {} })
									}}>
									Cerrar
								</a>
							</div>
						</div>
					</div>
				</Modal>
			)
		}
	}

	render() {
		return (
			<>
				{this.modalMapa()}
				<form action="#" onSubmit={this.handleSubmit}>
					<div className="title-page">
						<div className="title">
							<Back />
							<h2>Rutas</h2>
							<span>{this.state.name}</span>
						</div>
						<div className="btns">
							<button className="btn" type="submit">
								Guardar
							</button>
						</div>
					</div>
					<div className="grid g5" style={{ marginBottom: 20 }}>
						<div className="card">
							<div className="item-widget">
								<span>Regado</span>
								<strong>{Price(this.state.regado)}</strong>
							</div>
						</div>
						<div className="card">
							<div className="item-widget">
								<span>Recogido</span>
								<strong>{Price(this.state.recogido)}</strong>
							</div>
						</div>
						<div className="card">
							<div className="item-widget">
								<span>Mudado</span>
								<strong>{Price(this.state.mudado)}</strong>
							</div>
						</div>
						<div className="card">
							<div className="item-widget">
								<span>Clientes nuevos</span>
								<strong>{this.state.new_clients}</strong>
							</div>
						</div>
						<div className="card">
							<div className="item-widget">
								<span>Clientes bloqueados</span>
								<strong>{this.state.block_clients}</strong>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="columns">
							<div className="column">
								<Input type="text" title="Nombre de la ruta *" placeholder="Ingresa el nombre" value={this.state.name} name="name" />
								<Input
									type="select"
									name="cobrador"
									title="Cobrador *"
									change={val => this.setState({ cobrador: val })}
									value={this.state.cobrador}
									options={this.state.cobradores}
								/>
							</div>
							<div className="column">
								<Input
									type="select"
									value={this.state.day}
									name="day"
									title="Dia de la ruta *"
									change={val => this.setState({ day: val })}
									options={[
										{ val: 'Lunes', text: 'Lunes' },
										{ val: 'Martes', text: 'Martes' },
										{ val: 'Miércoles', text: 'Miercoles' },
										{ val: 'Jueves', text: 'Jueves' },
										{ val: 'Viernes', text: 'Viernes' },
										{ val: 'Sabado', text: 'Sabado' },
										{ val: 'Domingo', text: 'Domingo' }
									]}
								/>
								<Input
									type="checkbox"
									id="status"
									title="Estado de la ruta (Inactivo / Activo)"
									active={this.state.status}
									change={val => {
										this.setState({ status: val })
									}}
								/>
							</div>
						</div>
						<div className="divider"></div>
						{this.state.clientes.length && (
							<NavLink className="btn" to={'/dashboard/ways/map/' + this.props.params.uuid}>
								Ver clientes en el mapa
							</NavLink>
						)}
						<div className="divider"></div>
						{this.state.clientes.length > 0 && (
							<DragDropContext onDragEnd={r => this.onDragEnd(r, this.state.clientes)}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{this.state.clientes.map((item, index) => (
												<Draggable key={item.uuid} draggableId={item.uuid} index={index}>
													{(provided, snapshot) => (
														<div
															className="item-drag drag-clientes"
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
															{item.status === 'Inactivo' && (
																<span style={{ top: 5, left: 5 }} className="status-bloqueado">
																	Bloqueado
																</span>
															)}
															<div className="container-dragger">
																<span></span>
																<span></span>
																<span></span>
															</div>
															<span className="bola-negra">{Number(item.index) + 1}</span>
															<div className="info-drag">
																<div className="text">
																	<div>
																		<strong>Nombre: </strong>
																		<p>{item.name}</p>
																	</div>
																	<div>
																		<strong>Teléfono: </strong>
																		<p>{item.phone}</p>
																	</div>
																	<div>
																		<strong>Estado de cuenta: </strong>
																		<p className={item.debe < 0 ? 'color-danger' : ''}>{Price(item.debe)}</p>
																	</div>
																</div>
																<div className="actions">
																	<a
																		href="#"
																		className="btn"
																		onClick={e => {
																			e.preventDefault()
																			this.setState({ clienteMapa: item, modalMapa: true })
																		}}>
																		Ver ubiación
																	</a>
																	<a
																		href="#"
																		className="btn"
																		onClick={e => {
																			e.preventDefault()
																			this.props.navigate('/dashboard/clients/edit/' + item.phone)
																		}}>
																		Ver cliente
																	</a>
																	<a
																		href="#"
																		className="btn"
																		onClick={e => {
																			e.preventDefault()
																			this.props.navigate(`/dashboard/clients/history/${item.phone}/${this.props.params.uuid}`)
																		}}>
																		Ver movimientos
																	</a>
																	<a
																		href="#"
																		className="btn"
																		onClick={e => {
																			e.preventDefault()
																			Confirm('¿Realmente desea eliminar este cliente de la ruta?', () => this.deleteClientWay(item))
																		}}>
																		Eliminar de la ruta
																	</a>
																</div>
															</div>
														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						)}
						<div className="divider"></div>
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</form>
			</>
		)
	}
}
export default withParams(Page)

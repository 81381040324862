import { decode } from 'base64-arraybuffer'
import { S3 } from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'
import e from './settings.js'
import credentials from './credentials.js'
const url = 'https://fcm.googleapis.com/fcm/send'

export const Alert = (msg, type = 'success', duration = 7000) => {
	const p = document.createElement('p')
	const btn = document.createElement('a')
	const div = document.createElement('div')
	p.innerHTML = msg
	btn.setAttribute('href', '#')
	btn.setAttribute('class', 'icon-close')
	btn.innerHTML = 'x'
	btn.addEventListener('click', function (e) {
		this.parentNode.remove()
	})
	div.setAttribute('class', type)
	div.appendChild(p)
	div.appendChild(btn)
	document.getElementById('notifications').appendChild(div)
	setTimeout(() => {
		div.animate([{ opacity: 1 }, { opacity: 0 }], { duration: 300 })
		setTimeout(() => div.remove(), 250)
	}, duration)
}

export const Price = (value, currency = 'COP') => {
	const price = Number(value)
	return new Intl.NumberFormat('es-ES', {
		style: 'currency',
		currency,
		maximumFractionDigits: 0,
		maximumSignificantDigits: 10
	}).format(price)
}

export const Levenshtein = (a, b) => {
	function _min(d0, d1, d2, bx, ay) {
		return d0 < d1 || d2 < d1 ? (d0 > d2 ? d2 + 1 : d0 + 1) : bx === ay ? d1 : d1 + 1
	}
	if (a === b) {
		return 0
	}

	if (a.length > b.length) {
		const tmp = a
		a = b
		b = tmp
	}
	let la = a.length
	let lb = b.length
	while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
		la--
		lb--
	}
	let offset = 0
	while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
		offset++
	}
	la -= offset
	lb -= offset
	if (la === 0 || lb < 3) {
		return lb
	}
	let x = 0
	let y
	let d0
	let d1
	let d2
	let d3
	let dd
	let dy
	let ay
	let bx0
	let bx1
	let bx2
	let bx3
	const vector = []
	for (y = 0; y < la; y++) {
		vector.push(y + 1)
		vector.push(a.charCodeAt(offset + y))
	}
	const len = vector.length - 1
	for (; x < lb - 3; ) {
		bx0 = b.charCodeAt(offset + (d0 = x))
		bx1 = b.charCodeAt(offset + (d1 = x + 1))
		bx2 = b.charCodeAt(offset + (d2 = x + 2))
		bx3 = b.charCodeAt(offset + (d3 = x + 3))
		dd = x += 4
		for (y = 0; y < len; y += 2) {
			dy = vector[y]
			ay = vector[y + 1]
			d0 = _min(dy, d0, d1, bx0, ay)
			d1 = _min(d0, d1, d2, bx1, ay)
			d2 = _min(d1, d2, d3, bx2, ay)
			dd = _min(d2, d3, dd, bx3, ay)
			vector[y] = dd
			d3 = d2
			d2 = d1
			d1 = d0
			d0 = dy
		}
	}
	for (; x < lb; ) {
		bx0 = b.charCodeAt(offset + (d0 = x))
		dd = ++x
		for (y = 0; y < len; y += 2) {
			dy = vector[y]
			vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1])
			d0 = dy
		}
	}
	return dd
}

export const uploadImage = (image, prefix = '') => {
	return new Promise((resolve, reject) => {
		const fileName = prefix + uuidv4() + '.jpg'
		const contentDeposition = 'inline;filename="' + fileName + '"'
		const arrayBuffer = decode(image.split('base64,')[1])
		const s3bucket = new S3({
			region: e.region,
			accessKeyId: e.accessKeyId,
			secretAccessKey: e.secretAccessKey,
			Bucket: e.bucket,
			signatureVersion: 'v4'
		})
		s3bucket.createBucket(() => {
			const params = {
				Bucket: e.bucket,
				Key: fileName,
				Body: arrayBuffer,
				ContentDisposition: contentDeposition,
				ContentType: 'image/jpeg'
			}
			s3bucket.upload(params, (err, data) => {
				if (err) {
					reject(err)
				}
				resolve(fileName)
			})
		})
	})
}

export const deleteImage = image => {
	return new Promise((resolve, reject) => {
		const s3bucket = new S3({
			region: e.region,
			accessKeyId: e.accessKeyId,
			secretAccessKey: e.secretAccessKey,
			Bucket: e.bucket,
			signatureVersion: 'v4'
		})
		s3bucket.deleteObject({ Bucket: e.bucket, Key: image }, (err, data) => {
			if (err) {
				reject(err)
			}
			resolve(data)
		})
	})
}

export const hoy = (time = null, withHour = false) => {
	const h = time === null ? new Date() : new Date(Number(time) + 1300000000000)
	const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
	const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
	const getHours = () => {
		const h24 = Number(h.getHours())
		let pm = false
		let newH = 0
		if (h24 > 12) {
			newH = h24 - 12
			pm = true
		} else {
			newH = h24
		}
		newH = newH < 10 ? '0' + newH : newH

		let minutes = Number(h.getMinutes())
		minutes = minutes < 10 ? '0' + minutes : minutes

		return ' a las ' + newH + ':' + minutes + (pm ? ' pm' : ' am')
	}
	return dias[h.getDay()] + ' ' + h.getDate() + ' de ' + meses[h.getMonth()] + (withHour ? getHours() : '')
}

export const getDate = time => {
	time = Number(time) + 1300000000000
	return new Date(time)
}
export const getDateParse = date => {
	const nombresMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
	const fecha = `${date.getDate()}  ${nombresMeses[date.getMonth()]} - ${date.getFullYear()}`
	return fecha
}
export const getHourParse = date => {
	let hora = date.getHours()
	let minutos = date.getMinutes()
	const sufijo = hora >= 12 ? 'p.m.' : 'a.m.'
	minutos = minutos < 10 ? '0' + minutos : minutos
	hora = hora % 12 || 12

	return `${Number(hora) < 10 ? '0' + hora : hora}:${minutos} ${sufijo}`
}

export const Confirm = (msg, callback, callback2 = null, no = 'Cancelar', si = 'Aceptar') => {
	const p = document.createElement('p')
	const btn = document.createElement('a')
	const btn2 = document.createElement('a')
	const padre = document.createElement('div')
	const box = document.createElement('div')
	const botones = document.createElement('div')
	const confirmacion = document.createElement('div')
	const label = document.createElement('label')
	const input = document.createElement('input')
	input.setAttribute('id', 'inputConfirm')
	input.setAttribute('placeholder', 'confirmar')
	p.setAttribute('class', 'msg-confirm')
	confirmacion.setAttribute('class', 'input-group')
	p.innerHTML = msg
	label.innerHTML = "Por favor escribe la palabra <b style='padding: 0 10px;'> confirmar </b> para ejecutar esta acción"

	btn.innerHTML = si
	btn.setAttribute('href', '#')
	btn.addEventListener('click', function (e) {
		e.preventDefault()
		if (input.value === 'confirmar') {
			this.parentNode.parentNode.parentNode.remove()
			callback()
		} else {
			Alert('Debes de escribir Confirmar', 'danger')
		}
	})

	// boton de cancelar
	btn2.innerHTML = no
	btn2.setAttribute('href', '#')
	btn2.addEventListener('click', function (e) {
		this.parentNode.parentNode.parentNode.remove()
		if (callback2 !== null) {
			callback2()
		}
	})

	// div de botones
	botones.setAttribute('class', 'botones-confirm')
	botones.appendChild(btn2)
	botones.appendChild(btn)

	// confirmacion
	confirmacion.appendChild(label)
	confirmacion.appendChild(input)

	// agregar botones y texto
	box.setAttribute('class', 'body-modal')
	box.appendChild(p)
	box.appendChild(confirmacion)
	box.appendChild(botones)

	padre.setAttribute('class', 'modal-app')
	padre.appendChild(box)

	document.getElementsByTagName('body')[0].appendChild(padre)
}

export const sendSMS = (phones, message, type = 'TRNASACTIONAL') => {
	return new Promise((resolve, reject) => {
		fetch(e.urlSMS, {
			method: 'POST',
			headers: { Authorization: 'Bearer ' + e.smsKey },
			body: JSON.stringify({
				phones,
				message,
				type
			})
		})
			.then(d => d.json())
			.then(data => {
				resolve(data)
			})
			.catch(error => {
				reject(error)
			})
	})
}

export const sendNotification = async (to, title, body, type = null) => {
	try {
		const notify = {
			to,
			notification: { title, body }
		}
		if (type !== null) {
			notify.data = {
				type
			}
		}
		const resutl = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', Authorization: 'key=' + credentials.fcm },
			body: JSON.stringify(notify)
		})
		const data = await resutl.json()
		return { status: true, data }
	} catch (error) {
		console.error(error)
		return { status: false, data: error }
	}
}

import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import AnimateScreen from 'componentes/AnimateScreen'
import Mapa from 'componentes/mapa'
import { Alert } from 'utiles/functions'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
const clients = new DB('clients')
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			client: null,
			cliente: [],
			lat: 0,
			lng: 0
		}
	}

	componentDidMount() {
		clients.get({ phone: this.props.params.phone }).then(data => {
			const cliente = this.state.cliente
			cliente.push(data.data.Item)
			this.setState({ cliente, lat: data.data.Item.lat, lng: data.data.Item.log, loading: false })
		})
	}

	handleMarkerDragEnd = e => {
		const { latLng } = e
		const lat = latLng.lat()
		const lng = latLng.lng()
		const cliente = this.state.cliente
		cliente[0].lat = lat
		cliente[0].log = lng
	}

	updateLocation() {
		this.setState({ loading: true }, async () => {
			const cliente = this.state.cliente
			const dataCliente = {
				lat: cliente[0].lat,
				log: cliente[0].log
			}
			await clients.newUpdate(dataCliente, { phone: this.props.params.phone })
			this.setState({ loading: false }, () => {
				Alert('Ubicación actualizada')
				this.props.navigate('dashboard/clients')
			})
		})
	}

	render() {
		return (
			<>
				<div>
					<Loader active={this.state.loading} />
					<div className="container-mapa">
						<NavLink to="/dashboard/ways" className="back">
							<img src={require('../../img/back.svg').default} width={25} height={25} />
						</NavLink>
						<div className="btns">
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.updateLocation()
								}}
								style={{ marginRight: 7 }}
								className="btn btn_update_location">
								Actualizar ubicación
							</a>
						</div>
						<Mapa
							center={{ lat: parseFloat(this.state.lat), lng: parseFloat(this.state.lng) }}
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDjfUItorM8PVBzBBmE5Stx9wLyuf0_310&v=3.exp&libraries=places"
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `88vh` }} />}
							mapElement={<div style={{ height: `100%` }} />}
							marker={this.state.cliente}
							onMarkerDragEnd={this.handleMarkerDragEnd}
						/>
					</div>
				</div>
			</>
		)
	}
}

export default withParams(AnimateScreen(Page))

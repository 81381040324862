import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { Price, Levenshtein, Alert, sendNotification } from 'utiles/functions'
import Input from 'componentes/input'
import { NavLink } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db'
import e from 'utiles/settings'
import { setLoading } from 'store'
const productsTable = new DB('products')
const dispatchthecollectorsTable = new DB('dispatchthecollectors')
const waysTable = new DB('ways')
const usersTable = new DB('users')

class CreateDispatch extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cobradores: [],
			rutas: [],
			productos: [],
			hiddenProducts: [], // para el buscardor
			// Widgets
			cantidad: 0,
			avaluoCredito: 0,
			avaluoCostoOperacion: 0,
			// Datos del despacho
			uuid_way: '',
			productsToDispatch: {}
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			setLoading(true)
			// Consultamos cobradores activos
			const cobradoresResult = await usersTable.query(
				'#cava = :cava AND #rol = :rol AND #status = :status',
				{ ':cava': this.props.cava.uuid, ':rol': 'Cobrador', ':status': 'Activo' },
				{ '#cava': 'cava', '#rol': 'rol', '#status': 'status' }
			)
			// Consultamos rutas activas
			const waysResult = await waysTable.query(
				'#cava = :cava AND #status = :status',
				{ ':cava': this.props.cava.uuid, ':status': true },
				{ '#cava': 'cava', '#status': 'status' }
			)
			// Consultamos productos activos
			const productsResult = await productsTable.query(
				'#cava = :cava AND #status = :status',
				{ ':cava': this.props.cava.uuid, ':status': true },
				{ '#cava': 'cava', '#status': 'status' }
			)
			if (cobradoresResult.status === false || waysResult.status === false || productsResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			// Armamos el objeto de cobradores
			const cobradores = {}
			for (const user of cobradoresResult.data.Items) {
				cobradores[user.email] = user.name
			}
			// Armamos el array de rutas para el select
			const rutas = [{ val: '', text: 'Selecciona una ruta..' }]
			for (const way of waysResult.data.Items) {
				if (way.dispatch) {
					continue
				}
				rutas.push({
					val: way.uuid,
					text: `${way.name} - ${way.day} - ${cobradores[way.cobrador] || 'N/A'}`,
					name: way.name,
					day: way.day,
					user: way.cobrador
				})
			}
			// Armamos el array de productos para la seleccion del despacho
			const productos = []
			for (const product of productsResult.data.Items) {
				productos.push({
					uuid: product.uuid,
					cantidad: 0,
					stock: product.stock,
					cambio: false,
					image: product.image,
					name: product.name,
					precioCredito: product.price_credito,
					precioContado: product.price_contado,
					precioOperacion: product.price_operation
				})
			}
			this.setState({ cobradores, rutas, productos, hiddenProducts: productos })
			setLoading(false)
		} catch (error) {
			console.log(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	calcularWidgets(productsToDispatch) {
		let cantidad = 0
		let avaluoCredito = 0
		let avaluoCostoOperacion = 0
		for (const uuid in productsToDispatch) {
			const product = productsToDispatch[uuid]
			cantidad += product.cantidad
			avaluoCredito += Number(product.precioCredito) * product.cantidad
			avaluoCostoOperacion += Number(product.precioOperacion) * product.cantidad
		}
		this.setState({
			cantidad,
			avaluoCredito,
			avaluoCostoOperacion
		})
	}

	changeAmount(uuid, type = 'set', amount = 1) {
		const productsToDispatch = JSON.parse(JSON.stringify(this.state.productsToDispatch))
		const product = this.state.hiddenProducts.find(p => p.uuid === uuid)
		if (!productsToDispatch[uuid]) {
			// Si no existe el producto en el objeto de productos a despachar, lo creamos
			productsToDispatch[uuid] = {
				cantidad: 0,
				precioCredito: product.precioCredito,
				precioOperacion: product.precioOperacion
			}
		}
		if (type === 'add' && Number(product.stock) > productsToDispatch[uuid].cantidad) {
			productsToDispatch[uuid].cantidad++
		} else if (type === 'subtract' && productsToDispatch[uuid].cantidad > 0) {
			productsToDispatch[uuid].cantidad--
		} else if (type === 'set' && Number(product.stock) >= amount && amount >= 0) {
			productsToDispatch[uuid].cantidad = Number(amount)
		}
		if (productsToDispatch[uuid].cantidad <= 0) {
			// Si no hay cantidad, eliminamos el producto del objeto del despacho
			delete productsToDispatch[uuid]
		}
		this.calcularWidgets(productsToDispatch)
		this.setState({ productsToDispatch })
	}

	async generateDispatch() {
		try {
			setLoading(true)
			if (this.state.uuid_way === '') {
				setLoading(false)
				Alert('Selecciona una ruta', 'danger')
				return
			}
			if (Object.keys(this.state.productsToDispatch).length === 0) {
				setLoading(false)
				Alert('Selecciona al menos un producto', 'danger')
				return
			}
			// Obtenemos la ruta seleccionada
			const selectedWay = this.state.rutas.find(r => r.val === this.state.uuid_way)
			const way = {
				uuid: selectedWay.val,
				name: selectedWay.name,
				day: selectedWay.day,
				user: selectedWay.user
			}
			// Construimos el array de productos a despachar
			const uuidsProducts = Object.keys(this.state.productsToDispatch)
			const selectedProducts = this.state.hiddenProducts.filter(p => uuidsProducts.includes(p.uuid))
			const products = []
			for (const product of selectedProducts) {
				products.push({
					uuid: product.uuid,
					name: product.name,
					image: product.image,
					disponibles: Number(product.stock),
					quantity: Number(this.state.productsToDispatch[product.uuid].cantidad),
					price_credito: Number(product.precioCredito)
				})
			}
			// Usuario que genera el despacho
			const user = {
				email: this.props.user.email,
				name: this.props.user.name
			}
			// Obtener los tokens del los dispositivos asociasdos al cobrador
			const cobradorResult = await usersTable.get({ email: way.user })
			if (cobradorResult.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			const tokens = []
			if (cobradorResult.data.Item.devices) {
				for (const device of cobradorResult.data.Item.devices) {
					if (device.token) {
						tokens.push(device.token)
					}
				}
			}
			// Registrar el despacho en la base de datos
			const resultDispatch = await dispatchthecollectorsTable.create({
				uuid: uuidv4(),
				way,
				user,
				products,
				cava: this.props.cava.uuid,
				status: 'sin validar',
				price_credito: Number(this.state.avaluoCredito),
				price_cost_operation: Number(this.state.avaluoCostoOperacion),
				quantity_products: Number(this.state.cantidad)
			})
			if (resultDispatch.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				return
			}
			// Enviar notificaciones a todos los dispositivos del cobrador
			const promises = []
			for (const token of tokens) {
				promises.push(
					sendNotification(
						token,
						'¡Nuevo despacho!',
						`El usuario ${user.name} ha generado un nuevo despacho para la ruta ${way.name} - ${way.day}`,
						'despacho'
					)
				)
			}
			const resultNotifications = await Promise.all(promises)
			if (resultNotifications.some(r => r.status === false)) {
				setLoading(false)
				Alert('Despacho generado con una advertencia: tenemos un problemas de conexión para enviar las notificaciones', 'warning')
				this.props.navigate('dashboard/dispatchthecollectors')
				return
			}
			setLoading(false)
			Alert('Despacho de cobrador creado correctamente.')
			this.props.navigate('dashboard/dispatchthecollectors')
		} catch (error) {
			console.log(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	render() {
		return (
			<>
				<div className="title-page">
					<div className="title">
						<NavLink to="/dashboard/dispatchthecollectors" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Despacho de cobrador</h2>
						<span>nuevo</span>
					</div>
					<div className="btns">
						<button className="btn" onClick={() => this.generateDispatch()}>
							Generar despacho y notificar al cobrador
						</button>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total productos</span>
							<strong>{this.state.cantidad}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avalúo precio crédito</span>
							<strong>{Price(this.state.avaluoCredito)}</strong>
						</div>
					</div>
					<div className="card hiden-responsive">
						<div className="item-widget">
							<span>Avalúo costo de operación</span>
							<strong>{Price(this.state.avaluoCostoOperacion)}</strong>
						</div>
					</div>
				</div>

				<div className="divider"></div>
				<div className="card">
					<div className="la_descriopcion">
						<Input
							type="select"
							title="Selecciona la ruta"
							name=""
							value={this.state.uuid_way}
							change={val => this.setState({ uuid_way: val })}
							options={this.state.rutas}
						/>
					</div>
					<div className="container-search">
						<div className="search">
							<input
								type="text"
								placeholder="Escribe algo.."
								onChange={event => {
									const val = event.target.value
									if (val.length > 2) {
										const productos = this.state.hiddenProducts.filter(p => {
											const words = p.name.toLowerCase().split(' ')
											return words.some(word => word.indexOf(val.toLowerCase()) > -1 || Levenshtein(word, val.toLowerCase()) < 3)
										})
										this.setState({ productos })
									} else {
										this.setState({ productos: this.state.hiddenProducts })
									}
								}}
							/>
							<button>
								<img src={require('img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Producto</th>
								<th>Cantidad despacho</th>
								<th className="hiden-responsive-2">Cantidad disponible</th>
								<th className="hiden-responsive">Precio a crédito</th>
								<th className="hiden-responsive">Precio de operación</th>
							</tr>
						</thead>
						<tbody>
							{this.state.productos.map(product => (
								<tr key={product.uuid}>
									<td>
										<NavLink className="item-pro">
											{product.image && product.image !== 'undefined' ? (
												<img src={e.urlBucket + product.image} alt={product.name} />
											) : (
												<img src={require('img/defecto.jpg')} alt={product.name} />
											)}
											<span>{product.name}</span>
										</NavLink>
									</td>
									<td className="cantidadCombo">
										<span onClick={() => this.changeAmount(product.uuid, 'subtract')}>-</span>
										<Input
											type="number"
											title=""
											placeholder=""
											name="cantidad"
											id={product.uuid}
											value={this.state.productsToDispatch[product.uuid]?.cantidad || 0}
											change={val => this.changeAmount(product.uuid, 'set', Number(val))}
										/>
										<span onClick={() => this.changeAmount(product.uuid, 'add')}>+</span>
									</td>
									<td className="hiden-responsive-2">
										<p>{product.stock}</p>
									</td>
									<td className="hiden-responsive">{Price(product.precioCredito)}</td>
									<td className="hiden-responsive">{Price(product.precioOperacion)}</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className="btns">
						<button className="btn" onClick={() => this.generateDispatch()}>
							Generar despacho y notificar al cobrador
						</button>
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(CreateDispatch)

import { useState, useEffect } from 'react'
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table'
import Select from 'react-select'
import Input from 'componentes/input'

function Table({ columns = [], data, pagination = true, pageSize = 10 }) {
	const [sorting, setSorting] = useState([])
	const [globalFilter, setGlobalFilter] = useState('')
	const [columnFilters, setColumnFilters] = useState([])
	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onGlobalFilterChange: setGlobalFilter,
		onColumnFiltersChange: setColumnFilters,
		state: {
			sorting,
			columnFilters,
			globalFilter
		}
	})
	const optionsFilterBy = []
	for (const column of columns) {
		optionsFilterBy.push({ value: column.accessorKey, label: `Filtrar por ${column.header}` })
	}
	const filterTable = (val = '', cols = []) => {
		cols = cols.map(col => {
			return {
				id: col.id,
				value: val
			}
		})
		setColumnFilters(cols)
		setGlobalFilter(val)
	}
	useEffect(() => {
		table.setPageSize(pageSize)
	}, [])
	return (
		<div className="table-filter">
			<div className="actions-and-filters">
				<div className="filters">
					<Input
						type="text"
						value={globalFilter}
						change={val => {
							filterTable(val, columnFilters)
						}}
						placeholder="Buscar..."
					/>
					<Select
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								borderColor: state.isFocused ? 'grey' : 'red'
							})
						}}
						className="select_table"
						classNamePrefix="select"
						defaultValue={null}
						isClearable={true}
						onChange={cols => {
							cols = cols.map(col => {
								return { id: col.value, value: '' }
							})
							filterTable(globalFilter, cols)
						}}
						isSearchable={true}
						isMulti={true}
						name="globalFilter"
						options={optionsFilterBy}
						placeholder="Filtrar por..."
					/>
				</div>
			</div>
			<table>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header, i) => (
								<th key={'th_' + i}>
									<button
										onClick={header.column.getToggleSortingHandler()}
										className={{ asc: 'up', desc: 'down' }[header.column.getIsSorted() ?? null]}>
										<span>{header.column.columnDef.header}</span>
									</button>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => (
						<tr key={row.id}>
							{row.getVisibleCells().map((cell, i) => (
								<td key={i}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{pagination && <Pargination pageSize={pageSize} table={table} />}
		</div>
	)
}

function Pargination({ table, pageSize }) {
	// Paginación de la tabla
	let paginationButtons = []
	const paginationGoups = []
	const paginationGoupLimit = 10
	const currentPage = table.getState().pagination.pageIndex
	const countPageSize = table.getPageCount()
	for (let i = 0; i < countPageSize; i++) {
		if (i > 0 && i % paginationGoupLimit === 0) {
			paginationButtons.push(<span>...</span>)
			paginationGoups.push(paginationButtons)
			paginationButtons = []
		}
		paginationButtons.push(
			<button className={currentPage === i ? 'active' : ''} key={i} onClick={() => table.setPageIndex(i)}>
				{i + 1}
			</button>
		)
	}
	if (paginationButtons.length > 0) {
		if (paginationGoups.length > 0) {
			paginationButtons.unshift(<span>...</span>)
		}
		paginationGoups.push(paginationButtons)
		paginationButtons = []
	}
	const getCurrentPaginationGroup = () => {
		for (const i in paginationGoups) {
			if (paginationGoups[i].findIndex(u => u.key === String(currentPage)) !== -1) {
				return paginationGoups[i]
			}
		}
	}
	const optionsShowItemsPage = [
		{ value: 10, label: 'Mostrar 10 filas' },
		{ value: 20, label: 'Mostrar 20 filas' },
		{ value: 40, label: 'Mostrar 40 filas' },
		{ value: 50, label: 'Mostrar 50 filas' },
		{ value: 100, label: 'Mostrar 100 filas' },
		{ value: 200, label: 'Mostrar 200 filas' },
		{ value: 300, label: 'Mostrar 300 filas' },
		{ value: 500, label: 'Mostrar 500 filas' },
		{ value: 1000, label: 'Mostrar 1000 filas' }
	]
	if (countPageSize > 0) {
		return (
			<div className="pagination">
				<strong>
					{' '}
					Página {currentPage + 1} de {countPageSize}
				</strong>
				<div className="controls">
					<button className="firts-page" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}></button>
					<button className="prev" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}></button>
					{getCurrentPaginationGroup().map(button => button)}
					<button className="next" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}></button>
					<button className="last-page" onClick={() => table.setPageIndex(countPageSize - 1)} disabled={!table.getCanNextPage()}></button>
				</div>
				<Select
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: state.isFocused ? 'grey' : 'red'
						})
					}}
					className="select_table"
					classNamePrefix="select"
					defaultValue={optionsShowItemsPage.find(p => p.value === pageSize) || optionsShowItemsPage[0]}
					isClearable={true}
					onChange={item => {
						table.setPageSize(Number(item.value))
					}}
					isSearchable={true}
					isMulti={false}
					name="groupItemsTable"
					options={optionsShowItemsPage}
					placeholder={'Seleccionar...'}
				/>
			</div>
		)
	}
}
export default Table

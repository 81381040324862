import { Component } from 'react'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import withParams from 'componentes/WithParams'
import Back from 'componentes/back'
import { Price, Alert } from 'utiles/functions'
const providers = new DB('providers')
const typespents = new DB('typespents')
const paymentmethods = new DB('paymentmethods')
const categorias = new DB('categories')
const cashs = new DB('cashs')
const spents = new DB('spents')
const movBoxes = new DB('movBoxes')
const cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalProducts: false,
			type: '',
			provider: '',
			types: [],
			providers: [],
			data: [],
			cajas: [],
			productosSeleccionados: {},
			modalCajas: false,
			modalMetodos: false,
			modalPago: false,
			cajasAMostrar: [],
			metodos: [],
			cajasSeleccionados: [],
			cajasSeleccionada: {},
			metodoSeleccionado: {},
			valorAbono: 0,
			costo: 0,
			Abonado: 0,
			Deuda: 0,
			description: '',
			gasto: []
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})
		providers
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					this.setState({ providers: data.data.Items })
				}
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ metodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ cajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})

		typespents
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					const tipos = data.data.Items.map(type => {
						return { val: type.uuid, text: type.name }
					})
					tipos.unshift({ val: '', text: 'Selecciona un tipo de gasto' })
					this.setState({ loading: false, types: tipos })
				} else {
					this.setState({ loading: false })
				}
			})
			.catch(error => {
				console.error(error)
			})

		// consulto el gasto
		spents
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				this.setState({
					loading: false,
					gasto: data.data.Item,
					costo: data.data.Item.total
				})
				// consulto los abonos a el pago
				movBoxes
					.query('#gasto_uuid = :g', { ':g': data.data.Item.uuid }, { '#gasto_uuid': 'gasto_uuid' })
					.then(movimientos => {
						if (movimientos.status) {
							this.setState({ movimientosAnteriores: movimientos.data.Items }, () => {
								this.abonosAnteriores()
							})
						}
					})
					.catch(error => {
						console.error(error)
					})
			})
			.catch(error => {
				console.error(error)
				this.setState({ loading: false }, () => {
					Alert('¡Ooops!, tenemos problemas de conexión, intentalo de nuevo', 'danger')
				})
			})
	}

	abonosAnteriores() {
		const movimientosAnteriores = [...this.state.movimientosAnteriores]
		const cajasAMostrar = []
		for (const movimiento of movimientosAnteriores) {
			// consulto la caja
			let arrCaja = []
			arrCaja = this.state.cajas.find(caja => caja.uuid === movimiento.caja_uuid)
			// añado el aboono a la caja
			const method = this.state.metodos.find(metodo => metodo.uuid === movimiento.metodo_uuid)
			arrCaja.abono = {
				amount: movimiento.value,
				metodo: method
			}
			// defino un boolean para validar si el abono es antiguo o nuevo, los antiguos no se pueden eliminar ni nada
			arrCaja.abonoAntiguo = true
			cajasAMostrar.push(arrCaja)
		}
		this.setState({ cajasAMostrar })
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Gastos</h2>
						<span>nuevo gasto</span>
					</div>
					<div className="btns"></div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total gasto</span>
							<strong>{Price(this.state.costo)}</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<div className="contenido">
								<h3>Gasto</h3>
								<p>
									<strong>Tipo de gasto:</strong> {this.state.types.find(gasto => gasto.val === this.state.gasto.typespent)?.text}
								</p>
								<p>
									<strong>Valor del gasto: </strong> {this.state.gasto.total}
								</p>
								<p>
									<strong>Descripción: </strong> {this.state.gasto.description}
								</p>
							</div>
							<div className="contenido">
								<h3>Proveedor</h3>
								<p>
									<strong>Nombre:</strong> {this.state.providers.find(proveedor => proveedor.uuid === this.state.gasto.provider)?.name}
								</p>
								<p>
									<strong>NIT: </strong> {this.state.providers.find(proveedor => proveedor.uuid === this.state.gasto.provider)?.doc}
								</p>
								<p>
									<strong>Teléfono: </strong> {this.state.providers.find(proveedor => proveedor.uuid === this.state.gasto.provider)?.phone}
								</p>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/dashboard/providers/edit/' + this.state.gasto.provider)
									}}
									className="btn-edit">
									Ver proveedor
								</a>
							</div>
						</div>
						<div className="column">
							<div className="contenido">
								<h3>Usuario</h3>
								{this.state.gasto.user && (
									<>
										<p>
											<strong>Nombre:</strong> {this.state.gasto.user.name}
										</p>
										<p>
											<strong>Correo:</strong> {this.state.gasto.user.email}
										</p>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('/dashboard/users/edit/' + this.state.gasto.user.email)
											}}
											className="btn-edit">
											Ver usuario
										</a>
									</>
								)}
							</div>
						</div>
					</div>
					<hr />
					<br />

					<div className="productos">
						<div className="columns">
							<div className="column">
								<h3>Salida de dinero</h3>
							</div>
							<div className="column"></div>
						</div>
						<div className="divider"></div>
						<table className="table">
							<thead>
								<tr>
									<th>Caja</th>
									<th>Metodo</th>
									<th>Abono</th>
								</tr>
							</thead>
							<tbody>
								{this.state.cajasAMostrar.map((caja, index) => (
									<tr key={caja.uuid}>
										<td>{caja.name}</td>
										<td>{caja.abono.metodo.name}</td>
										<td>{Price(caja.abono.amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default withParams(Page)

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import DB from 'utiles/db'
import Table from 'componentes/Table'
import { Price, hoy, Confirm, Alert } from 'utiles/functions'
import { setLoading } from 'store'
import { v4 as uuidv4 } from 'uuid'
const loansTable = new DB('loans')
const usersTable = new DB('users')
const movBoxesTable = new DB('movBoxes')
const cashsTable = new DB('cashs')
const inventoryTweaks = new DB('inventory_tweaks')
const productsTable = new DB('products')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loans: []
		}
	}

	componentDidMount() {
		this.init()
	}

	async init() {
		try {
			setLoading(true)
			const resultUsers = await usersTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const resultLoans = await loansTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			if (resultUsers.status === false || resultLoans.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				console.error(resultUsers, resultLoans)
				return
			}
			const loans = []
			for (const loan of resultLoans.data.Items) {
				loan.empleado = resultUsers.data.Items.find(user => user.email === loan.empleado)
				loans.push(loan)
			}
			this.setState({ loans })
			setLoading(false)
		} catch (error) {
			console.error(error)
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
		}
	}

	async deleteLoan(prestamo) {
		setLoading(true)
		try {
			const usuarioAjuste = {
				email: prestamo.empleado.email,
				name: prestamo.empleado.name,
				rol: prestamo.empleado.rol
			}
			if (prestamo.tipo === 'dinero') {
				// hago un movimiento de caja y restablezco el total del metodo de pago en caja
				await movBoxesTable.create({
					uuid: uuidv4(),
					cava: this.props.cava.uuid,
					caja_uuid: prestamo.caja,
					metodo_uuid: prestamo.metodo,
					type: 'ingreso',
					value: prestamo.total,
					description: 'Cancelación de préstamo a empleado',
					voucher: '',
					user: usuarioAjuste
				})

				const box = (await cashsTable.get({ uuid: prestamo.caja })).data.Item
				const result = await cashsTable.update({
					Key: { uuid: prestamo.caja },
					UpdateExpression: `SET #uuid_method.#amount = :amount`,
					ExpressionAttributeNames: {
						'#uuid_method': prestamo.metodo,
						'#amount': 'amount'
					},
					ExpressionAttributeValues: {
						':amount': (box[prestamo.metodo]?.amount || 0) + prestamo.total
					}
				})
				if (result.status === false) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					console.error(result)
					return
				}
			}
			if (prestamo.tipo === 'productos') {
				// guardar ajuste de inventario
				const productosAjustados = []
				// construir arreglo de productos editados
				for (const product of prestamo.productos) {
					productosAjustados.push({
						uuid: product.uuid,
						name: product.name,
						image: product.image,
						quantity_old: product.stock,
						quantity_new: Number(product.stock) + Number(product.quantity),
						price_credito: product.price_credito
					})
				}
				// guardar ajustede inventario
				const resultInventory = await inventoryTweaks.create({
					uuid: uuidv4(),
					user: usuarioAjuste,
					description: 'Cancelación de préstamo de productos',
					tweaks: productosAjustados,
					cava: this.props.cava.uuid
				})
				if (resultInventory.status === false) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					console.error(resultInventory)
					return
				}
				// ajustar cantidades en los productos
				const pr = []
				for (const producto of productosAjustados) {
					pr.push(
						productsTable.update({
							Key: { uuid: producto.uuid },
							UpdateExpression: 'SET #s = :stock',
							ExpressionAttributeValues: {
								':stock': producto.quantity_new
							},
							ExpressionAttributeNames: {
								'#s': 'stock'
							}
						})
					)
				}
				const resultProducts = await Promise.all(pr)
				if (resultProducts.some(res => res.status === false)) {
					setLoading(false)
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
					console.error(resultProducts)
					return
				}
			}
			const resultDelete = await loansTable.delete({ uuid: prestamo.uuid })
			if (resultDelete.status === false) {
				setLoading(false)
				Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				console.error(resultDelete)
				return
			}
			setLoading(false)
			this.init()
		} catch (error) {
			setLoading(false)
			Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
			console.error(error)
		}
	}

	render() {
		const columns = [
			{ header: 'Empleado', accessorKey: 'empleado.name' },
			{ header: 'tipo', accessorKey: 'tipo' },
			{ header: 'Valor total', accessorKey: 'total', cell: ({ row }) => Price(row.original.total) },
			{ header: 'Fecha', accessorKey: 'time', cell: ({ row }) => hoy(row.original.time) },
			{
				header: 'Estado',
				accessorKey: 'estado',
				cell: ({ row }) => (
					<span className={`status ${row.original.estado !== 'pendiente' ? 'Activo' : 'Desincronizado'}`}>
						{row.original.estado !== 'pendiente' ? 'Pagado' : 'Pendiente'}
					</span>
				)
			},
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<>
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								this.props.navigate('/dashboard/loans/edit/' + row.original.uuid)
							}}
							className="btn-edit">
							Ver
						</a>
						<a
							href="#"
							onClick={event => {
								event.preventDefault()
								Confirm('¿Realmente desea eliminar este préstamo?', () => this.deleteLoan(row.original))
							}}
							className="btn-delete">
							Eliminar
						</a>
					</>
				)
			}
		]

		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Préstamos</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/loans/create" className="btn">
							Nuevo prestamos
						</NavLink>
					</div>
				</div>
				<div className="card">
					<Table columns={columns} data={this.state.loans} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

import { Component } from 'react'
import Input from 'componentes/input.jsx'
import Loader from 'componentes/loader.jsx'
import withParams from 'componentes/WithParams'
import { NavLink } from 'react-router-dom'
import { Price } from 'utiles/functions.js'
import DB from 'utiles/db.js'
const camps = {
	phone: 'Telefono de usuario',
	name: 'Nombre de usuario',
	address: 'Dirección de usuario',
	level: 'Nivel de usuario',
	mora: 'Deuda de usuario'
}
const conditions = {
	'=': 'Igual ( = )',
	'>': 'Mayor ( > )',
	'<': 'Menor ( < )',
	'!=': 'Diferente ( <> )',
	contains: 'Contenga'
}
const types = {
	AND: 'Y',
	OR: 'O'
}
const ways = new DB('ways')
const campains = new DB('campains')
class Edit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sms: '',
			loading: false,
			conditions: [],
			ways: [],
			way: {},
			priceSMS: 0.0015,
			countClients: 0
		}
	}

	componentDidMount() {
		this.getCampain()
		this.getWays()
	}

	getCampain() {
		campains.get({ uuid: this.props.params.uuid }).then(data => {
			if (data.status) {
				this.setState({
					loading: false,
					sms: data.data.Item.sms,
					way: data.data.Item.way,
					conditions: data.data.Item.conditions,
					countClients: data.data.Item.countClients
				})
			}
		})
	}

	getWays() {
		ways
			.query('#cava = :c AND #status = :status', { ':c': this.props.cava.uuid, ':status': true }, { '#cava': 'cava', '#status': 'status' })
			.then(data => {
				if (data.status) {
					const ways = [{ val: '', text: 'Seleccionar...' }]
					for (const way of data.data.Items) {
						ways.push({
							val: way.uuid,
							text: way.name + ' - ' + way.day
						})
					}
					this.setState({ ways })
				}
			})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div>
					<div className="title-page">
						<div className="title">
							<NavLink to="/dashboard/campains" className="back">
								<img src={require('../../img/back.svg').default} width={25} height={25} alt="Imagen" />
							</NavLink>
							<h2>Campañas SMS</h2>
							<span>Ver campaña</span>
						</div>
					</div>
					<div className="grid g3" style={{ marginBottom: 20 }}>
						<div className="card">
							<div className="item-widget">
								<span>Cantidad de SMS enviados</span>
								<strong>{this.state.countClients}</strong>
							</div>
						</div>
						<div className="card">
							<div className="item-widget">
								<span>Costo de la campaña</span>
								<strong>{Price(this.state.countClients * this.state.priceSMS, 'USD')}</strong>
							</div>
						</div>
						<div className="card">
							<div className="item-widget">
								<span>Precio del SMS</span>
								<strong>{Price(this.state.priceSMS, 'USD')}</strong>
							</div>
						</div>
					</div>
					<div className="card">
						<Input
							type="textarea"
							height={170}
							title="Mensaje SMS (Máximo 100 caracteres) *"
							name="sms"
							value={this.state.sms}
							readonly={true}
							placeholder="Escribe el mensaje."
						/>
						<Input type="select" title="Ruta ( Opcional )" name="way" value={this.state.way} options={this.state.ways} />
						<div className="divider"></div>
						<div className="title-page">
							<div className="title">
								<h2>Condiciones de envio</h2>
							</div>
						</div>
						<div className="divider"></div>
						{this.state.conditions.length > 0 && (
							<table className="table">
								<thead>
									<tr>
										<th>Cuando</th>
										<th>Sea</th>
										<th>A</th>
									</tr>
								</thead>
								<tbody>
									{this.state.conditions.map((condition, i) => (
										<tr key={i}>
											{condition.type === 'condition' ? (
												<>
													<td>{camps[condition.camp]}</td>
													<td>{conditions[condition.condition]}</td>
													<td>{condition.value}</td>
												</>
											) : (
												<>
													<td
														colSpan={4}
														style={{
															textAlign: 'center',
															backgroundColor: '#600080',
															color: '#fff',
															fontWeight: 'bold'
														}}>
														{types[condition.separator]}
													</td>
												</>
											)}
										</tr>
									))}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</>
		)
	}
}
export default withParams(Edit)

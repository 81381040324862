import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { Price, Alert } from 'utiles/functions'
import { NavLink } from 'react-router-dom'
import DB from 'utiles/db'
import Table from 'componentes/Table'
import { setLoading } from 'store'
import e from 'utiles/settings'
const productsTable = new DB('products')
const categoriasTable = new DB('categories')
class Products extends Component {
	constructor(props) {
		super(props)
		this.state = {
			products: [],
			cant: 0,
			avaluoOperation: 0,
			avaluoCosto: 0,
			avaluoContado: 0,
			avaluoCredito: 0
		}
	}

	componentDidMount() {
		this.getProducts()
	}

	async getProducts() {
		try {
			setLoading(true)
			const resultCategories = await categoriasTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			const resultProducts = await productsTable.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			if (resultCategories.status === false || resultProducts.status === false) {
				console.error(resultCategories)
				console.error(resultProducts)
				Alert('¡Ooops! tenemos problemas de conexión', 'danger')
				setLoading(false)
			}

			const categories = {}
			for (const cat of resultCategories.data.Items) {
				categories[cat.uuid] = cat.name
			}
			let cant = 0
			let avaluoOperation = 0
			let avaluoCosto = 0
			let avaluoContado = 0
			let avaluoCredito = 0
			const products = []
			for (const p of resultProducts.data.Items) {
				cant += Number(p.stock)
				avaluoCosto += Number(p.stock) * Number(p.price_cost)
				avaluoOperation += Number(p.stock) * Number(p.price_operation)
				avaluoContado += Number(p.stock) * Number(p.price_contado)
				avaluoCredito += Number(p.stock) * Number(p.price_credito)
				p.categoryName = categories[p.category]
				products.push(p)
			}
			this.setState({
				products,
				cant,
				avaluoOperation,
				avaluoCosto,
				avaluoContado,
				avaluoCredito
			})
			setLoading(false)
		} catch (error) {
			console.error(error)
			Alert('¡Ooops! tenemos problemas de conexión', 'danger')
			setLoading(false)
		}
	}

	render() {
		const columns = [
			{
				header: 'Nombre',
				accessorKey: 'name',
				cell: ({ row }) => (
					<NavLink className="item-pro" to={'/dashboard/products/edit/' + row.original.uuid}>
						{row.original.image !== undefined ? (
							<img src={e.urlBucket + row.original.image} alt={row.original.name} />
						) : (
							<img src={require('../../img/defecto.jpg')} alt={row.original.name} />
						)}
						<span>{row.original.name}</span>
					</NavLink>
				)
			},
			{ header: 'Categoria', accessorKey: 'categoryName' },
			{ header: 'Precio de contado', accessorKey: 'price_contado', cell: ({ row }) => Price(row.original.price_contado) },
			{ header: 'Precio a crédito', accessorKey: 'price_credito', cell: ({ row }) => Price(row.original.price_credito) },
			{ header: 'Cantidad', accessorKey: 'stock' },
			{
				header: 'Estado',
				accessorKey: 'status',
				cell: ({ row }) => (
					<span className={`status ${row.original.status ? 'Activo' : 'Inactivo'}`}>{row.original.status ? 'Activo' : 'Inactivo'}</span>
				)
			},
			{
				header: 'Estado catálogo',
				accessorKey: 'status_catalogo',
				cell: ({ row }) => (
					<span className={`status ${row.original.status_catalogo ? 'Activo' : 'Inactivo'}`}>
						{row.original.status_catalogo ? 'Activo' : 'Inactivo'}
					</span>
				)
			},
			{
				header: 'Acciones',
				cell: ({ row }) => (
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.props.navigate('/dashboard/products/edit/' + row.original.uuid)
						}}
						className="btn-edit">
						Editar
					</a>
				)
			}
		]
		return (
			<>
				<div className="title-page">
					<div className="title">
						<h2>Productos</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/inventory_tweaks" className="btn">
							Ajustes de inventario
						</NavLink>
						<NavLink to="/dashboard/products/categories" className="btn">
							Categorias
						</NavLink>
						<NavLink to="/dashboard/products/create" className="btn">
							Nuevo producto
						</NavLink>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Inventario global</span>
							<strong>{this.state.cant}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avaluo costo</span>
							<strong>{Price(this.state.avaluoCosto)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avaluo operación</span>
							<strong>{Price(this.state.avaluoOperation)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avaluo contado</span>
							<strong>{Price(this.state.avaluoContado)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Avaluo crédito</span>
							<strong>{Price(this.state.avaluoCredito)}</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<Table columns={columns} data={this.state.products} pageSize={20} />
				</div>
			</>
		)
	}
}
export default AnimateScreen(Products)

import { Component } from 'react'
import Input from 'componentes/input'
import Loader from 'componentes/loader'
import { Alert } from 'utiles/functions'
import Back from 'componentes/back'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db'
const cavas = new DB('cavas')

export default class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			catalogo: false,
			hide_amount: false
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const cava = {
			uuid: uuidv4(),
			name: data.get('name'),
			address: data.get('address'),
			phone: data.get('phone'),
			phone2: data.get('phone2'),
			catalogo: this.state.catalogo,
			hide_amount: this.state.hide_amount,
			count_ways: 0,
			count_cobradores: 0,
			count_clientes: 0
		}
		if (cava.name !== '' && cava.address !== '' && cava.phone !== '') {
			this.setState({ loading: true }, async () => {
				const result = await cavas.create(cava)
				if (result.status) {
					this.setState({ loading: false }, () => {
						Alert('Cava creada correctamente.')
						this.props.navigate('/admin/cavas')
					})
				} else {
					this.setState({ loading: false })
				}
			})
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Cavas</h2>
						<span>Nueva cava</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre de la cava *" name="name" placeholder="Ingresa el nombre" />
							<Input type="textarea" title="Dirección *" name="address" placeholder="Ingresa la descripción" />
							<Input type="number" title="Celular de la cava *" name="phone" placeholder="Ingresa el celular" />
							<Input type="number" title="Celular 2 de la cava" name="phone2" placeholder="Ingresa el celular" />
						</div>
						<div className="column">
							<Input type="image" name="avatar" title="Logo" />
							<div className="divider"></div>
							<Input
								type="checkbox"
								id="catalogo"
								title="Activar catálogo:"
								active={this.state.catalogo}
								change={val => {
									this.setState({ catalogo: val })
								}}
							/>
							<Input
								type="checkbox"
								id="hide_amount"
								title="Ocultar monto:"
								active={this.state.hide_amount}
								change={val => {
									this.setState({ hide_amount: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn m-auto" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}

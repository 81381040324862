import { Component } from 'react'
import Loader from 'componentes/loader'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import Back from 'componentes/back'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const providers = new DB('providers')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			name: '',
			doc: '',
			phone: '',
			bank: '',
			description: '',
			status: false
		}
	}

	componentDidMount() {
		providers
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				if (data.status) {
					this.setState({
						loading: false,
						name: data.data.Item.name,
						doc: data.data.Item.doc,
						phone: data.data.Item.phone,
						bank: data.data.Item.bank,
						description: data.data.Item.description,
						status: data.data.Item.status
					})
				} else {
					this.setState({ loading: false })
				}
			})
			.catch(error => {
				console.error(error)
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (data.get('name') !== '' && data.get('doc') !== '' && data.get('phone') !== '') {
			this.setState({ loading: true }, () => {
				providers
					.newUpdate(
						{
							name: data.get('name'),
							doc: data.get('doc'),
							phone: data.get('phone'),
							bank: data.get('bank'),
							description: data.get('description'),
							status: this.state.status
						},
						{
							uuid: this.props.params.uuid
						}
					)
					.then(data => {
						if (data.status) {
							this.setState({ loading: false })
							this.props.navigate('/dashboard/providers')
							Alert('Proveedor actualizado correctamente.')
						} else {
							Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
						}
					})
					.catch(error => {
						console.error(error)
					})
			})
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Proveedores</h2>
						<span>Editar proveedor</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre del proveedor *" placeholder="Ingresa el nombre del proveedor" value={this.state.name} name="name" />
							<Input
								type="number"
								title="NIT o Cédula de ciudadania *"
								placeholder="Ingresa el número de documento"
								value={this.state.doc}
								change={val => {
									this.setState({ doc: val })
								}}
								name="doc"
							/>
							<Input
								type="number"
								title="Número de teléfono *"
								placeholder="Ingresa el número de teléfono"
								value={this.state.phone}
								change={val => {
									this.setState({ phone: val })
								}}
								name="phone"
							/>
						</div>
						<div className="column">
							<Input
								type="textarea"
								title="¿Que productos provee?"
								height={110}
								placeholder="Ejemplo: 
	                        	- Chorizo 
	                        	- Salchichón"
								name="description"
								value={this.state.description}
							/>
							<Input
								type="textarea"
								title="Datos de cuenta bancaria para transferencias"
								placeholder="Ingresa los datos de la cuenta bancaria"
								value={this.state.bank}
								name="bank"
							/>
							<Input
								type="checkbox"
								id="status"
								title="Estado (Inactivo / Activo)"
								active={this.state.status}
								change={val => {
									this.setState({ status: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(Page)

import { Component } from 'react'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { v4 as uuidv4 } from 'uuid'
import { Alert, Price } from 'utiles/functions'
import DB from 'utiles/db'
import Back from 'componentes/back'
const paymentmethods = new DB('paymentmethods')
const cashs = new DB('cashs')
const movBoxes = new DB('movBoxes')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			valor: '',
			voucher: '',
			type: '',
			description: '',
			cajas: [],
			metodos: [],
			infoCajas: [],
			infoMetodos: [],
			caja: '',
			metodo: '',
			disponibleCaja: 0
		}
	}

	componentDidMount() {
		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const cajas = data.data.Items.map(val => {
					return { val: val.uuid, text: val.name }
				})
				cajas.unshift({ val: '', text: 'Selecciona la caja' })
				this.setState({ cajas, infoCajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const metodos = data.data.Items.map(val => {
					return { val: val.uuid, text: val.name }
				})
				metodos.unshift({ val: '', text: 'Selecciona el metodo' })
				this.setState({ metodos, infoMetodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
	}

	guardar() {
		if (this.state.type !== '') {
			if (this.state.valor !== '') {
				if (this.state.voucher !== '') {
					if (this.state.caja !== '') {
						if (this.state.metodo !== '') {
							if (this.state.type === 'egreso') {
								if (this.state.valor <= this.state.disponibleCaja) {
									this.registrarBd()
								} else {
									Alert('No hay dinero disponible suficiente en la caja o método de pago que seleccionaste', 'danger')
								}
							} else {
								this.registrarBd()
							}
						} else {
							Alert('Selecciona el método de pago', 'danger')
						}
					} else {
						Alert('Selecciona la caja', 'danger')
					}
				} else {
					Alert('Ingresa el comprobante', 'danger')
				}
			} else {
				Alert('Ingresa el valor', 'danger')
			}
		} else {
			Alert('Selecciona el tipo de movimiento', 'danger')
		}
	}

	registrarBd() {
		this.setState({ loading: true }, async () => {
			// registrar movimiento
			// construir la info del usuario
			const usuarioAjuste = {
				email: this.props.user.email,
				name: this.props.user.name,
				rol: this.props.user.rol
			}
			await movBoxes.create({
				uuid: uuidv4(),
				cava: this.props.cava.uuid,
				caja_uuid: this.state.caja,
				metodo_uuid: this.state.metodo,
				type: this.state.type,
				value: Number(this.state.valor),
				description: this.state.description,
				voucher: this.state.voucher,
				user: usuarioAjuste
			})
			// editar caja
			const laCaja = this.state.infoCajas.find(caja => caja.uuid === this.state.caja)
			let totalcaja = 0
			if (laCaja[this.state.metodo]) {
				totalcaja = laCaja[this.state.metodo].amount
			}
			if (this.state.type === 'ingreso') {
				totalcaja += Number(this.state.valor)
			} else if (this.state.type === 'egreso') {
				totalcaja -= this.state.valor
			}
			const method = this.state.infoMetodos.find(metodo => metodo.uuid === this.state.metodo)
			const methodUpdate = {
				amount: totalcaja,
				name: method.name
			}
			await cashs.update({
				Key: { uuid: this.state.caja },
				UpdateExpression: `SET #m = :m`,
				ExpressionAttributeValues: {
					':m': methodUpdate
				},
				ExpressionAttributeNames: {
					'#m': this.state.metodo
				},
				ReturnValues: 'UPDATED_NEW'
			})
			this.setState({ loading: false })
			this.props.navigate('/dashboard/cashMovements')
			Alert('Movimiento de caja realizado con éxito.')
		})
	}

	evaluarDisponibilidad() {
		if (this.state.type !== '') {
			if (this.state.valor !== '') {
				if (this.state.caja !== '') {
					if (this.state.metodo !== '') {
						if (this.state.type === 'egreso') {
							this.setState({ loading: true }, () => {
								cashs
									.get({ uuid: this.state.caja })
									.then(data => {
										const caja = data.data.Item
										console.log(caja)
										if (caja[this.state.metodo]) {
											const amount = caja[this.state.metodo].amount
											this.setState({ disponibleCaja: amount })
											if (this.state.valor > amount) {
												Alert('No hay dinero disponible suficiente en la caja o método de pago que seleccionaste', 'danger')
											}
										} else {
											this.setState({ disponibleCaja: 0 })
											Alert('No hay dinero disponible suficiente en la caja o método de pago que seleccionaste', 'danger')
										}
										this.setState({ loading: false })
									})
									.catch(error => {
										console.error(error)
										this.setState({ loading: false })
										Alert('¡Ooops!, tenemos problemas de conexión, intenta de nuevo')
									})
							})
						}
					} else {
						Alert('Por favor selecciona el método de pago', 'danger')
					}
				} else {
					Alert('Por favor selecciona la caja', 'danger')
				}
			} else {
				Alert('Por favor ingresa el valor del movimiento', 'danger')
			}
		} else {
			Alert('Por favor selecciona el tipo de movimiento', 'danger')
		}
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Movimientos de caja</h2>
						<span>Nuevo</span>
					</div>
					<div className="btns">
						<button className="btn" onClick={() => this.guardar()}>
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input
								type="select"
								title="Tipo de movimiento *"
								placeholder=""
								name="name"
								value={this.state.type}
								change={val => this.setState({ type: val })}
								options={[
									{
										val: '',
										text: 'Selecciona el tipo del gasto'
									},
									{
										val: 'ingreso',
										text: 'Ingreso'
									},
									{
										val: 'egreso',
										text: 'Egreso'
									}
								]}
							/>
							<Input
								type="number"
								title="Valor del movimiento *"
								placeholder="Ingresa el valor"
								name="doc"
								value={this.state.valor}
								change={val => this.setState({ valor: val })}
							/>
							<Input
								type="text"
								title="Comprobante *"
								placeholder="Ingresa el código del comprobante"
								name="phone"
								value={this.state.voucher}
								change={val => this.setState({ voucher: val })}
							/>
						</div>
						<div className="column">
							<Input
								type="textarea"
								title="Descripción"
								height={110}
								placeholder="Ingresa la descripción"
								name="description"
								value={this.state.description}
								change={val => this.setState({ description: val })}
							/>
						</div>
					</div>
					<div className="divider"></div>
					{this.state.type === 'egreso' && (
						<p>
							<strong>Disponible caja seleccionada: </strong>
							{Price(this.state.disponibleCaja)}
						</p>
					)}
					<div className="columns">
						<div className="column">
							<Input
								type="select"
								title="Caja *"
								placeholder=""
								name="name"
								value={this.state.caja}
								change={val => {
									this.setState({ caja: val }, () => this.evaluarDisponibilidad())
								}}
								options={this.state.cajas}
							/>
						</div>
						<div className="column">
							<Input
								type="select"
								title="Método *"
								placeholder=""
								name="name"
								value={this.state.metodo}
								change={val => this.setState({ metodo: val }, () => this.evaluarDisponibilidad())}
								options={this.state.metodos}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" onClick={() => this.guardar()}>
						Guardar
					</button>
				</div>
			</>
		)
	}
}

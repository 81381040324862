import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import QRCode from 'react-qr-code'
import e from 'utiles/settings.js'

export default function Header(props) {
	const [qr, setQr] = useState(false)
	return (
		<header>
			<div>
				<a
					href="#"
					className="btn-menu"
					onClick={event => {
						event.preventDefault()
						props.setMini(!props.mini)
					}}>
					<img className="toggle-menu" src={require('../img/menu.svg').default} width={35} height={35} />
				</a>
			</div>
			<div className="container-access">
				{props.cava && (
					<>
						{
							// usar clase cuando tengamos notificaciones en tiempo real
							// hasNotifications
						}
						{props.user.rol === 'Administrador' ||
							(props.user.rol === 'Super' && (
								<>
									<a
										href="#"
										className="qrs"
										onClick={event => {
											event.preventDefault()
											setQr(!qr)
										}}>
										<img src={require('../img/qr.svg').default} width={20} height={20} />
										{qr && (
											<div className="qr-device">
												<QRCode
													value={btoa(
														JSON.stringify({
															cava: props.cava.uuid,
															name: props.cava.name,
															hide_amount: props.cava.hide_amount
														})
													)}
												/>
												<p>QR para aplicación móvil</p>
											</div>
										)}
									</a>
									<NavLink to="/dashboard/notifications" className="notifications">
										<img src={require('../img/notification.svg').default} width={20} height={20} />
									</NavLink>
									<NavLink to="/dashboard/settings" className="settings">
										<img src={require('../img/settings.svg').default} width={20} height={20} />
									</NavLink>
								</>
							))}
					</>
				)}
				<NavLink className="avatar" to="/dashboard/profile">
					{'avatar' in props.user ? (
						<div className="container-avater">
							<img src={e.urlBucket + props.user.avatar} alt="" />
						</div>
					) : (
						<span className="container-avater">{props.user.name[0].toUpperCase()}</span>
					)}
					<div className="text">
						<span>{props.user.name}</span>
					</div>
				</NavLink>
			</div>
		</header>
	)
}

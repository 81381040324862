const e = {
	// Accesos AWS
	// -----------
	region: 'us-east-2',
	accessKeyId: 'AKIA43PX26BN6ZQJMHG6',
	secretAccessKey: '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',
	bucket: 'cavas',
	urlBucket: 'https://cavas.s3.us-east-2.amazonaws.com/',
	urlSMS: 'https://ibuk5pkt2b.execute-api.us-east-2.amazonaws.com/default/SMSender',
	smsKey: 'Ak77jsgtlnJDbtggHTuYDT2Op58h28Yoh78!J4j415Yg1A1fd12aeFfp'

	// Configuración de estilos globales
	// ---------------------------------
	// font: 17,
	// fontSmall: 15,
	// fontBig: 25,
	// p: '#009BDD',
	// negro: '#423F40',
	// blanco: '#fff',
	// gris: '#bbb',
	// negro2: '#111',
	// gris2: '#eaeaea',
	// background: '#fff'
}
export default e

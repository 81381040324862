import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import { Price } from 'utiles/functions'
import DB from 'utiles/db'
import withParams from 'componentes/WithParams'
const paymentmethods = new DB('paymentmethods')
const cashs = new DB('cashs')
const movBoxes = new DB('movBoxes')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			valor: '',
			voucher: '',
			type: '',
			description: '',
			cajas: [],
			metodos: [],
			infoCajas: [],
			infoMetodos: [],
			caja: '',
			metodo: '',
			user: {}
		}
	}

	componentDidMount() {
		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ cajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ metodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		movBoxes.get({ uuid: this.props.params.uuid }).then(data => {
			this.setState({
				type: data.data.Item.type,
				valor: data.data.Item.value,
				description: data.data.Item.description,
				voucher: data.data.Item.voucher,
				caja: data.data.Item.caja_uuid,
				metodo: data.data.Item.metodo_uuid,
				user: data.data.Item.user
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/dashboard/cashMovements" className="back">
							<img src={require('../../img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Movimientos de caja</h2>
						<span>Editar</span>
					</div>
					<div className="btns"></div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<div className="contenido">
								<p>
									<strong>Tipo de movimiento: </strong> <span style={{ textTransform: 'uppercase' }}>{this.state.type}</span>{' '}
									{this.state.type === 'egreso' ? (
										<img className="flechaegreso" src={require('../../img/direct-down.svg').default} width={18} height={18} />
									) : (
										<img className="flechaingreso" src={require('../../img/direct-up.svg').default} width={18} height={18} />
									)}{' '}
								</p>
								<p>
									<strong>Valor: </strong> {Price(this.state.valor)}
								</p>
								<p>
									<strong>Comprobante: </strong> {this.state.voucher}
								</p>
								<p>
									<strong>Caja: </strong> {this.state.cajas.find(caja => caja.uuid === this.state.caja)?.name}
								</p>
								<p>
									<strong>Metodo: </strong> {this.state.metodos.find(metodo => metodo.uuid === this.state.metodo)?.name}
								</p>
								<p>
									<strong>Descripción: </strong> {this.state.description}
								</p>
							</div>
						</div>
						<div className="column">
							<div className="contenido">
								<h3>Usuario</h3>
								<p>
									<strong>Nombre: </strong> {this.state.user.name}
								</p>
								<p>
									<strong>Correo: </strong> {this.state.user.email}
								</p>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/dashboard/users/edit/' + this.state.user.email)
									}}
									className="btn-edit">
									Ver usuario
								</a>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
export default withParams(Page)

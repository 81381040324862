import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Price } from 'utiles/functions'
import DB from 'utiles/db'
import e from 'utiles/settings'
import withParams from 'componentes/WithParams'
import Back from 'componentes/back'
const paymentmethods = new DB('paymentmethods')
const cashs = new DB('cashs')
const loans = new DB('loans')
const users = new DB('users')
const products = new DB('products')
const categorias = new DB('categories')
const cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: true,
			valor: '',
			voucher: '',
			type: '',
			description: '',
			cajas: [],
			metodos: [],
			infoCajas: [],
			infoMetodos: [],
			data: [],
			usuarios: [],
			caja: '',
			metodo: '',
			modalProducts: false,
			productosSeleccionados: {},
			productosAMostrar: [],
			cantidadProductos: 0,
			costo: 0
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})

		products
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					this.setState({ data: data.data.Items })
				}
			})
			.catch(error => {
				console.error(error)
			})

		users
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ usuarios: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})

		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const cajas = data.data.Items.map(val => {
					return { val: val.uuid, text: val.name }
				})
				cajas.unshift({ val: '', text: 'Selecciona la caja' })
				this.setState({ cajas, infoCajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				const metodos = data.data.Items.map(val => {
					return { val: val.uuid, text: val.name }
				})
				metodos.unshift({ val: '', text: 'Selecciona el metodo' })
				this.setState({ metodos, infoMetodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})

		loans.get({ uuid: this.props.params.uuid }).then(data => {
			this.setState({
				costo: data.data.Item.total,
				type: data.data.Item.tipo,
				cantidadProductos: data.data.Item.productos.length,
				productosAMostrar: data.data.Item.productos,
				data: data.data.Item
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Préstamos</h2>
						<span>Ver</span>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Total prestado</span>
							<strong>{Price(this.state.costo)}</strong>
						</div>
					</div>
					{this.state.type === 'productos' && (
						<div className="card">
							<div className="item-widget">
								<span>Cantidad productos</span>
								<strong>{this.state.cantidadProductos}</strong>
							</div>
						</div>
					)}
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<div className="contenido">
								<h3>Información del prestamo</h3>
								<p>
									<strong>Tipo de préstamo: </strong> {this.state.data.tipo}
								</p>
								<p>
									<strong>Empleado: </strong>
								</p>
								<p>Nombre: {this.state.usuarios.find(user => user.email === this.state.data.empleado)?.name}</p>
								<p>Correo: {this.state.data.empleado}</p>
								<p>Teléfono: {this.state.usuarios.find(user => user.email === this.state.data.empleado)?.phone}</p>
								<p>
									<strong>Total:</strong> {Price(this.state.costo)}
								</p>
							</div>
						</div>
						<div className="column">
							<div className="contenido">
								<h3>prestado por</h3>
								<p>Nombre: {this.state.data.usuario?.name}</p>
								<p>Correo: {this.state.data.usuario?.email}</p>
								{this.state.type === 'dinero' && (
									<>
										<h3>Salida de dinero</h3>
										<p>Caja: {this.state.infoCajas.find(caja => caja.uuid === this.state.data.caja)?.name}</p>
										<p>Método de pago: {this.state.infoMetodos.find(metodo => metodo.uuid === this.state.data.metodo)?.name}</p>
									</>
								)}
							</div>
						</div>
					</div>
					<div className="divider"></div>

					{this.state.type === 'productos' && (
						<div className="productos">
							<div className="columns">
								<div className="column">
									<h3>Productos prestados</h3>
								</div>
							</div>
							<div className="divider"></div>
							<table className="table">
								<thead>
									<tr>
										<th>Producto</th>
										<th>Categroría</th>
										<th>Costo operación</th>
										<th>Cantidad prestada</th>
										<th>Incremento por unidad</th>
										<th>Costo total</th>
									</tr>
								</thead>
								<tbody>
									{this.state.productosAMostrar.map(pro => (
										<tr key={pro.uuid}>
											<td>
												<NavLink className="item-pro">
													{pro.image !== undefined ? (
														<img src={e.urlBucket + pro.image} alt={pro.name} />
													) : (
														<img src={require('../../img/defecto.jpg')} alt={pro.name} />
													)}
													<span>{pro.name}</span>
												</NavLink>
											</td>
											<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
											<td>{Price(pro.price_operation)}</td>
											<td>
												<Input type="number" placeholder="0" value={pro.quantity} readonly={true} />
											</td>
											<td>
												<Input type="number" placeholder="0" value={pro.cost} readonly={true} />
											</td>
											<td>
												<Input
													type="text"
													placeholder="0"
													value={Price(pro.cost * pro.quantity + pro.quantity * pro.price_operation)}
													readonly={true}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					<div className="divider"></div>
				</div>
			</>
		)
	}
}

export default withParams(Page)

import { Component } from 'react'
import Loader from 'componentes/loader'
import { Price, Levenshtein, Alert } from 'utiles/functions'
import Input from 'componentes/input'
import { NavLink } from 'react-router-dom'
import withParams from 'componentes/WithParams'
import Back from 'componentes/back'
import DB from 'utiles/db'
import e from 'utiles/settings'
const products = new DB('products')
const categorias = new DB('categories')
const dispatchthecollectors = new DB('dispatchthecollectors')
const users = new DB('users')
const productos = []
const cates = []
// TODO: en los catch de los métodos query, debería haber un Alert('¡Ooops! tenemos problemas de conexión', 'danger') o algo similar y tambien parar el cargador
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			cant: 0,
			avaluo_operation: 0,
			avaluo_costo: 0,
			avaluo_costo_contado: 0,
			avaluo_costo_operacion: 0,
			activos: 0,
			inactivos: 0,
			cantidades: [],
			description: '',
			route: '',
			date: '',
			products: [],
			way: {},
			ruta: '',
			cobradores: [],
			productos: []
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})
		users
			.query('#cava = :c AND #rol = :rol', { ':c': this.props.cava.uuid, ':rol': 'Cobrador' }, { '#cava': 'cava', '#rol': 'rol' })
			.then(data => {
				const cobradores = []
				for (const user of data.data.Items) {
					cobradores[user.email] = user.name
				}
				this.setState({ cobradores })
			})
			.catch(error => {
				console.error(error)
			})
		dispatchthecollectors
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				const cobrador = this.state.cobradores[data.data.Item.way.user] ? this.state.cobradores[data.data.Item.way.user] : 'N/A'
				this.setState({
					loading: false,
					data: data.data.Item,
					products: data.data.Item.products,
					avaluo_costo: data.data.Item.price_credito,
					avaluo_costo_operacion: data.data.Item.price_cost_operation,
					way: data.data.Item.way,
					cant: data.data.Item.quantity_products,
					ruta: data.data.Item.way.name + ' - ' + data.data.Item.way.day + ' - ' + cobrador
				})
				products
					.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
					.then(data => {
						const cantidades = []
						for (const pro of data.data.Items) {
							// validar si ya hay este producto en el antiguo despacho
							let cantidad = 0
							let cambio = false
							for (const product of this.state.products) {
								if (product.uuid === pro.uuid) {
									cantidad = product.quantity
									cambio = true
								}
							}
							cantidades.push({
								uuid: pro.uuid,
								cantidad,
								disponibles: pro.stock,
								cambio,
								image: pro.image,
								name: pro.name,
								precioCredito: pro.price_credito,
								precioContado: pro.price_contado,
								precioOperacion: pro.price_operation
							})
						}
						this.setState({
							loading: false,
							productos: data.data.Items,
							cantidades
						})
					})
					.catch(error => {
						console.error(error)
					})
			})
			.catch(error => {
				console.error(error)
			})
	}

	calcularWidgets() {
		let productosDespachar = 0
		let avaluoCredito = 0
		let avaluoCostoOperacion = 0
		for (const prodDespacho of this.state.cantidades) {
			if (prodDespacho.cambio === true) {
				productosDespachar += Number(prodDespacho.cantidad)
				avaluoCredito += prodDespacho.precioCredito * prodDespacho.cantidad
				avaluoCostoOperacion += prodDespacho.precioOperacion * prodDespacho.cantidad
			}
		}
		this.setState({
			cant: productosDespachar,
			avaluo_costo: avaluoCredito,
			avaluo_costo_operacion: avaluoCostoOperacion
		})
	}

	cantidadProducto(uuid, cantidades) {
		const input = document.getElementById(uuid)
		if (input !== null) {
			input.value = cantidades.find(product => product.uuid === uuid).cantidad
		}
		return cantidades.find(product => product.uuid === uuid).cantidad
	}

	restele(uuid) {
		const cantidades = this.state.cantidades
		const index = cantidades.findIndex(product => product.uuid === uuid)
		if (cantidades[index].cantidad > 0) {
			cantidades[index].cantidad--
			cantidades[index].cambio = true
			this.setState({
				cantidades
			})
			this.calcularWidgets()
		}
		if (cantidades[index].cantidad === 0) {
			cantidades[index].cambio = false
			this.calcularWidgets()
		}
	}

	sumele(uuid, stock) {
		const cantidades = this.state.cantidades
		const index = cantidades.findIndex(product => product.uuid === uuid)
		if (cantidades[index].cantidad < stock) {
			cantidades[index].cantidad++
			cantidades[index].cambio = true
			this.setState({
				cantidades
			})
			this.calcularWidgets()
		}
	}

	changeInput(uuid, stock) {
		const input = document.getElementById(uuid)
		if (!input) {
			this.calcularWidgets()
			return
		}
		const value = input.value
		const cantidades = this.state.cantidades
		const index = cantidades.findIndex(product => product.uuid === uuid)
		if (cantidades[index].cantidad !== value) {
			stock = parseInt(stock)
			if (value < stock) {
				cantidades[index].cantidad = value
				cantidades[index].cambio = true
				this.setState({
					cantidades
				})
			} else {
				cantidades[index].cantidad = stock
				cantidades[index].cambio = true
				this.setState({
					cantidades
				})
			}
		}
		this.calcularWidgets()
	}

	guardarAjuste() {
		const productosAjustados = []
		// construir arreglo de productos editados
		for (const product of this.state.cantidades) {
			if (product.cambio) {
				// si cambió el producto
				productosAjustados.push({
					uuid: product.uuid,
					name: product.name,
					image: product.image,
					disponibles: Number(product.disponibles),
					quantity: Number(product.cantidad),
					price_credito: Number(product.precioCredito)
				})
			}
		}

		this.setState({ loading: true }, async () => {
			const updated = await dispatchthecollectors.update({
				Key: { uuid: this.state.data.uuid },
				UpdateExpression: 'SET #p = :products, #pc = :price_credito, #po = :price_cost_operation, #q = :quantity_products',
				ExpressionAttributeValues: {
					':products': productosAjustados,
					':price_credito': Number(this.state.avaluo_costo),
					':price_cost_operation': Number(this.state.avaluo_costo_operacion),
					':quantity_products': Number(this.state.cant)
				},
				ExpressionAttributeNames: {
					'#p': 'products',
					'#pc': 'price_credito',
					'#po': 'price_cost_operation',
					'#q': 'quantity_products'
				},
				ReturnValues: 'UPDATED_NEW'
			})
			if (updated.status) {
				this.setState({ loading: false }, () => {
					this.props.navigate('dashboard/dispatchthecollectors')
					Alert('Despacho de cobrador editado correctamente.')
				})
			} else {
				this.setState({ loading: false }, () => {
					Alert('Algo falló, inténtalo nuevamente.', 'danger')
				})
			}
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Despacho de cobrador</h2>
						<span>Editar</span>
					</div>
					<div className="btns">
						<button className="btn" onClick={() => this.guardarAjuste()}>
							Editar despacho y notificar al cobrador
						</button>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Cantidad despacho</span>
							<strong>{this.state.cant}</strong>
						</div>
					</div>

					<div className="card">
						<div className="item-widget">
							<span>Avalúo precio crédito</span>
							<strong>{Price(this.state.avaluo_costo)}</strong>
						</div>
					</div>
					<div className="card hiden-responsive">
						<div className="item-widget">
							<span>Avalúo costo de operación</span>
							<strong>{Price(this.state.avaluo_costo_operacion)}</strong>
						</div>
					</div>
				</div>

				<div className="divider"></div>
				<div className="card">
					<div className="la_descriopcion">
						<Input type="text" title="Ruta" name="" value={this.state.ruta} readonly="readOnly" />
					</div>

					<div className="container-search">
						<div className="search">
							<input
								type="text"
								placeholder="Escribe algo.."
								onChange={event => {
									const val = event.target.value
									if (val.length > 2) {
										const produs = productos.filter(pr => {
											const name = pr.name.toLowerCase().split(' ')
											let coincidencia = false
											for (const palabra of name) {
												if (Levenshtein(palabra, val.toLowerCase()) < 3 || palabra.indexOf(val.toLowerCase()) > -1) {
													coincidencia = true
												}
											}
											return coincidencia
										})
										this.setState({ data: produs })
									} else {
										this.setState({ data: productos })
									}
								}}
							/>
							<button>
								<img src={require('../../img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Producto</th>
								<th>Cantidad despacho</th>
								<th className="hiden-responsive-2">Cantidad disponible al momento del despacho</th>
								<th className="hiden-responsive">Precio a crédito</th>
								<th className="hiden-responsive">Precio de operación</th>
							</tr>
						</thead>
						<tbody>
							{this.state.productos.map(pro => (
								<tr key={pro.uuid}>
									<td>
										<NavLink className="item-pro">
											{pro.image !== undefined ? (
												<img src={e.urlBucket + pro.image} alt={pro.name} />
											) : (
												<img src={require('../../img/defecto.jpg')} alt={pro.name} />
											)}
											<span>{pro.name}</span>
										</NavLink>
									</td>
									<td className="cantidadCombo">
										<span onClick={() => this.restele(pro.uuid)}>-</span>
										<Input
											type="number"
											title=""
											placeholder=""
											name="cantidad"
											id={pro.uuid}
											value={this.cantidadProducto(pro.uuid, this.state.cantidades)}
											change={() => this.changeInput(pro.uuid, pro.stock)}
										/>
										<span onClick={() => this.sumele(pro.uuid, pro.stock)}>+</span>
									</td>
									<td className="hiden-responsive-2">
										<p>{pro.stock}</p>
									</td>
									<td className="hiden-responsive">{Price(pro.price_credito)}</td>
									<td className="hiden-responsive">{Price(pro.price_operation)}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(Page)

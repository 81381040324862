import { configureStore } from '@reduxjs/toolkit'
import globalFlagsReducer from './globalFlags'
const store = configureStore({
	reducer: {
		globalFlags: globalFlagsReducer.reducer
	}
})

const d = store.dispatch
const storeManager = {
	// Global Flags
	// ------------
	setLoading: data => d(globalFlagsReducer.actions.setLoading(data))
}
export const { setLoading } = storeManager
export default store

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import Input from 'componentes/input'
import Modal from 'componentes/modal'
import e from 'utiles/settings'
import Back from 'componentes/back'
import { v4 as uuidv4 } from 'uuid'
import { Price, Alert, Confirm } from 'utiles/functions'
const providers = new DB('providers')
const typespents = new DB('typespents')
const products = new DB('products')
const categorias = new DB('categories')
const cashs = new DB('cashs')
const paymentmethods = new DB('paymentmethods')
const spents = new DB('spents')
const inventoryTweaks = new DB('inventory_tweaks')
const movBoxes = new DB('movBoxes')
const cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalProducts: false,
			modalCajas: false,
			modalPago: false,
			type: '',
			provider: '',
			description: '',
			types: [],
			providers: [],
			data: [],
			cajas: [],
			metodos: [],
			cajasSeleccionados: [],
			productosSeleccionados: {},
			productosAMostrar: [],
			cajasAMostrar: [],
			cantidadProductos: 0,
			valorAbono: '',
			DescripcionAbono: '',
			costoProductos: 0,
			Deuda: 0,
			Abonado: 0,
			cajasSeleccionada: {},
			metodoSeleccionado: {},
			numberfactura: '',
			totalDisponible: 0
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				for (const cat of data.data.Items) {
					cates[cat.uuid] = cat.name
				}
			})
			.catch(error => {
				console.error(error)
			})
		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ cajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ metodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		providers
			.query('#cava = :c AND #status = :status', { ':c': this.props.cava.uuid, ':status': true }, { '#cava': 'cava', '#status': 'status' })
			.then(data => {
				if (data.status) {
					const provedorsoskis = data.data.Items.map(type => {
						return { val: type.uuid, text: type.name }
					})
					provedorsoskis.unshift({ val: '', text: 'Selecciona un proveedor' })
					this.setState({ providers: provedorsoskis })
				}
			})
			.catch(error => {
				console.error(error)
			})
		products
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					this.setState({ data: data.data.Items })
				}
			})
			.catch(error => {
				console.error(error)
			})
		typespents
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					this.setState({
						loading: false,
						types: data.data.Items.map(type => {
							return { val: type.uuid, text: type.name }
						})
					})
				} else {
					this.setState({ loading: false })
				}
			})
			.catch(error => {
				console.error(error)
			})
	}

	// funciones de modal de seleccionar productos
	seleccionProductos(uuid) {
		const productosSeleccionados = this.state.productosSeleccionados
		if (productosSeleccionados[uuid] !== undefined) {
			delete productosSeleccionados[uuid]
		} else {
			productosSeleccionados[uuid] = true
		}
		this.setState({ productosSeleccionados })
		this.LoadSelectedProducts()
	}

	valorProducto(productosSeleccionados, uuid) {
		const productosSeleccionadosar = productosSeleccionados
		if (productosSeleccionadosar[uuid] === true) {
			return 'on'
		}
	}

	// cargar productos en la tabla
	LoadSelectedProducts() {
		const productosSeleccionados = this.state.productosSeleccionados
		const productosAMostrar = []
		for (const uuid in productosSeleccionados) {
			if (productosSeleccionados[uuid] === true) {
				const productoEncontrado = this.state.data.find(producto => producto.uuid === uuid)
				if (productoEncontrado) {
					productosAMostrar.push(productoEncontrado)
				}
			}
		}
		this.setState({ productosAMostrar })
	}

	// eliminar producto de la orden de compra
	deleteProductBuy(uuid) {
		// eliminar de la orden de compra
		const nuevosProductos = this.state.productosAMostrar.filter(producto => producto.uuid !== uuid)
		this.setState({ productosAMostrar: nuevosProductos })
		// eliminar el estado del check en la seleccion de productos
		this.seleccionProductos(uuid)
	}

	// datos de los productos en compra
	// cambiar cantidad
	changeQuantity(quantity, uuid) {
		let cantidadProductos = 0
		const productosAMostrar = this.state.productosAMostrar.map(product => {
			if (product.uuid === uuid) {
				product.quantity = quantity
			}
			if (product.quantity) {
				cantidadProductos += Number(product.quantity)
			}
			return product
		})
		this.setState({ productosAMostrar, cantidadProductos })
	}

	// cambiar costo total
	changeCost(cost, uuid) {
		let costoProductos = 0
		const productosAMostrar = this.state.productosAMostrar.map(product => {
			if (product.uuid === uuid) {
				product.cost = cost
			}
			if (product.cost) {
				costoProductos += Number(product.cost)
			}
			return product
		})
		this.setState({ productosAMostrar, costoProductos }, () => {
			this.calcularDeuda()
		})
	}

	// obtener la cantidad
	getValueQuantity(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.quantity || producto.quantity === 0) {
				return producto.quantity
			}
		}
	}

	// obtener lea costo
	getValueCost(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.cost) {
				return producto.cost
			}
		}
	}

	// valor unitario
	getValueUnitCost(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.cost && producto.quantity) {
				return Price(producto.cost / producto.quantity)
			}
		}
	}

	// consultar el total de cada caja
	totalCaja(caja) {
		let totalCaja = 0
		// recorro los metodos de pago
		for (const metodo of this.state.metodos) {
			if (caja[metodo.uuid]) {
				totalCaja += Number(caja[metodo.uuid].amount)
			}
		}
		return Price(totalCaja)
	}

	totalCajaMoney(caja) {
		let totalCaja = 0
		// recorro los metodos de pago
		for (const metodo of this.state.metodos) {
			if (caja[metodo.uuid]) {
				totalCaja += Number(caja[metodo.uuid].amount)
			}
		}
		return totalCaja
	}

	// seleccionar caja
	seleccionCaja(uuid) {
		const cajasSeleccionados = this.state.cajasSeleccionados
		if (cajasSeleccionados[uuid] !== undefined) {
			delete cajasSeleccionados[uuid]
		} else {
			cajasSeleccionados[uuid] = true
		}
		this.setState({ cajasSeleccionados })
		this.LoadSelectedCajas()
	}

	valorCaja(cajasSeleccionados, uuid) {
		const cajasSeleccionadosar = cajasSeleccionados
		if (cajasSeleccionadosar[uuid] === true) {
			return 'on'
		}
	}

	// listado de cajas
	LoadSelectedCajas() {
		const cajasSeleccionados = this.state.cajasSeleccionados
		const cajasAMostrar = []
		for (const uuid in cajasSeleccionados) {
			if (cajasSeleccionados[uuid] === true) {
				const productoEncontrado = this.state.cajas.find(producto => producto.uuid === uuid)
				if (productoEncontrado) {
					cajasAMostrar.push(productoEncontrado)
				}
			}
		}
		this.setState({ cajasAMostrar })
	}

	// seleccionar caja
	seleccionarCaja(cajasSeleccionada) {
		this.setState({ cajasSeleccionada, modalCajas: false, modalMetodos: true })
	}

	// seleccionar metodo
	seleccionarMetodoPago(metodoSeleccionado) {
		this.setState({ metodoSeleccionado, modalMetodos: false, modalPago: true })
	}

	// guardarAbono
	guardarAbono() {
		const cajasAMostrar = [...this.state.cajasAMostrar]
		const cajasSeleccionada = this.state.cajasSeleccionada
		const metodoSeleccionado = this.state.metodoSeleccionado
		const valorAbono = this.state.valorAbono
		const DescripcionAbono = this.state.DescripcionAbono

		const cajaConAbono = {
			...cajasSeleccionada,
			abono: {
				metodo: metodoSeleccionado,
				amount: valorAbono,
				description: DescripcionAbono
			}
		}

		cajasAMostrar.push(cajaConAbono)
		this.setState(
			{
				cajasAMostrar,
				modalPago: false,
				cajasSeleccionada: {},
				metodoSeleccionado: {},
				valorAbono: '',
				DescripcionAbono: ''
			},
			() => {
				this.calcularDeuda()
			}
		)
	}

	getDisponible() {
		if (this.state.metodoSeleccionado && this.state.cajasSeleccionada) {
			const metodoSeleccionadoUUID = this.state.metodoSeleccionado.uuid
			if (metodoSeleccionadoUUID in this.state.cajasSeleccionada) {
				const totalDispo = this.state.cajasSeleccionada[metodoSeleccionadoUUID].amount || Price(0)
				if (totalDispo !== this.state.totalDisponible) {
					this.setState({ totalDisponible: totalDispo })
				}
				return Price(totalDispo)
			}
		}
		return Price(0)
	}

	deleteAbono(index) {
		// Clona el array cajasAMostrar para no modificar el estado original directamente
		const cajasAMostrar = [...this.state.cajasAMostrar]

		// Utiliza el índice para eliminar el elemento correspondiente
		if (index >= 0 && index < cajasAMostrar.length) {
			cajasAMostrar.splice(index, 1) // Elimina 1 elemento en la posición 'index'

			// Actualiza el estado con la nueva matriz
			this.setState({ cajasAMostrar }, () => {
				this.calcularDeuda()
			})
		}
	}

	calcularDeuda() {
		let Deuda = this.state.costoProductos
		let Abonado = 0
		for (const caja of this.state.cajasAMostrar) {
			Deuda -= caja.abono.amount
			Abonado += Number(caja.abono.amount)
		}
		this.setState({ Deuda, Abonado })
	}

	// guardar

	guardarTodo() {
		// validar si hay programador
		if (this.state.provider !== '') {
			// validar si si hay productos en a orden
			if (this.state.costoProductos !== 0) {
				// validar si hay productos que tenagn en 0 el costo unidad
				let validadorProductos = 0
				for (const product of this.state.productosAMostrar) {
					if (!product.quantity || !product.cost) {
						validadorProductos++
					}
				}
				if (validadorProductos === 0) {
					// validar los abonos
					if (this.state.Abonado === 0) {
						Confirm('¿Quieres guardar la compra sin realizar abonos?', () => {
							// terminar compra sin abonos
							this.registrarenBD(false)
						})
					} else {
						// si hay abonos
						this.registrarenBD(true)
					}
				} else {
					Alert('Por favor ingresa la cantidad y el costo de los productos', 'danger', 4000)
				}
			} else {
				Alert('Por favor selecciona los productos que vas a ingresar', 'danger', 4000)
			}
		} else {
			Alert('Por favor selecciona el proveedor', 'danger', 4000)
		}
	}

	// validar el limite para las cajas
	validarMaximoAbono() {
		if (this.state.totalDisponible < this.state.valorAbono) {
			this.setState({ valorAbono: this.state.totalDisponible })
		}
	}

	registrarenBD(registrarAbonos) {
		this.setState({ loading: true }, async () => {
			const uuidGasto = uuidv4()
			// construir la info del usuario
			const usuarioAjuste = {
				email: this.props.user.email,
				name: this.props.user.name,
				rol: this.props.user.rol
			}
			const productosCompra = this.state.productosAMostrar.map(product => {
				return {
					uuid: product.uuid,
					image: product.image,
					category: product.category,
					name: product.name,
					price_credito: Number(product.price_credito),
					quantity: Number(product.quantity),
					stock: Number(product.stock),
					cost: Number(product.cost)
				}
			})
			// guardar gasto
			await spents.create({
				uuid: uuidGasto,
				cava: this.props.cava.uuid,
				rawmaterials: true,
				typespent: '',
				provider: this.state.provider,
				total: Number(this.state.costoProductos),
				description: this.state.description,
				products: productosCompra,
				abonado: Number(this.state.Abonado),
				nbill: this.state.numberfactura,
				user: usuarioAjuste
			})

			// guardar ajuste de inventario
			const productosAjustados = []
			// construir arreglo de productos editados
			for (const product of this.state.productosAMostrar) {
				productosAjustados.push({
					uuid: product.uuid,
					name: product.name,
					image: product.image,
					quantity_old: Number(product.stock),
					quantity_new: Number(product.quantity) + Number(product.stock),
					price_credito: Number(product.price_credito)
				})
			}

			// guardar ajuste de inventario
			await inventoryTweaks.create({
				uuid: uuidv4(),
				user: usuarioAjuste,
				description: 'Compra de materia prima',
				tweaks: productosAjustados,
				cava: this.props.cava.uuid
			})

			// ajustar cantidades en los productos
			for (const producto of productosAjustados) {
				await products.update({
					Key: { uuid: producto.uuid },
					UpdateExpression: 'SET #s = :stock',
					ExpressionAttributeValues: {
						':stock': Number(producto.quantity_new)
					},
					ExpressionAttributeNames: {
						'#s': 'stock'
					},
					ReturnValues: 'UPDATED_NEW'
				})
			}

			// guardar abonos
			if (registrarAbonos) {
				this.state.cajasAMostrar.map(async caja => {
					// registrar movimiento de caja
					await movBoxes.create({
						uuid: uuidv4(),
						cava: this.props.cava.uuid,
						caja_uuid: caja.uuid,
						metodo_uuid: caja.abono.metodo.uuid,
						gasto_uuid: uuidGasto,
						type: 'egreso',
						value: Number(caja.abono.amount),
						description: 'Compra de materia prima',
						voucher: caja.abono.description,
						user: usuarioAjuste
					})
					// actualizar total del metodo de pago en la caja
					const metodo = caja[caja.abono.metodo.uuid]
					const totalMetodo = Number(caja[caja.abono.metodo.uuid].amount) - Number(caja.abono.amount)
					metodo.amount = Number(totalMetodo)
					const expressionAttributeNames = {
						'#m': caja.abono.metodo.uuid
					}
					const expressionAttributeValues = {
						':m': metodo
					}
					await cashs.update({
						Key: { uuid: caja.uuid },
						UpdateExpression: `SET #m = :m`,
						ExpressionAttributeValues: expressionAttributeValues,
						ExpressionAttributeNames: expressionAttributeNames,
						ReturnValues: 'UPDATED_NEW'
					})
				})
			}
			this.setState({ loading: false })
			this.props.navigate('/dashboard/rawmaterials')
			Alert('Compra de materia prima realizada con éxito.')
		})
	}

	renderCajas() {
		return (
			<Modal active={this.state.modalCajas} className="lamodal">
				<table className="table">
					<thead>
						<tr>
							<th>Caja</th>
							<th>total</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.cajas.map(pro => (
							<tr key={pro.uuid}>
								<td>{pro.name}</td>
								<td>{this.totalCaja(pro)}</td>
								<td>
									{this.totalCajaMoney(pro) === 0 ? (
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
											}}
											className="btn-edit disabled">
											Selecionar
										</a>
									) : (
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.seleccionarCaja(pro)
											}}
											className="btn-edit">
											Selecionar
										</a>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalCajas: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	renderMetodosPago() {
		return (
			<Modal active={this.state.modalMetodos} className="lamodal">
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>
					<strong>Caja Seleccionada: {this.state.cajasSeleccionada.name}</strong>
				</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total compra: {Price(this.state.costoProductos)}</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total deuda: {Price(this.state.Deuda)}</p>
				<div className="divider"></div>
				<table className="table">
					<thead>
						<tr>
							<th>Método</th>
							<th>Disponible</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.metodos.map(metodo => (
							<>
								<tr>
									<td>{metodo.name}</td>
									{this.state.cajasSeleccionada[metodo.uuid] ? (
										<>
											<td>{Price(this.state.cajasSeleccionada[metodo.uuid].amount)}</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.seleccionarMetodoPago(metodo)
													}}
													className="btn-edit">
													Seleccionar
												</a>
											</td>
										</>
									) : (
										<>
											<td>0 COP</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
													}}
													className="btn-edit disabled">
													Seleccionar
												</a>
											</td>
										</>
									)}
								</tr>
							</>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalMetodos: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	renderPago() {
		return (
			<Modal active={this.state.modalPago} className="lamodal">
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>
					<strong>Caja Seleccionada: {this.state.cajasSeleccionada.name}</strong>
				</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total compra: {Price(this.state.costoProductos)}</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total deuda: {Price(this.state.Deuda)}</p>
				<div className="divider"></div>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>
					<strong>Método de pago Seleccionado: {this.state.metodoSeleccionado.name}</strong>
				</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Disponible: {this.getDisponible()}</p>
				<Input
					type="number"
					value={this.state.valorAbono}
					placeholder="0"
					title="Ingresa el valor del abono"
					change={value => {
						this.setState({ valorAbono: value }, () => this.validarMaximoAbono())
					}}
				/>
				<Input
					type="text"
					value={this.state.DescripcionAbono}
					placeholder="COMPROBANTE"
					title="Ingresa el comprobante del abono"
					change={value => {
						this.setState({ DescripcionAbono: value })
					}}
				/>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalPago: false })
						}}
						className="btn">
						Cerrar
					</a>
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.guardarAbono()
						}}
						className="btn">
						Guardar
					</a>
				</div>
			</Modal>
		)
	}

	renderProducts() {
		return (
			<Modal active={this.state.modalProducts} className="lamodal">
				<table className="table">
					<thead>
						<tr>
							<th>Producto</th>
							<th>categoría</th>
							<th>precio crédito</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.data.map(pro => (
							<tr key={pro.uuid}>
								<td>
									<NavLink className="item-pro">
										{pro.image !== undefined ? (
											<img src={e.urlBucket + pro.image} alt={pro.name} />
										) : (
											<img src={require('../../img/defecto.jpg')} alt={pro.name} />
										)}
										<span>{pro.name}</span>
									</NavLink>
								</td>
								<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
								<td>{Price(pro.price_credito)}</td>
								<td>
									<Input
										type="checkbox"
										id=""
										title=""
										active={this.valorProducto(this.state.productosSeleccionados, pro.uuid)}
										change={val => {
											this.seleccionProductos(pro.uuid)
										}}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalProducts: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.renderProducts()}
				{this.renderCajas()}
				{this.renderMetodosPago()}
				{this.renderPago()}
				<Loader active={this.state.loading} message="Cargando..." />

				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Compra de materia prima</h2>
						<span>nueva compra</span>
					</div>
					<div className="btns">
						<button
							className="btn"
							onClick={event => {
								event.preventDefault()
								this.guardarTodo()
							}}>
							Guardar
						</button>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Cantidad productos</span>
							<strong>{this.state.cantidadProductos}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total compra</span>
							<strong>{Price(this.state.costoProductos)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total deuda</span>
							<strong>{Price(this.state.Deuda)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total abonado</span>
							<strong>{Price(this.state.Abonado)}</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input
								type="select"
								title="Proveedor *"
								placeholder="Selecciona el proveedor"
								name="name"
								value={this.state.provider}
								change={val => this.setState({ provider: val })}
								options={this.state.providers}
							/>
							<Input
								type="text"
								title="N° de factura *"
								placeholder="Número de facura"
								name="name"
								value={this.state.numberfactura}
								change={val => this.setState({ numberfactura: val })}
							/>
						</div>
						<div className="column">
							<Input
								type="textarea"
								title="Descripción"
								placeholder=""
								name="name"
								value={this.state.description}
								change={val => this.setState({ description: val })}
							/>
						</div>
					</div>
					<hr />
					<br />
					<div className="productos">
						<div className="columns">
							<div className="column">
								<h3>Productos comprados</h3>
							</div>
							<div className="column" style={{ textAlign: 'right' }}>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.setState({ modalProducts: true })
									}}
									style={{ display: 'inline-block' }}
									className="btn">
									Seleccionar productos
								</a>
							</div>
						</div>
						<div className="divider"></div>
						<table className="table">
							<thead>
								<tr>
									<th>Producto</th>
									<th>Categroría</th>
									<th>Precio crédito</th>
									<th>Cantidad comprada</th>
									<th>Costo total</th>
									<th>Costo unidad</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.productosAMostrar.map(pro => (
									<tr key={pro.uuid}>
										<td>
											<NavLink className="item-pro">
												{pro.image !== undefined ? (
													<img src={e.urlBucket + pro.image} alt={pro.name} />
												) : (
													<img src={require('../../img/defecto.jpg')} alt={pro.name} />
												)}
												<span>{pro.name}</span>
											</NavLink>
										</td>
										<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
										<td>{Price(pro.price_credito)}</td>
										<td>
											<Input
												type="number"
												placeholder="0"
												value={this.getValueQuantity(pro.uuid)}
												change={value => {
													this.changeQuantity(value, pro.uuid)
												}}
											/>
										</td>
										<td>
											<Input
												type="number"
												placeholder="0"
												value={this.getValueCost(pro.uuid)}
												change={value => {
													this.changeCost(value, pro.uuid)
												}}
											/>
										</td>
										<td>
											<Input type="text" placeholder="0" value={this.getValueUnitCost(pro.uuid)} readonly={true} />
										</td>
										<td>
											<a
												href="#"
												onClick={event => {
													event.preventDefault()
													this.deleteProductBuy(pro.uuid)
												}}
												className="btn-edit">
												Eliminar
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<br />
					<hr />
					<br />
					<div className="productos">
						<div className="columns">
							<div className="column">
								<h3>Salida de dinero</h3>
							</div>
							<div className="column" style={{ textAlign: 'right' }}>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.setState({ modalCajas: true })
									}}
									className="btn"
									style={{ display: 'inline-block' }}>
									Seleccionar caja
								</a>
							</div>
						</div>
						<div className="divider"></div>
						<table className="table">
							<thead>
								<tr>
									<th>Caja</th>
									<th>Metodo</th>
									<th>Abono</th>
									<th>Comprobante</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.cajasAMostrar.map((caja, index) => (
									<tr key={caja.uuid}>
										<td>{caja.name}</td>
										<td>{caja.abono.metodo.name}</td>
										<td>{Price(caja.abono.amount)}</td>
										<td>{caja.abono.description}</td>
										<td>
											<a
												href="#"
												onClick={event => {
													event.preventDefault()
													this.deleteAbono(index)
												}}
												className="btn-edit">
												Eliminar
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

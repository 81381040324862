import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen.jsx'
import Loader from 'componentes/loader'
import { hoy, Confirm } from 'utiles/functions.js'
import DB from 'utiles/db.js'
const notifications = new DB('notifications')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			notifications: []
		}
	}

	componentDidMount() {
		this.init()
	}

	init() {
		notifications.query('#cava = :cava', { ':cava': this.props.cava.uuid }, { '#cava': 'uuid_cava' }).then(data => {
			if (data.status) {
				this.setState({ notifications: data.data.Items, loading: false })
			}
		})
	}

	deleteNotifications() {
		Confirm('¿Realmente deseas limpiar la bandeja de notificaciones?', () => {
			this.setState({ loading: true }, async () => {
				for (const notification of this.state.notifications) {
					await notifications.delete({ uuid: notification.uuid })
				}
				this.setState({ loading: true }, () => {
					this.init()
				})
			})
		})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<h2>Notificaciones</h2>
					</div>
					<div className="btns">
						<a
							href="#"
							className="btn"
							onClick={event => {
								event.preventDefault()
								this.deleteNotifications()
							}}>
							Limpiar bandeja de notificaciones
						</a>
					</div>
				</div>
				<div className="card">
					<div className="container-search">
						<div className="search">
							<input type="text" placeholder="Escribe algo.." />
							<button>
								<img src={require('../img/search.svg').default} width={20} height={20} />
							</button>
						</div>
					</div>
					<div className="notificaciones-container">
						{this.state.notifications.map((n, i) => (
							<div className="notification" key={i}>
								<div className="container-icon">
									<img src={require(`../img/sync.png`)} />
								</div>
								<div className="text">
									<h3>{n.title}</h3>
									<p>{n.message}</p>
									<span>{hoy(n.time, true)}</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

import e from './settings.js'
const AWS = require('aws-sdk')
AWS.config.update({
	region: e.region,
	accessKeyId: e.accessKeyId,
	secretAccessKey: e.secretAccessKey,
	dynamoDbCrc32: false
})
const Dynamo = new AWS.DynamoDB.DocumentClient()

class DB {
	constructor(table) {
		this.table = table
	}

	count(expression, data, names) {
		return new Promise(resolve => {
			Dynamo.scan(
				{
					TableName: this.table,
					FilterExpression: expression,
					ExpressionAttributeValues: data,
					ExpressionAttributeNames: names,
					Select: 'COUNT'
				},
				function (error, result) {
					if (error) {
						resolve({ status: false, data: error })
					} else {
						resolve({ status: true, data: result })
					}
				}
			)
		})
	}

	query(expression, data, names) {
		return new Promise(resolve => {
			Dynamo.scan(
				{
					TableName: this.table,
					FilterExpression: expression,
					ExpressionAttributeValues: data,
					ExpressionAttributeNames: names,
					ScanIndexForward: true,
					Limit: 20000
				},
				function (error, result) {
					if (error) {
						resolve({ status: false, data: error })
					} else {
						result.Items = result.Items.sort((a, b) => b.time - a.time)
						resolve({ status: true, data: result })
					}
				}
			)
		})
	}

	getAll() {
		return new Promise(resolve => {
			Dynamo.scan({ TableName: this.table, ScanIndexForward: true }, function (error, result) {
				if (error) {
					resolve({ status: false, data: error })
				} else {
					result.Items = result.Items.sort((a, b) => b.time - a.time)
					resolve({ status: true, data: result })
				}
			})
		})
	}

	// ✓
	get(data) {
		return new Promise(resolve => {
			Dynamo.get({ TableName: this.table, Key: data }, function (error, result) {
				if (error) {
					resolve({ status: false, data: error })
				} else {
					resolve({ status: true, data: result })
				}
			})
		})
	}

	// ✓
	id() {
		const CUSTOMEPOCH = 1300000000000 // artificial epoch
		const ts = new Date().getTime() - CUSTOMEPOCH // limit to recent
		return ts
	}

	// ✓
	create(registro) {
		return new Promise(resolve => {
			if (!('id' in registro)) {
				registro.time = this.id()
			}
			Dynamo.put({ TableName: this.table, Item: registro }, function (error, data) {
				if (error) {
					resolve({ status: false, data: error })
				} else {
					resolve({ status: true, data: registro })
				}
			})
		})
	}

	read(id) {
		return new Promise(resolve => {
			Dynamo.get({ TableName: this.table, Key: { id } }, function (error, data) {
				if (error) {
					resolve({ status: false, data: error })
				} else {
					resolve({ status: true, data })
				}
			})
		})
	}

	// ✓
	update(params) {
		return new Promise(resolve => {
			params.TableName = this.table
			Dynamo.update(params, (error, data) => {
				if (error) {
					resolve({ status: false, data: error })
				} else {
					resolve({ status: true, data })
				}
			})
		})
	}

	newUpdate(data, dKey) {
		return new Promise(resolve => {
			let expresion = 'SET '
			const values = {}
			const attributes = {}
			for (const key of Object.keys(data)) {
				const exp = key === 'name' ? '#n' : '#' + key
				const expV = key === 'name' ? ':n' : ':' + key
				expresion += exp + ' = ' + expV + ', '
				values[expV] = data[key]
				attributes[exp] = key
			}
			expresion = expresion.slice(0, -2)
			Dynamo.update(
				{
					Key: dKey,
					TableName: this.table,
					UpdateExpression: expresion,
					ExpressionAttributeValues: values,
					ExpressionAttributeNames: attributes
				},
				(error, data) => {
					if (error) {
						resolve({ status: false, data: error })
					} else {
						resolve({ status: true, data })
					}
				}
			)
		})
	}

	delete(data) {
		return new Promise(resolve => {
			Dynamo.delete({ TableName: this.table, Key: data }, (error, data) => {
				if (error) {
					resolve({ status: false, data: error })
				} else {
					resolve({ status: true, data })
				}
			})
		})
	}

	find(id) {}
}
export default DB

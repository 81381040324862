import { Component } from 'react'
import Loader from 'componentes/loader'
import { Price, Levenshtein } from 'utiles/functions'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
import './styles.css'
import e from 'utiles/settings'
const products = new DB('products')
const cava = new DB('cavas')
const categorias = new DB('categories')
let productsArr = []

class Catalogo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			loadOk: false,
			name: '',
			logo: '',
			catSelected: '',
			products: [],
			categories: []
		}
	}

	componentDidMount() {
		this.getCava()
			.then(() => {
				this.getCatalogo()
					.then(() => {
						this.getCategories()
					})
					.catch(error => {
						console.error(error)
					})
			})
			.catch(error => {
				console.error(error)
			})
	}

	getCava() {
		return new Promise(resolve => {
			cava.get({ uuid: this.props.params.uuid_cava }).then(data => {
				if (data.status) {
					if (data.data.Item.catalogo) {
						this.setState({
							logo: data.data.Item.logo,
							name: data.data.Item.name
						})
						resolve()
					} else {
						this.setState({ loading: false, loadOk: false })
						resolve('Catalogo no activo')
					}
				} else {
					this.setState({ loading: false, loadOk: false })
					resolve('Error al cargar cava')
				}
			})
		})
	}

	getCatalogo() {
		return new Promise(resolve => {
			products
				.query(
					'#cava = :c AND #status_catalogo = :sc AND #status = :st',
					{
						':c': this.props.params.uuid_cava,
						':sc': true,
						':st': true
					},
					{
						'#cava': 'cava',
						'#status_catalogo': 'status_catalogo',
						'#status': 'status'
					}
				)
				.then(data => {
					if (data.status) {
						this.setState({ products: data.data.Items })
						productsArr = data.data.Items
						resolve()
					} else {
						this.setState({ loading: false, loadOk: false })
						resolve()
					}
				})
		})
	}

	getCategories() {
		categorias
			.query('#cava = :c', { ':c': this.props.params.uuid_cava }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ loading: false, loadOk: true, categories: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
	}

	filterCategory(uuid) {
		if (uuid === this.state.catSelected) {
			this.setState({ products: productsArr, catSelected: '' })
		} else {
			const newProducts = productsArr.filter(p => p.category === uuid)
			this.setState({ products: newProducts, catSelected: uuid })
		}
	}

	render() {
		return (
			<>
				{this.state.loadOk ? (
					<>
						<div className="header-catalogo">
							<section className="header-main-container">
								<div className="header-top-container">
									<div className="header-left">
										{this.state.logo !== '' && (
											<a href="#">
												<img src={e.urlBucket + this.state.logo} />
											</a>
										)}
										<h1>{this.state.name}</h1>
									</div>
									<div className="header-center">
										<div className="finder-container">
											<input
												type="text"
												placeholder="Buscar..."
												onChange={event => {
													const val = event.target.value
													if (val.length > 2) {
														const produs = productsArr.filter(pr => {
															const name = pr.name.toLowerCase().split(' ')
															let coincidencia = false
															for (const palabra of name) {
																if (Levenshtein(palabra, val.toLowerCase()) < 3 || palabra.indexOf(val.toLowerCase()) > -1) {
																	coincidencia = true
																}
															}
															return coincidencia
														})
														this.setState({ products: produs })
													} else {
														this.setState({ products: productsArr })
													}
												}}
											/>
											<a href="#">Buscar</a>
										</div>
									</div>
								</div>
								{this.state.categories.length > 0 && (
									<div className="header-bottom-container">
										<div className="nav-catalogo">
											<ul>
												{this.state.categories.map(c => (
													<li key={c.uuid}>
														<a
															href="#"
															className={`${this.state.catSelected === c.uuid ? 'active' : ''}`}
															onClick={e => {
																e.preventDefault()
																this.filterCategory(c.uuid)
															}}>
															{c.name}
														</a>
													</li>
												))}
											</ul>
										</div>
									</div>
								)}
							</section>
						</div>
						<div className="ancho">
							<h1 className="title-page-catalogo" style={{ marginBottom: 20 }}>
								Catálogo de productos
							</h1>
							<main className="products-main">
								{this.state.products.map(p => (
									<div className="product-container" key={p.uuid}>
										{Number(p.stock) > 0 && <p className="status-product">En stock</p>}
										{p.image !== undefined ? (
											<img src={e.urlBucket + p.image} alt={p.name} />
										) : (
											<img src={require('../../img/defecto.jpg')} alt={p.name} />
										)}
										<h2>{p.name}</h2>
										<span>Precio de contado: {Price(p.price_contado)}</span>
										<span>Precio a crédito: {Price(p.price_credito)}</span>
									</div>
								))}
							</main>
						</div>
					</>
				) : (
					<>
						{this.state.loading ? (
							<Loader active={this.state.loading} />
						) : (
							<div className="page404">
								<h1>404</h1>
								<p>Página no encontrada</p>
							</div>
						)}
					</>
				)}
			</>
		)
	}
}
export default withParams(Catalogo)

import { Component } from 'react'
import AnimateScreen from 'componentes/AnimateScreen'
import Loader from 'componentes/loader'
import { getDate } from 'utiles/functions'
import { NavLink } from 'react-router-dom'
import Back from 'componentes/back'
import DB from 'utiles/db'
const inventoryTweaks = new DB('inventory_tweaks')
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			data: [],
			cant: 0,
			avaluo_operation: 0,
			avaluo_costo: 0,
			activos: 0,
			inactivos: 0
		}
	}

	componentDidMount() {
		inventoryTweaks
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({
					data: data.data.Items
				})
			})
			.catch(error => {
				console.error(error)
			})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Ajustes de inventario</h2>
						<span>Lista</span>
					</div>
					<div className="btns">
						<NavLink to="/dashboard/inventory_tweaks/create" className="btn">
							Nuevo ajuste
						</NavLink>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="container-search"></div>
					<table className="table">
						<thead>
							<tr>
								<th>Usuario</th>
								<th>Descripción</th>
								<th>Fecha</th>
								<th>Acciones</th>
							</tr>
						</thead>
						<tbody>
							{this.state.data.map(tweak => (
								<tr key={tweak.uuid}>
									<td>{tweak.user.name}</td>
									<td>{tweak.description}</td>
									<td>{getDate(tweak.time).toLocaleString()}</td>

									<td>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('/dashboard/inventory_tweaks/show/' + tweak.uuid)
											}}
											className="btn-edit">
											Ver
										</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default AnimateScreen(Page)

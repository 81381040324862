import { Component } from 'react'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert, uploadImage, deleteImage } from 'utiles/functions'
import withParams from 'componentes/WithParams'
import DB from 'utiles/db'
import Back from 'componentes/back'
const categorias = new DB('categories')
const productos = new DB('products')
let cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			name: '',
			price_cost: '',
			price_operation: '',
			price_contado: '',
			price_credito: '',
			stock: '',
			umbral: '',
			category: '',
			description: '',
			old_image: null,
			image: null,
			status_catalogo: true,
			status: true
		}
	}

	componentDidMount() {
		categorias
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				cates = data.data.Items.map(cat => {
					return { val: cat.uuid, text: cat.name }
				})
			})
			.catch(error => {
				console.error(error)
			})
		productos
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				if (data.status) {
					this.setState({
						loading: false,
						name: data.data.Item.name,
						price_cost: data.data.Item.price_cost,
						price_operation: data.data.Item.price_operation,
						price_contado: data.data.Item.price_contado,
						price_credito: data.data.Item.price_credito,
						stock: data.data.Item.stock,
						umbral: data.data.Item.umbral,
						category: data.data.Item.category,
						description: data.data.Item.description,
						old_image: data.data.Item.image,
						status_catalogo: data.data.Item.status_catalogo,
						status: data.data.Item.status
					})
				} else {
					Alert('¡Ooops!, tenemos un problemas de conexión, intenta de nuevo', 'danger')
				}
			})
			.catch(error => {
				console.error(error)
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (
			data.get('name') !== '' &&
			data.get('price_cost') !== '' &&
			data.get('price_operation') !== '' &&
			data.get('price_contado') !== '' &&
			data.get('price_credito') !== '' &&
			data.get('stock') !== ''
		) {
			let expresion =
				'SET #n = :name, #price_cost = :price_cost, #price_operation = :price_operation, #price_contado = :price_contado, #price_credito = :price_credito, #umbral = :umbral, #category = :category, #description = :description, #status_catalogo = :status_catalogo, #s = :status'
			const values = {
				':name': data.get('name'),
				':price_cost': Number(data.get('price_cost')),
				':price_operation': Number(data.get('price_operation')),
				':price_contado': Number(data.get('price_contado')),
				':price_credito': Number(data.get('price_credito')),
				':umbral': Number(data.get('umbral')),
				':category': data.get('category'),
				':description': data.get('description'),
				':status_catalogo': this.state.status_catalogo,
				':status': this.state.status
			}
			const attributes = {
				'#n': 'name',
				'#price_cost': 'price_cost',
				'#price_operation': 'price_operation',
				'#price_contado': 'price_contado',
				'#price_credito': 'price_credito',
				'#umbral': 'umbral',
				'#category': 'category',
				'#description': 'description',
				'#status_catalogo': 'status_catalogo',
				'#s': 'status'
			}
			this.setState({ loading: true }, async () => {
				if (this.state.image !== null) {
					const url = await uploadImage(this.state.image)
					attributes['#i'] = 'image'
					values[':image'] = url
					expresion += ', #i = :image'
					if (this.state.old_image !== null && this.state.old_image !== undefined) {
						await deleteImage(this.state.old_image)
					}
				}

				await productos.update({
					Key: { uuid: this.props.params.uuid },
					UpdateExpression: expresion,
					ExpressionAttributeValues: values,
					ExpressionAttributeNames: attributes,
					ReturnValues: 'UPDATED_NEW'
				})
				this.setState({ loading: false })
				this.props.navigate('/dashboard/products')
				Alert('Producto actualizado correctamente.')
			})
		} else {
			Alert('Los campos con asterisco (*) son obligatorios.', 'danger', 7000)
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Productos</h2>
						<span>Editar producto</span>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Nombre del producto *" placeholder="Ingresa el nombre del producto" value={this.state.name} name="name" />
							<Input
								type="number"
								title="Precio costo *"
								placeholder="Ingresa el precio costo del producto"
								value={this.state.price_cost}
								change={val => {
									this.setState({ price_cost: val })
								}}
								name="price_cost"
							/>
							<Input
								type="number"
								title="Precio de operación *"
								placeholder="Ingresa el precio de operación"
								value={this.state.price_operation}
								change={val => {
									this.setState({ price_operation: val })
								}}
								name="price_operation"
							/>
							<Input
								type="number"
								title="Precio de contado *"
								placeholder="Ingresa el precio de contado"
								value={this.state.price_contado}
								change={val => {
									this.setState({ price_contado: val })
								}}
								name="price_contado"
							/>
							<Input
								type="number"
								title="Precio a crédito *"
								placeholder="Ingresa el precio a crédito"
								value={this.state.price_credito}
								change={val => {
									this.setState({ price_credito: val })
								}}
								name="price_credito"
							/>
							<Input
								type="number"
								title="Cantidad en inventario *"
								placeholder="Ingresa la cantidad de inventario"
								value={this.state.stock}
								name="stock"
								readonly="readOnly"
							/>
						</div>
						<div className="column">
							<Input
								type="image"
								img={this.state.old_image}
								name="image"
								change={data => this.setState({ image: data })}
								title="Imagen del producto"
							/>
							<Input
								type="number"
								title="Umbral de pocas existencias"
								placeholder="Ingresa el umbral de pocas existencias"
								value={this.state.umbral}
								change={val => {
									this.setState({ umbral: val })
								}}
								name="umbral"
							/>
							<Input type="select" value={this.state.category} name="category" title="Categoria del producto" options={cates} />
							<Input
								type="textarea"
								title="Descripción del producto"
								value={this.state.description}
								name="description"
								placeholder="Ingresa la ddescripción del producto"
							/>
							<Input
								type="checkbox"
								id="status"
								title="Estado (Inactivo / Activo)"
								active={this.state.status}
								change={val => {
									this.setState({ status: val })
								}}
							/>
							<Input
								type="checkbox"
								id="status_catalogo"
								title="Visible en el catálogo"
								active={this.state.status_catalogo}
								change={val => {
									this.setState({ status_catalogo: val })
								}}
							/>
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(Page)

import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import DB from 'utiles/db'
import Input from 'componentes/input'
import Modal from 'componentes/modal'
import e from 'utiles/settings'
import withParams from 'componentes/WithParams'
import { v4 as uuidv4 } from 'uuid'
import { Price, Alert, hoy } from 'utiles/functions'
const providers = new DB('providers')
const cashs = new DB('cashs')
const paymentmethods = new DB('paymentmethods')
const spents = new DB('spents')
const movBoxes = new DB('movBoxes')
const cates = []

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			modalCajas: false,
			modalPago: false,
			modalAbono: false,
			type: '',
			provider: '',
			description: '',
			types: [],
			providers: [],
			data: [],
			cajas: [],
			metodos: [],
			cajasSeleccionados: [],
			productosSeleccionados: {},
			productosAMostrar: [],
			cajasAMostrar: [],
			movimientosAnteriores: [],
			cantidadProductos: 0,
			valorAbono: '',
			DescripcionAbono: '',
			costoProductos: 0,
			Deuda: 0,
			Abonado: 0,
			cajasSeleccionada: {},
			metodoSeleccionado: {},
			compra_materia_prima: {},
			infomodalabono: {},
			totalDisponible: 0
		}
	}

	componentDidMount() {
		cashs
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ cajas: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		paymentmethods
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				this.setState({ metodos: data.data.Items })
			})
			.catch(error => {
				console.error(error)
			})
		providers
			.query('#cava = :c', { ':c': this.props.cava.uuid }, { '#cava': 'cava' })
			.then(data => {
				if (data.status) {
					this.setState({ providers: data.data.Items })
				}
			})
			.catch(error => {
				console.error(error)
			})

		// consulto el gasto
		spents
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				this.setState({
					loading: false,
					compra_materia_prima: data.data.Item,
					cantidadProductos: data.data.Item.products.length,
					Deuda: Number(data.data.Item.total) - Number(data.data.Item.abonado ? data.data.Item.abonado : 0),
					Abonado: data.data.Item.abonado ? data.data.Item.abonado : 0,
					costoProductos: data.data.Item.total,
					productosAMostrar: data.data.Item.products
				})
				// consulto los abonos a el pago
				movBoxes
					.query('#gasto_uuid = :g', { ':g': data.data.Item.uuid }, { '#gasto_uuid': 'gasto_uuid' })
					.then(movimientos => {
						if (movimientos.status) {
							this.setState({ movimientosAnteriores: movimientos.data.Items }, () => {
								this.abonosAnteriores()
							})
						}
					})
					.catch(error => {
						console.error(error)
					})
			})
			.catch(error => {
				console.error(error)
				this.setState({ loading: false }, () => {
					Alert('¡Ooops!, tenemos problemas de conexión, intentalo de nuevo', 'danger')
				})
			})
	}

	// cargar los abonos realizados en las salidas de dinero
	abonosAnteriores() {
		const movimientosAnteriores = [...this.state.movimientosAnteriores]
		const cajasAMostrar = []
		for (const movimiento of movimientosAnteriores) {
			// consulto la caja
			const caja = this.state.cajas.find(caja => caja.uuid === movimiento.caja_uuid)
			if (caja) {
				const cajaConAbono = { ...caja }
				// añado el aboono a la caja
				const metodoski = this.state.metodos.find(metodo => metodo.uuid === movimiento.metodo_uuid)
				cajaConAbono.abono = {
					amount: movimiento.value,
					metodo: metodoski,
					date: hoy(movimiento.time),
					user: movimiento.user,
					uuid: movimiento.uuid
				}
				// defino un boolean para validar si el abono es antiguo o nuevo, los antiguos no se pueden eliminar ni nada
				cajaConAbono.abonoAntiguo = true
				cajasAMostrar.push(cajaConAbono)
			}
		}
		this.setState({ cajasAMostrar })
	}

	// obtener la cantidad
	getValueQuantity(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.quantity) {
				return producto.quantity
			}
		}
	}

	// obtener lea costo
	getValueCost(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.cost) {
				return producto.cost
			}
		}
	}

	// valor unitario
	getValueUnitCost(uuid) {
		const producto = this.state.productosAMostrar.find(producto => producto.uuid === uuid)
		if (producto) {
			if (producto.cost && producto.quantity) {
				return Price(producto.cost / producto.quantity)
			}
		}
	}

	// consultar el total de cada caja
	totalCaja(caja) {
		let totalCaja = 0
		// recorro los metodos de pago
		for (const metodo of this.state.metodos) {
			if (caja[metodo.uuid]) {
				totalCaja += Number(caja[metodo.uuid].amount)
			}
		}
		return Price(totalCaja)
	}

	totalCajaMoney(caja) {
		let totalCaja = 0
		// recorro los metodos de pago
		for (const metodo of this.state.metodos) {
			if (caja[metodo.uuid]) {
				totalCaja += Number(caja[metodo.uuid].amount)
			}
		}
		return totalCaja
	}

	valorCaja(cajasSeleccionados, uuid) {
		const cajasSeleccionadosar = cajasSeleccionados
		if (cajasSeleccionadosar[uuid] === true) {
			return 'on'
		}
	}

	// seleccionar caja
	seleccionarCaja(cajasSeleccionada) {
		this.setState({ cajasSeleccionada, modalCajas: false, modalMetodos: true })
	}

	// seleccionar metodo
	seleccionarMetodoPago(metodoSeleccionado) {
		this.setState({ metodoSeleccionado, modalMetodos: false, modalPago: true })
	}

	// guardarAbono
	guardarAbono() {
		const cajasAMostrar = [...this.state.cajasAMostrar]
		const cajasSeleccionada = this.state.cajasSeleccionada
		const metodoSeleccionado = this.state.metodoSeleccionado
		const valorAbono = this.state.valorAbono
		const DescripcionAbono = this.state.DescripcionAbono

		const cajaConAbono = {
			...cajasSeleccionada,
			abonoAntiguo: false,
			abono: {
				metodo: metodoSeleccionado,
				amount: Number(valorAbono),
				date: hoy(),
				description: DescripcionAbono
			}
		}

		cajasAMostrar.unshift(cajaConAbono)
		this.setState(
			{
				cajasAMostrar,
				modalPago: false,
				cajasSeleccionada: {},
				metodoSeleccionado: {},
				valorAbono: '',
				DescripcionAbono: ''
			},
			() => {
				this.calcularDeuda()
			}
		)
	}

	getDisponible() {
		if (this.state.metodoSeleccionado && this.state.cajasSeleccionada) {
			const metodoSeleccionadoUUID = this.state.metodoSeleccionado.uuid
			if (metodoSeleccionadoUUID in this.state.cajasSeleccionada) {
				const totalDispo = this.state.cajasSeleccionada[metodoSeleccionadoUUID].amount || Price(0)
				if (totalDispo !== this.state.totalDisponible) {
					this.setState({ totalDisponible: totalDispo })
				}
				return Price(totalDispo)
			}
		}
		return Price(0)
	}

	// validar el limite para las cajas
	validarMaximoAbono() {
		if (this.state.totalDisponible < this.state.valorAbono) {
			this.setState({ valorAbono: this.state.totalDisponible })
		}
	}

	deleteAbono(index) {
		// Clona el array cajasAMostrar para no modificar el estado original directamente
		const cajasAMostrar = [...this.state.cajasAMostrar]

		// Utiliza el índice para eliminar el elemento correspondiente
		if (index >= 0 && index < cajasAMostrar.length) {
			cajasAMostrar.splice(index, 1) // Elimina 1 elemento en la posición 'index'

			// Actualiza el estado con la nueva matriz
			this.setState({ cajasAMostrar }, () => {
				this.calcularDeuda()
			})
		}
	}

	calcularDeuda() {
		let Deuda = this.state.costoProductos
		let Abonado = 0
		for (const caja of this.state.cajasAMostrar) {
			Deuda -= caja.abono.amount
			Abonado += Number(caja.abono.amount)
		}
		this.setState({ Deuda, Abonado })
	}

	// guardar

	guardarTodo() {
		// validar los abonos
		if (this.state.Abonado === 0) {
			Alert('Debes realizar abonos para poder guardar')
		} else {
			// si hay abonos
			this.registrarenBD(true)
		}
	}

	registrarenBD() {
		this.setState({ loading: true }, async () => {
			const uuidGasto = this.state.compra_materia_prima.uuid
			const usuarioAjuste = {
				email: this.props.user.email,
				name: this.props.user.name,
				rol: this.props.user.rol
			}

			// guardar gasto
			await spents.update({
				Key: { uuid: uuidGasto },
				UpdateExpression: `SET #m = :m`,
				ExpressionAttributeValues: {
					':m': Number(this.state.Abonado)
				},
				ExpressionAttributeNames: {
					'#m': 'abonado'
				},
				ReturnValues: 'UPDATED_NEW'
			})

			// guardar abonos

			this.state.cajasAMostrar.map(async caja => {
				if (!caja.abonoAntiguo) {
					// registrar movimiento de caja
					await movBoxes.create({
						uuid: uuidv4(),
						cava: this.props.cava.uuid,
						caja_uuid: caja.uuid,
						metodo_uuid: caja.abono.metodo.uuid,
						gasto_uuid: uuidGasto,
						type: 'egreso',
						value: Number(caja.abono.amount),
						description: 'Abono por compra de materia prima',
						voucher: caja.abono.description,
						user: usuarioAjuste
					})
					// actualizar total del metodo de pago en la caja
					const metodo = caja[caja.abono.metodo.uuid]
					const totalMetodo = Number(caja[caja.abono.metodo.uuid].amount) - Number(caja.abono.amount)
					metodo.amount = Number(totalMetodo)
					const expressionAttributeNames = {
						'#m': caja.abono.metodo.uuid
					}
					const expressionAttributeValues = {
						':m': metodo
					}
					await cashs.update({
						Key: { uuid: caja.uuid },
						UpdateExpression: `SET #m = :m`,
						ExpressionAttributeValues: expressionAttributeValues,
						ExpressionAttributeNames: expressionAttributeNames,
						ReturnValues: 'UPDATED_NEW'
					})
				}
			})

			this.setState({ loading: false })
			this.props.navigate('/dashboard/rawmaterials')
			Alert('Abonos de materia prima realizada con éxito.')
		})
	}

	renderCajas() {
		return (
			<Modal active={this.state.modalCajas} className="lamodal">
				<table className="table">
					<thead>
						<tr>
							<th>Caja</th>
							<th>total</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.cajas.map(pro => (
							<tr key={pro.uuid}>
								<td>{pro.name}</td>
								<td>{this.totalCaja(pro)}</td>
								<td>
									{this.totalCajaMoney(pro) === 0 ? (
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
											}}
											className="btn-edit disabled">
											Selecionar
										</a>
									) : (
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.seleccionarCaja(pro)
											}}
											className="btn-edit">
											Selecionar
										</a>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalCajas: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	renderMetodosPago() {
		return (
			<Modal active={this.state.modalMetodos} className="lamodal">
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>
					<strong>Caja Seleccionada: {this.state.cajasSeleccionada.name}</strong>
				</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total compra: {Price(this.state.costoProductos)}</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total deuda: {Price(this.state.Deuda)}</p>
				<div className="divider"></div>
				<table className="table">
					<thead>
						<tr>
							<th>Método</th>
							<th>Disponible</th>
							<th>Seleccionar</th>
						</tr>
					</thead>
					<tbody>
						{this.state.metodos.map(metodo => (
							<>
								<tr>
									<td>{metodo.name}</td>
									{this.state.cajasSeleccionada[metodo.uuid] ? (
										<>
											<td>{Price(this.state.cajasSeleccionada[metodo.uuid].amount)}</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.seleccionarMetodoPago(metodo)
													}}
													className="btn-edit">
													Seleccionar
												</a>
											</td>
										</>
									) : (
										<>
											<td>0 COP</td>
											<td>
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
													}}
													className="btn-edit disabled">
													Seleccionar
												</a>
											</td>
										</>
									)}
								</tr>
							</>
						))}
					</tbody>
				</table>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalMetodos: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	renderPago() {
		return (
			<Modal active={this.state.modalPago} className="lamodal">
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>
					<strong>Caja Seleccionada: {this.state.cajasSeleccionada.name}</strong>
				</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total compra: {Price(this.state.costoProductos)}</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Total deuda: {Price(this.state.Deuda)}</p>
				<div className="divider"></div>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>
					<strong>Método de pago Seleccionado: {this.state.metodoSeleccionado.name}</strong>
				</p>
				<p style={{ textAlign: 'left', marginBottom: '10px' }}>Disponible: {this.getDisponible()}</p>
				<Input
					type="number"
					value={this.state.valorAbono}
					placeholder="0"
					title="Ingresa el valor del abono"
					change={value => {
						this.setState({ valorAbono: value }, () => this.validarMaximoAbono())
					}}
				/>
				<Input
					type="text"
					value={this.state.DescripcionAbono}
					placeholder="COMPROBANTE"
					title="Ingresa el comprobante del abono"
					change={value => {
						this.setState({ DescripcionAbono: value })
					}}
				/>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalPago: false })
						}}
						className="btn">
						Cerrar
					</a>
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.guardarAbono()
						}}
						className="btn">
						Guardar
					</a>
				</div>
			</Modal>
		)
	}

	renderViewAbono() {
		return (
			<Modal active={this.state.modalAbono} className="lamodal">
				<div className="contenido">
					{this.state.infomodalabono.name && (
						<>
							<div className="columns">
								<div className="column">
									<h3>Abono</h3>
									<div className="divider"></div>
									<p>
										<strong>Caja: </strong>
										{this.state.infomodalabono.name}
									</p>
									<p>
										<strong>Método: </strong>
										{this.state.infomodalabono.abono.metodo.name}
									</p>
									<p>
										<strong>Abono: </strong>
										{Price(this.state.infomodalabono.abono.amount)}
									</p>
									<p>
										<strong>Fecha: </strong>
										{this.state.infomodalabono.abono.date}
									</p>
									<a
										href="#"
										onClick={event => {
											event.preventDefault()
											this.props.navigate('/dashboard/cashMovements/edit/' + this.state.infomodalabono.abono.uuid)
										}}
										className="btn-edit">
										Ver usuario
									</a>
								</div>
								<div className="column">
									<p>
										<strong>Usuario </strong>
									</p>
									<div className="divider"></div>
									{this.state.infomodalabono.abono.user.name && (
										<>
											<p>
												<strong>Nombre: </strong>
												{this.state.infomodalabono.abono.user.name}
											</p>
											<p>
												<strong>Correo: </strong>
												{this.state.infomodalabono.abono.user.email}
											</p>
											<a
												href="#"
												onClick={event => {
													event.preventDefault()
													this.props.navigate('/dashboard/users/edit/' + this.state.infomodalabono.abono.user.email)
												}}
												className="btn-edit">
												Ver movimiento
											</a>
										</>
									)}
								</div>
							</div>
						</>
					)}
				</div>
				<div className="divider"></div>
				<div className="btns-flex">
					<a
						href="#"
						onClick={event => {
							event.preventDefault()
							this.setState({ modalAbono: false })
						}}
						className="btn">
						Cerrar
					</a>
				</div>
			</Modal>
		)
	}

	render() {
		return (
			<>
				{this.renderCajas()}
				{this.renderMetodosPago()}
				{this.renderPago()}
				{this.renderViewAbono()}
				<Loader active={this.state.loading} message="Cargando..." />

				<div className="title-page">
					<div className="title">
						<NavLink to="/dashboard/rawmaterials/" className="back">
							<img src={require('../../img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Compra de materia prima</h2>
						<span>Editar compra</span>
					</div>
					<div className="btns">
						<button
							className="btn"
							onClick={event => {
								event.preventDefault()
								this.guardarTodo()
							}}>
							Guardar
						</button>
					</div>
				</div>
				<div className="grid g5">
					<div className="card">
						<div className="item-widget">
							<span>Cantidad productos</span>
							<strong>{this.state.cantidadProductos}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total compra</span>
							<strong>{Price(this.state.costoProductos)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total deuda</span>
							<strong>{Price(this.state.Deuda)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Total abonado</span>
							<strong>{Price(this.state.Abonado)}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Estado</span>
							<strong className={Number(this.state.Deuda) === 0 ? 'EstadoPagada' : 'EstadoPendiente'}>
								{Number(this.state.Deuda) === 0 ? 'Pagada' : 'Pendiente'}
							</strong>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="N° de factura *" name="name" value={this.state.compra_materia_prima.nbill} readonly={true} />
							<div className="contenido">
								<h3>Proveedor</h3>
								<p>
									<strong>Nombre:</strong> {this.state.providers.find(proveedor => proveedor.uuid === this.state.compra_materia_prima.provider)?.name}
								</p>
								<p>
									<strong>NIT: </strong> {this.state.providers.find(proveedor => proveedor.uuid === this.state.compra_materia_prima.provider)?.doc}
								</p>
								<p>
									<strong>Teléfono: </strong>{' '}
									{this.state.providers.find(proveedor => proveedor.uuid === this.state.compra_materia_prima.provider)?.phone}
								</p>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.props.navigate('/dashboard/providers/edit/' + this.state.compra_materia_prima.provider)
									}}
									className="btn-edit">
									Ver proveedor
								</a>
							</div>
						</div>
						<div className="column">
							<Input
								type="textarea"
								title="Descripción"
								placeholder=""
								name="name"
								value={this.state.compra_materia_prima.description}
								readonly={true}
							/>
							<div className="contenido">
								<h3>Usuario</h3>
								{this.state.compra_materia_prima.user && (
									<>
										<p>
											<strong>Nombre:</strong> {this.state.compra_materia_prima.user.name}
										</p>
										<p>
											<strong>Correo:</strong> {this.state.compra_materia_prima.user.email}
										</p>
										<a
											href="#"
											onClick={event => {
												event.preventDefault()
												this.props.navigate('/dashboard/users/edit/' + this.state.compra_materia_prima.user.email)
											}}
											className="btn-edit">
											Ver usuario
										</a>
									</>
								)}
							</div>
						</div>
					</div>
					<hr />
					<br />
					<div className="productos">
						<div className="columns">
							<div className="column">
								<h3>Productos comprados</h3>
							</div>
						</div>
						<div className="divider"></div>
						<table className="table">
							<thead>
								<tr>
									<th>Producto</th>
									<th>Categroría</th>
									<th>Precio crédito</th>
									<th>Cantidad comprada</th>
									<th>Costo total</th>
									<th>Costo unidad</th>
								</tr>
							</thead>
							<tbody>
								{this.state.productosAMostrar.map(pro => (
									<tr key={pro.uuid}>
										<td>
											<NavLink className="item-pro">
												{pro.image !== undefined ? (
													<img src={e.urlBucket + pro.image} alt={pro.name} />
												) : (
													<img src={require('../../img/defecto.jpg')} alt={pro.name} />
												)}
												<span>{pro.name}</span>
											</NavLink>
										</td>
										<td>{pro.category ? cates[pro.category] : 'N/A'}</td>
										<td>{Price(pro.price_credito)}</td>
										<td>
											<Input type="number" value={this.getValueQuantity(pro.uuid)} readonly={true} />
										</td>
										<td>
											<Input type="text" value={Price(this.getValueCost(pro.uuid))} readonly={true} />
										</td>
										<td>
											<Input type="text" value={this.getValueUnitCost(pro.uuid)} readonly={true} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<br />
					<hr />
					<br />
					<div className="productos">
						<div className="columns">
							<div className="column">
								<h3>Salida de dinero</h3>
							</div>
							<div className="column" style={{ textAlign: 'right' }}>
								<a
									href="#"
									onClick={event => {
										event.preventDefault()
										this.setState({ modalCajas: true })
									}}
									style={{ display: 'inline-block' }}
									className="btn">
									Seleccionar caja
								</a>
							</div>
						</div>
						<div className="divider"></div>
						<table className="table">
							<thead>
								<tr>
									<th>Caja</th>
									<th>Metodo</th>
									<th>Abono</th>
									<th>Fecha</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.cajasAMostrar.map((caja, index) => (
									<tr key={index}>
										<td>{caja.name}</td>
										<td>{caja.abono.metodo.name}</td>
										<td>{Price(caja.abono.amount)}</td>
										<td>{caja.abono.date}</td>
										<td>
											{!caja?.abonoAntiguo ? (
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.deleteAbono(index)
													}}
													className="btn-edit">
													Eliminar
												</a>
											) : (
												<a
													href="#"
													onClick={event => {
														event.preventDefault()
														this.setState({ infomodalabono: caja, modalAbono: true })
													}}
													className="btn-edit">
													Ver
												</a>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default withParams(Page)

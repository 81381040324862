import { Component } from 'react'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
import { setLoading } from 'store'
const usersTable = new DB('users')
const cavasTable = new DB('cavas')
const bcrypt = require('bcryptjs')

export default class Login extends Component {
	async componentDidMount() {
		try {
			setLoading(true)
			const userStore = localStorage.getItem('@user_cava')
			if (!userStore || userStore === '') {
				setLoading(false)
				return
			}
			const user = JSON.parse(userStore)
			const cava = (await cavasTable.get({ uuid: user.cava })).data.Item
			this.loginAndRedirect(user, cava)
			setLoading(false)
		} catch (error) {
			Alert('¡Ooops!, tenemos problemas de conexión, intentalo de nuevo', 'danger')
			setLoading(false)
			console.error(error)
		}
	}

	loginAndRedirect = (user, cava) => {
		this.props.setUser(user) // TODO: esto lo debemos pasar para estaso globales con redux
		if (user.rol !== 'Super') {
			this.props.setCava(cava) // TODO: esto deberia ser global con redux
			if (user.rol === 'Cavero') {
				this.props.navigate('/dashboard/dispatchthecollectors')
			} else {
				this.props.navigate('/dashboard/inicio')
			}
		} else {
			this.props.navigate('/admin/inicio')
		}
	}

	handleSubmit = async event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const email = data.get('email')
		const pass = data.get('password')
		if (email === '' && pass === '') {
			Alert('Debes ingresar un correo y una contraseña', 'danger')
			return
		}
		setLoading(true)
		const result = await usersTable.get({ email: data.get('email') })
		if (result.status === false) {
			// Problemas de conexión
			Alert('¡Ooops!, tenemos problemas de conexión, intentalo de nuevo', 'danger')
			setLoading(false)
			return
		}
		if (!('Item' in result.data)) {
			// No existe el usuario
			Alert('El correo electrónico no se encuentra registrado', 'danger')
			setLoading(false)
			return
		}
		const user = result.data.Item
		const comparePass = bcrypt.compareSync(pass, user.password)
		if (!comparePass) {
			// Contraseña incorrecta
			Alert('La contraseña no es correcta.', 'danger')
			setLoading(false)
			return
		}
		if (user.status === 'Inactivo') {
			// Usuario inactivo
			Alert('Lo sentimos, su usuario no se encuentra activo', 'danger')
			setLoading(false)
			return
		}
		if (user.rol === 'Cobrador') {
			// Usuario cobrador
			Alert('Lo sentimos, su usuario no cuenta con los permisos suficientes para ingresar', 'danger')
			setLoading(false)
			return
		}
		localStorage.setItem('@user_cava', JSON.stringify(user))
		const cava = (await cavasTable.get({ uuid: user.cava })).data.Item
		this.loginAndRedirect(user, cava)
	}

	render() {
		return (
			<>
				<div id="notifications"></div>
				<div className="container-login">
					<form onSubmit={this.handleSubmit} action="#">
						<h1>Iniciar sesión</h1>
						<span>Sistema de gestión de cavas</span>
						<div className="divider" />
						<div className="input-group">
							<label>Correo electrónico</label>
							<input type="text" name="email" placeholder="Ingresa tu correo" />
						</div>
						<div className="input-group">
							<label>Contraseña</label>
							<input type="password" name="password" placeholder="*******" />
						</div>
						<div className="divider" />
						<button className="btn">Ingresar</button>
					</form>
				</div>
			</>
		)
	}
}

import { Component } from 'react'
import { uploadImage, Price, getDateParse, getHourParse, hoy, Alert } from 'utiles/functions'
import AnimateScreen from 'componentes/AnimateScreen'
import withParams from 'componentes/WithParams'
import Input from 'componentes/input'
import Loader from 'componentes/loader'
import Modal from 'componentes/modal'
import Back from 'componentes/back'
import e from 'utiles/settings'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db'
const movsClientsByWay = new DB('movsClientsByWay')
const productsOrders = new DB('productsOrders')
const clientsbyways = new DB('clientsbyways')
const clients = new DB('clients')
const orders = new DB('orders')
const ways = new DB('ways')
const paymentmethods = new DB('paymentmethods')
const movBoxes = new DB('movBoxes')
const cashs = new DB('cashs')
// añadir un campo place_cava true y user del abonador

// TODO: este archivo se debe refactorizar
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			client: {},
			ruta: {},
			movs: [],
			ordenes: [],
			verComprobante: {},
			way: {},
			tab: 1,
			metodoAbono: '',
			montoAbono: '',
			comprobante: '',

			// modales
			modalOrderClient: false,
			modalAbono: false,
			modalDetallesTransferencia: false,
			orderClient: {}
		}
	}

	componentDidMount() {
		this.init()
	}

	init = async () => {
		// consulto el cliente
		const client = (
			await clientsbyways.query(
				'#p = :p AND #uuid_way = :uuid_way',
				{ ':p': this.props.params.phone, ':uuid_way': this.props.params.uuid_way },
				{ '#p': 'phone', '#uuid_way': 'uuid_way' }
			)
		).data.Items[0]
		// consulto las ordenes
		const ordenes = (
			await orders.query(
				'#p = :p AND #uuid_way = :uuid_way',
				{ ':p': this.props.params.phone, ':uuid_way': this.props.params.uuid_way },
				{ '#p': 'phone_client', '#uuid_way': 'uuid_way' }
			)
		).data.Items
		// Consulto los Movimientos del cliente
		const movs = (
			await movsClientsByWay.query(
				'#p = :p AND #uuid_ruta = :uuid_ruta',
				{ ':p': this.props.params.phone, ':uuid_ruta': this.props.params.uuid_way },
				{ '#p': 'phone_client', '#uuid_ruta': 'uuid_ruta' }
			)
		).data.Items
		// consulto la ruta
		await ways.get({ uuid: this.props.params.uuid_way }).then(data => {
			this.setState({
				client,
				movs,
				ordenes,
				ruta: data.data.Item,
				loading: false
			})
		})
	}

	async getProductsOrder(uuidOrder) {
		this.setState({ loading: true })
		const orden = this.state.ordenes.filter(o => o.uuid === uuidOrder)[0]
		const productos = (await productsOrders.query('#uuid_order = :uuid_order', { ':uuid_order': uuidOrder }, { '#uuid_order': 'uuid_order' })).data
			.Items
		const orderClient = {
			...orden,
			productos
		}
		this.setState({ loading: false, orderClient, modalOrderClient: true })
	}

	async saveAbono() {
		if (this.state.metodoAbono !== '') {
			if (this.state.montoAbono !== '') {
				if (this.state.metodoAbono === 'Transferencia') {
					// transferencia
					if (this.state.comprobante !== '') {
						this.setState({ loading: true })
						const imagen = await uploadImage(this.state.comprobante)
						// guardar abono
						await movsClientsByWay.create({
							uuid: uuidv4(),
							amount: Number(this.state.montoAbono),
							phone_client: this.props.params.phone,
							type: this.state.metodoAbono,
							uuid_closed: 'EMPTY',
							uuid_order: null,
							uuid_ruta: this.props.params.uuid_way,
							photo: imagen,
							place_cava: true,
							date: new Date().getTime(),
							user_cava: {
								email: this.props.user.email,
								name: this.props.user.name
							}
						})
						// actualizar estado de ceunta
						await clientsbyways.update({
							Key: { uuid: this.state.client.uuid },
							UpdateExpression: 'SET #d = :debe',
							ExpressionAttributeValues: {
								':debe': Number(this.state.client.debe) + Number(this.state.montoAbono)
							},
							ExpressionAttributeNames: {
								'#d': 'debe'
							},
							ReturnValues: 'UPDATED_NEW'
						})
						// guardar ultimo abono
						await clients.update({
							Key: { phone: this.props.params.phone },
							UpdateExpression: 'SET #l = :last_mov',
							ExpressionAttributeValues: {
								':last_mov': {
									amount: Number(this.state.client.debe) + Number(this.state.montoAbono),
									date: new Date().getTime(),
									way: {
										name: this.state.ruta.name,
										uuid: this.props.params.uuid_way
									}
								}
							},
							ExpressionAttributeNames: {
								'#l': 'last_mov'
							},
							ReturnValues: 'UPDATED_NEW'
						})
						// consulto el método de pago y la caja predeterminada
						const metodoPago = (
							await paymentmethods.query(
								'#cava = :cava AND #defaultTransferencia = :defaultTransferencia',
								{ ':cava': this.props.cava.uuid, ':defaultTransferencia': true },
								{ '#cava': 'cava', '#defaultTransferencia': 'defaultTransferencia' }
							)
						).data.Items[0]
						const caja = (
							await cashs.query(
								'#cava = :cava AND #default = :default',
								{ ':cava': this.props.cava.uuid, ':default': true },
								{ '#cava': 'cava', '#default': 'default' }
							)
						).data.Items[0]
						// movimiento de caja
						const usuarioMov = {
							email: this.props.user.email,
							name: this.props.user.name,
							rol: this.props.user.rol
						}
						await movBoxes.create({
							uuid: uuidv4(),
							cava: this.props.cava.uuid,
							caja_uuid: caja.uuid,
							metodo_uuid: metodoPago.uuid,
							type: 'ingreso',
							value: Number(this.state.montoAbono),
							description: 'Abono de cliente',
							voucher: '',
							user: usuarioMov
						})
						// actualizar el estado del método
						let totalcaja = 0
						if (caja[metodoPago.uuid]) {
							totalcaja = caja[metodoPago.uuid].amount
						}
						totalcaja += Number(this.state.montoAbono)
						const metodo = {
							amount: totalcaja,
							name: metodoPago.name
						}
						await cashs.update({
							Key: { uuid: caja.uuid },
							UpdateExpression: `SET #m = :m`,
							ExpressionAttributeValues: {
								':m': metodo
							},
							ExpressionAttributeNames: {
								'#m': metodoPago.uuid
							},
							ReturnValues: 'UPDATED_NEW'
						})

						this.setState({ modalAbono: false })
						this.init()
					} else {
						Alert('Sube el comprobante del abono.', 'danger', 7000)
					}
				} else {
					// efectivo
					this.setState({ loading: true })
					await movsClientsByWay.create({
						uuid: uuidv4(),
						amount: Number(this.state.montoAbono),
						phone_client: this.props.params.phone,
						type: this.state.metodoAbono,
						uuid_closed: 'EMPTY',
						uuid_order: null,
						uuid_ruta: this.props.params.uuid_way,
						place_cava: true,
						date: new Date().getTime(),
						user_cava: {
							email: this.props.user.email,
							name: this.props.user.name
						}
					})
					// actualizar estado de ceunta
					await clientsbyways.update({
						Key: { uuid: this.state.client.uuid },
						UpdateExpression: 'SET #d = :debe',
						ExpressionAttributeValues: {
							':debe': Number(this.state.client.debe) + Number(this.state.montoAbono)
						},
						ExpressionAttributeNames: {
							'#d': 'debe'
						},
						ReturnValues: 'UPDATED_NEW'
					})
					// guardar ultimo abono
					await clients.update({
						Key: { phone: this.props.params.phone },
						UpdateExpression: 'SET #l = :last_mov',
						ExpressionAttributeValues: {
							':last_mov': {
								amount: Number(this.state.client.debe) + Number(this.state.montoAbono),
								date: new Date().getTime(),
								way: {
									name: this.state.ruta.name,
									uuid: this.props.params.uuid_way
								}
							}
						},
						ExpressionAttributeNames: {
							'#l': 'last_mov'
						},
						ReturnValues: 'UPDATED_NEW'
					})

					// consulto el método de pago y la caja predeterminada
					const metodoPago = (
						await paymentmethods.query(
							'#cava = :cava AND #defaultTransferencia = :defaultTransferencia',
							{ ':cava': this.props.cava.uuid, ':defaultTransferencia': true },
							{ '#cava': 'cava', '#defaultTransferencia': 'defaultTransferencia' }
						)
					).data.Items[0]
					const caja = (
						await cashs.query(
							'#cava = :cava AND #default = :default',
							{ ':cava': this.props.cava.uuid, ':default': true },
							{ '#cava': 'cava', '#default': 'default' }
						)
					).data.Items[0]
					// movimiento de caja
					const usuarioMov = {
						email: this.props.user.email,
						name: this.props.user.name,
						rol: this.props.user.rol
					}
					await movBoxes.create({
						uuid: uuidv4(),
						cava: this.props.cava.uuid,
						caja_uuid: caja.uuid,
						metodo_uuid: metodoPago.uuid,
						type: 'ingreso',
						value: Number(this.state.montoAbono),
						description: 'Abono de cliente',
						voucher: '',
						user: usuarioMov
					})
					// actualizar el estado del método
					let totalcaja = 0
					if (caja[metodoPago.uuid]) {
						totalcaja = caja[metodoPago.uuid].amount
					}
					totalcaja += Number(this.state.montoAbono)
					const metodo = {
						amount: totalcaja,
						name: metodoPago.name
					}
					await cashs.update({
						Key: { uuid: caja.uuid },
						UpdateExpression: `SET #m = :m`,
						ExpressionAttributeValues: {
							':m': metodo
						},
						ExpressionAttributeNames: {
							'#m': metodoPago.uuid
						},
						ReturnValues: 'UPDATED_NEW'
					})
					this.setState({ modalAbono: false })
					this.init()
				}
			} else {
				Alert('Ingresa el monto a abonar.', 'danger', 7000)
			}
		} else {
			Alert('Selecciona el método con el que van a abonar.', 'danger', 7000)
		}
	}

	modalOrderClient() {
		if (this.state.modalOrderClient) {
			return (
				<Modal active={this.state.modalOrderClient}>
					<div>
						<h2>Detalles de la orden</h2>
						<div className="divider"></div>
						<strong style={{ marginBottom: 10, display: 'block', textAlign: 'left' }}>Total orden: {Price(this.state.orderClient.total)}</strong>
						<table className="table">
							<thead>
								<tr>
									<th>Productos</th>
									<th>Cantidad</th>
									<th>Precio</th>
								</tr>
							</thead>
							<tbody>
								{this.state.orderClient.productos.map(pro => (
									<tr key={pro.uuid}>
										<td>
											<div className="item-pro">
												{pro.imagen !== undefined ? (
													<img src={e.urlBucket + pro.imagen} alt={pro.name} />
												) : (
													<img src={require('../../img/defecto.jpg')} alt={pro.name} />
												)}
												<span>{pro.name}</span>
											</div>
										</td>
										<td>
											<strong>{pro.amount}</strong>
										</td>
										<td>
											<strong>{Price(pro.price)}</strong>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<div className="divider"></div>
						<center>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalOrderClient: false, orderClient: {} })
								}}
								className="btn">
								Cerrar
							</a>
						</center>
					</div>
				</Modal>
			)
		}
	}

	modalDetallesTransferencia() {
		if (this.state.modalDetallesTransferencia) {
			return (
				<Modal active={this.state.modalDetallesTransferencia}>
					<div>
						<h2>Detalles del abono</h2>
						<div className="divider"></div>
						<div className="contenido">
							<p>
								<strong>Cantidad: </strong>
								{Price(this.state.verComprobante.amount)}
							</p>
							<p>
								<strong>Fecha: </strong>
								{getDateParse(new Date(Number(this.state.verComprobante.date))) +
									' a las ' +
									getHourParse(new Date(Number(this.state.verComprobante.date)))}
							</p>
						</div>
						<img src={e.urlBucket + this.state.verComprobante.photo} height={200} width={200} />
						<br />
						<a href={e.urlBucket + this.state.verComprobante.photo} className="btn-edit" target="_blank" rel="noreferrer">
							Ver combrobante
						</a>
						<div className="divider"></div>
						<center>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalDetallesTransferencia: false })
								}}
								className="btn">
								Cerrar
							</a>
						</center>
					</div>
				</Modal>
			)
		}
	}

	modalAbono() {
		if (this.state.modalAbono) {
			return (
				<Modal active={this.state.modalAbono}>
					<div>
						<h2>Nuevo abono</h2>
						<div className="divider"></div>
						<Input
							type="select"
							title="Método de pago *"
							value={this.state.metodoAbono}
							change={val => {
								this.setState({ metodoAbono: val })
							}}
							options={[
								{
									val: '',
									text: 'Selecciona un método de pago'
								},
								{
									val: 'Efectivo',
									text: 'Efectivo'
								},
								{
									val: 'Transferencia',
									text: 'Transferencia'
								}
							]}
						/>
						<Input
							type="number"
							title="Monto a abonar"
							placeholder="Ingresa el monto del abono"
							val={this.state.montoAbono}
							change={val => this.setState({ montoAbono: val })}
						/>
						{this.state.metodoAbono === 'Transferencia' && (
							<Input
								type="image"
								name="combrobante"
								img={this.state.comprobante}
								title="Comprobante"
								change={data => this.setState({ comprobante: data })}
							/>
						)}
						<div className="divider"></div>
						<div className="btns-flex">
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.setState({ modalAbono: false })
								}}
								className="btn btn-eliminar">
								Cerrar
							</a>
							<a
								href="#"
								onClick={event => {
									event.preventDefault()
									this.saveAbono()
								}}
								className="btn">
								Guardar
							</a>
						</div>
					</div>
				</Modal>
			)
		}
	}

	render() {
		return (
			<>
				{this.modalOrderClient()}
				{this.modalAbono()}
				{this.modalDetallesTransferencia()}
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Movimientos del cliente</h2>
						<span>{'name' in this.state.client ? this.state.client.name : this.props.params.photo}</span>
					</div>
					{/* <div className="btns">
						<a href="#" onClick={(e) => { e.preventDefault(); this.setState({modalAbono: true}) }} className="btn">Registrar abono</a>
					</div> */}
				</div>
				<div className="grid g5" style={{ marginBottom: 20 }}>
					<div className="card">
						<div className="item-widget">
							<span>Estado de cuenta</span>
							<strong>{'debe' in this.state.client ? Price(this.state.client.debe) : 0}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Cantidad de ordenes</span>
							<strong>{this.state.ordenes.length}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Cantidad de abonos</span>
							<strong>{this.state.movs.filter(m => m.type !== 'debe').length}</strong>
						</div>
					</div>
					<div className="card">
						<div className="item-widget">
							<span>Posicion en la ruta</span>
							<strong>{'index' in this.state.client ? this.state.client.index : '-'}</strong>
						</div>
					</div>
					<div className="card" style={{ backgroundColor: this.state.client.status === 'Activo' ? '#009900' : '#959595' }}>
						<div className="item-widget">
							<span style={{ color: '#fff' }}>Estado</span>
							<strong style={{ color: '#fff' }}>{'status' in this.state.client ? this.state.client.status : '-'}</strong>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="btns-tabs">
						<a
							className={`${this.state.tab === 1 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 1 })
							}}>
							Movimientos
						</a>
						<a
							className={`${this.state.tab === 2 ? 'active' : ''}`}
							href="#"
							onClick={e => {
								e.preventDefault()
								this.setState({ tab: 2 })
							}}>
							Ordenes
						</a>
					</div>

					<div className="tab" style={{ display: this.state.tab === 1 ? 'block' : 'none' }}>
						<div className="container-search">
							<div className="search">
								<input type="text" placeholder="Escribe algo.." />
								<button>
									<img src={require('../../img/search.svg').default} width={20} height={20} />
								</button>
							</div>
						</div>
						<table className="table">
							<thead>
								<tr>
									<th>Cantidad</th>
									<th>Metodo</th>
									<th>Tipo</th>
									<th>Fecha</th>
									<th>Manual</th>
									<th>Cierre de ruta</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.movs.map(mov => (
									<tr key={mov.uuid}>
										<td>
											<strong style={{ color: mov.type !== 'debe' ? 'green' : 'red' }}>
												{mov.type !== 'debe' ? '+' : '-'}
												{Price(mov.amount)}
											</strong>
										</td>
										<td>
											<strong style={{ color: mov.type !== 'debe' ? 'green' : 'red' }}>{mov.type !== 'debe' ? mov.type : 'N/A'}</strong>
										</td>
										<td>
											<strong>{mov.type !== 'debe' ? 'Registro de abono' : 'Compra de productos'}</strong>
										</td>
										<td>{getDateParse(new Date(Number(mov.date))) + ' a las ' + getHourParse(new Date(Number(mov.date)))}</td>
										<td></td>
										<td>
											{mov.uuid_closed === 'EMPTY' ? (
												'N/A'
											) : (
												<a
													href="#"
													className="btn-edit"
													onClick={event => {
														event.preventDefault()
														console.log('Ver cierre de ruta')
													}}>
													Ver cierre
												</a>
											)}
										</td>
										<td>
											{mov.type === 'debe' ? (
												<a
													href="#"
													className="btn-edit"
													onClick={event => {
														event.preventDefault()
														this.getProductsOrder(mov.uuid_order)
													}}>
													Detalles
												</a>
											) : mov.type === 'Transferencia' ? (
												<a
													href="#"
													className="btn-edit"
													onClick={event => {
														event.preventDefault()
														this.setState({ verComprobante: mov, modalDetallesTransferencia: true })
													}}>
													Detalles
												</a>
											) : (
												'N/A'
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="tab" style={{ display: this.state.tab === 2 ? 'block' : 'none' }}>
						<div className="container-search">
							<div className="search">
								<input type="text" placeholder="Escribe algo.." />
								<button>
									<img src={require('../../img/search.svg').default} width={20} height={20} />
								</button>
							</div>
						</div>
						<table className="table">
							<thead>
								<tr>
									<th>Total orden</th>
									<th>Fecha</th>
									<th>Acciones</th>
								</tr>
							</thead>
							<tbody>
								{this.state.ordenes.map(orden => (
									<tr key={orden.uuid}>
										<td>
											<strong>{Price(orden.total)}</strong>
										</td>
										<td>{hoy(orden.time, true)}</td>
										<td>
											<a
												href="#"
												className="btn-edit"
												onClick={event => {
													event.preventDefault()
													this.getProductsOrder(orden.uuid)
												}}>
												Ver detalles
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		)
	}
}
export default AnimateScreen(withParams(Page))

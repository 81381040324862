import { Component } from 'react'
import Loader from 'componentes/loader'
import { Price, getDate } from 'utiles/functions'
import Input from 'componentes/input'
import withParams from 'componentes/WithParams'
import { NavLink } from 'react-router-dom'
import Back from 'componentes/back'
import DB from 'utiles/db'
import e from 'utiles/settings'
const inventoryTweaks = new DB('inventory_tweaks')
class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			data: [],
			cant: 0,
			avaluo_operation: 0,
			avaluo_costo: 0,
			activos: 0,
			inactivos: 0,
			user: {},
			productos: [],
			date: 0
		}
	}

	componentDidMount() {
		inventoryTweaks
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				this.setState({
					data: data.data.Item,
					user: data.data.Item.user,
					productos: data.data.Item.tweaks,
					loading: false,
					date: data.data.Item.time
				})
			})
			.catch(error => {
				console.error(error)
			})
	}

	render() {
		return (
			<>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<Back />
						<h2>Ajustes de inventario</h2>
						<span>ver</span>
					</div>
					<div className="btns"></div>
				</div>

				<div className="divider"></div>
				<div className="card">
					<div className="la_descriopcion">
						<div className="columns">
							<div className="column">
								<Input
									type="textarea"
									title="Descripción del ajuste de inventario"
									placeholder="Ingresa aquí tu descripción"
									name=""
									value={this.state.data.description}
									readonly="readOnly"
								/>
							</div>
							<div className="column">
								<Input type="text" title="Fecha" placeholder="" name="" value={getDate(this.state.date).toLocaleString()} readonly="readOnly" />
							</div>
						</div>

						<p>Ajuste realizado por:</p>
						<div className="columns">
							<div className="column">
								<Input type="text" title="Nombre" placeholder="" name="" value={this.state.user.name} readonly="readOnly" />
							</div>
							<div className="column">
								<Input type="text" title="Rol" placeholder="" name="" value={this.state.user.rol} readonly="readOnly" />
							</div>
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th>Producto</th>
								<th>Cantidad ajustada</th>
								<th>Cantidad antigua</th>
								<th>Diferencia</th>
								<th>Diferencia precio</th>
							</tr>
						</thead>
						<tbody>
							{this.state.productos.map(pro => (
								<tr key={pro.uuid}>
									<td>
										<NavLink className="item-pro">
											{pro.image !== undefined ? (
												<img src={e.urlBucket + pro.image} alt={pro.name} />
											) : (
												<img src={require('../../img/defecto.jpg')} alt={pro.name} />
											)}
											<span>{pro.name}</span>
										</NavLink>
									</td>
									<td>
										<Input type="number" title="" placeholder="" name="cantidad" id={pro.uuid} value={pro.quantity_new} readonly="readOnly" />
									</td>
									<td>
										<Input type="number" title="" placeholder="" name="cantidad" id={pro.uuid} value={pro.quantity_old} readonly="readOnly" />
									</td>
									<td>
										<Input type="number" title="" placeholder="" name="cantidad" value={pro.quantity_new - pro.quantity_old} readonly="readOnly" />
									</td>
									<td>
										<Input
											type="text"
											title=""
											placeholder=""
											name="cantidad"
											value={Price(pro.price_credito * (pro.quantity_new - pro.quantity_old))}
											readonly="readOnly"
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</>
		)
	}
}
export default withParams(Page)
